import * as React from 'react';
import styled from 'styled-components';
import {
    FaRegTimesCircle,
    FiUser,
    FiUsers,
    MdLogout
} from "react-icons/all";
import {useDispatch, useSelector} from "react-redux";
import {
    disconnectUser,
    selectImpersonated, selectUser,
    setImpersonated,
} from "../../reducers/user.reducer";
import {
    resetState,
    selectCurrentEntity,
    selectDisplayDebug,
    selectEntities, setCurrentEntity,
    setDisplayDebug, setEntities,
    setLoading, selectDirty
} from "../../reducers/app.reducer";
import {resetContentfulReducer, selectAllContentfulData} from "../../reducers/contentful.reducer";
import {useHistory, useLocation} from "react-router-dom";
import {selectSelectedUser} from "../../reducers/dashboard.reducer";
import {USER_ROLES} from "../../utils/constants";

export interface UserComponentProps {
    user: any;
};

export const UserComponent = ({user}: UserComponentProps) => {

    const dispatch = useDispatch();
    const entities = useSelector(selectEntities);
    const currentEntity = useSelector(selectCurrentEntity);
    const displayDebug = useSelector(selectDisplayDebug);
    const impersonated = useSelector(selectImpersonated);
    const history = useHistory();
    const location = useLocation();
    const selectedUser = useSelector(selectSelectedUser);
    const stateUser = useSelector(selectUser);
    const contentfulData = useSelector(selectAllContentfulData);
    const surveyIsDirty = useSelector(selectDirty);

    const disconnect = () => {
        let leaveAnyway = true;
        if (surveyIsDirty) {
            leaveAnyway = window.confirm("Vous avez des changements non sauvegardés. Etes vous sûr de vouloir quitter la page?")
        }
        if (leaveAnyway) {
            window.sessionStorage.removeItem('logged_user');
            window.sessionStorage.removeItem('access_token');
            window.localStorage.clear();
            window.sessionStorage.clear();
            dispatch(resetState());
            dispatch(resetContentfulReducer());
            dispatch(disconnectUser());
        }
    }

    const print = () => {
        console.log("entities", entities);
        console.log("currentEntity", currentEntity);
        console.log("impersonated", impersonated);
        console.log("history", history.location);
        console.log("location", location);
        console.log("SELECTED USER", selectedUser);
        console.log("CONNECTED USER", stateUser)
        console.log("contentful datas", contentfulData);
    }

    const toggleToolbar = () => {
        dispatch(setDisplayDebug(!displayDebug));
    };

    const handleRemoveImpersonated = () => {
        dispatch(setEntities(user.responses));
        dispatch(setImpersonated(null));
        dispatch(setCurrentEntity(user.responses[0]));
    }

    return (
        <UserComponentContainer>
            {/* <Initial>{getInitial(user.userName)}</Initial>*/}
            {user.userType === USER_ROLES.ADMIN &&
                <ToolsContainer>
                    <div onClick={print}>print</div>
                    <div onClick={toggleToolbar}>debug</div>
                </ToolsContainer>
            }
            <UserName>
                {user.userName}
                {impersonated &&
                    <span>( En tant que {impersonated.userName} <FaRegTimesCircle onClick={handleRemoveImpersonated}/>)</span>
                }
            </UserName>
            <PP>{impersonated ? <FiUsers/> : <FiUser/>}</PP>
            <Logout onClick={disconnect}>Déconnexion<MdLogout/></Logout>
        </UserComponentContainer>
    );
};

const ToolsContainer = styled.div`
  background-color: #5d5d5d;
  padding: 10px 20px;
  display: flex;
  column-gap: 15px;
  border-radius: 10px;

  div {
    color: white;
    font-size: 1.125em;
    user-select: none;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
    
    &:hover {
        opacity: 1;
    }
  }

`;

const UserComponentContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  border-radius: 20px;
  height: 60px;
  min-width: 0;
  padding: 0 20px;
  column-gap: 15px;
  z-index: 99;
  flex: 1;
`;

const PP = styled.div`
  height: 55px;
  width: 55px;
  border: 2px solid white;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  image-rendering: auto;
  image-rendering: crisp-edges;

  svg {
    width: 25px;
    height: 25px;
  }
`;

const Initial = styled.div`
  height: 40px;
  width: 40px;
  background-color: #1179a0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const UserName = styled.div`
  color: white;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  white-space: nowrap;

  span {
    font-size: 1em;
    opacity: 1;
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #ffffffc2;

    svg {
      cursor: pointer;
      color: #fab1a0;
      opacity: .8;
      transition: all .2s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

const Logout = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: all 0.3s;
  column-gap: 5px;
  color: white;

  &:hover {
    opacity: 1;
  }

  > * {
    width: 20px;
    height: 20px;
  }

  svg {
    color: white;
    opacity: 0.7;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;
