import React from 'react';
import {saveAs} from "file-saver";
import * as ExcelJS from 'exceljs';
import {getCellAddress, getColorFromProcessorStatus, handleConditions, handleReplace} from "../../utils/utils";

const MarkdownIt = require('markdown-it')();

const generateExcel = async (formData, registry) => {

    const getChildrenFromMarkdown = (md) => {
        return md.children.map((child, idx) => {
            if (child.type === "text" && child.content !== "") {
                return child.level === 0 
                ? {type: "text", text: child.content, bold: false}
                : child.level === 1 
                    ? md.children[idx -1].tag === "strong"
                        ? {type: "text", text: child.content, bold: true}
                        : {type: "link", text: child.content, bold: false, url: md.children[idx -1].attrs[0][1]}
                    : {type: "link", text: child.content, bold: true, url: md.children[idx -3].attrs[0][1]}
            }
        }).filter(Boolean)
    }

    const handleValues = (value) => {
        if (value.text === undefined && !value.composed) {
            return ''
        }    
        if (value.composed) {
            let filteredValues = value.values.filter(value => handleConditions(value, formData));
            if (filteredValues.length < 1) {
                return 'N/A';
            }
            return filteredValues.map(value => {
                
                return handleConditions(value, formData) ? handleReplace(value, formData).text : false;
            }).join();
        } else {
            return handleConditions(value, formData) ? handleReplace(value, formData)?.text : 'N/A'
        }
    };

    const workbook = new ExcelJS.Workbook();

    workbook.creator = 'Me';
    workbook.lastModifiedBy = 'Her';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.properties.date1904 = true;

    registry.sheets.forEach((sheet, index) => {
        const currentSheet = workbook.addWorksheet(handleReplace(sheet, formData).name, {views: [{state: 'frozen', xSplit: 1, ySplit: 1}]});
        let currentSheetColumns = sheet.headers?.map((header, index) => {
            return {
                header: header,
                key: `${sheet.name}_${index}`,
                width: 30,
                outlineLevel: 1
            }
        });
        currentSheetColumns.push({
            header: 'Date de mise à jour',
            key: `${sheet.name}_${sheet.headers.length}`,
            width: 30,
            outlineLevel: 1
        })
        currentSheet.columns = currentSheetColumns
        
        let cellsToUpdate = [];
        let rows = sheet?.rows?.map((row, rowIdx) => {
            if (handleConditions(row, formData)) {
                let rowValues = row.values?.map((value, valueIdx) => {
                    let parsedMarkdown = MarkdownIt.parse(handleValues(value), {references: {}});
                    let markdownText = ""
                    if (value.text !== undefined) {
                    }
                    
                    if (parsedMarkdown.length) {
                        let markdownChildren = getChildrenFromMarkdown(parsedMarkdown[1])
                        markdownChildren.map( markdownChild => {
                            markdownText = markdownText += markdownChild.text
                            if(markdownChild.type === "link") {
                                cellsToUpdate.push({
                                    address: getCellAddress(rowIdx, valueIdx),
                                    color: getColorFromProcessorStatus(value.backgroundColor), 
                                    url: markdownChild.url
                                })
                            }
                        })
                    }
                    if (value.backgroundColor) {
                        cellsToUpdate.push({
                            address: getCellAddress(rowIdx, valueIdx),
                            color: getColorFromProcessorStatus(value.backgroundColor), 
                            url: null
                        })
                    }
                    return handleValues({...value, text:markdownText});
                })
                if (rowValues) {
                    let today = new Date().toLocaleDateString()
                    rowValues.push(today);
                    return rowValues;
                }
            }
        }).filter(a => a);
        if (rows.length) {
            currentSheet.addRows(rows);
        }

        currentSheet.getRows(0, rows.length + 2).forEach((row, idx) => {
            if (idx === 1) {
                row.font = {bold: true, color: {argb: 'FF4682B4'}}
            }

            row.eachCell((cell, idx) => {
                if (idx === 1) {
                    cell.style.font = {bold: true, color: {argb: 'FF4682B4'}}
                }
                let cellToUpdateIndex = cellsToUpdate.findIndex(cellToUpdate => cellToUpdate.address === cell.address.toString())
                
                if (cellToUpdateIndex !== -1) {
                    if (cellsToUpdate[cellToUpdateIndex].color) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {argb: cellsToUpdate[cellToUpdateIndex].color}
                        }
                    } 
                    if( cellsToUpdate[cellToUpdateIndex].url) {
                        cell.value = {
                            text: cell.value,
                            hyperlink: cellsToUpdate[cellToUpdateIndex].url,
                        };
                    }
                }

                cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center'};
            })
        })
    });

    //PREPARE SAVE
    const myFile = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const blob = new Blob([myFile], {type: fileType});

    return blob;
}

export const getOneExcel = async (formData, registry) => {
    let registryBlob = await generateExcel(formData, registry);
    saveAs(registryBlob, registry.name + '.xlsx');
}

export const getAllExcel = async (formData, registries) => {
    return await registries
        .filter(registry => handleConditions(registry, formData))
        .map(registry => ({
            blob: generateExcel(formData, registry),
            name: registry.name
        }))
}


