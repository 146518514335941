export const ENTITY_STATUS = {
    EDITED: 'En cours',
    NOT_EDITED: 'Nouveau',
    SUBMITTED: 'Validation en cours',
    REJECTED: 'Validation rejetée',
    VALIDATED: 'Validé',
}

export const ENTITY_STEPS: { user?: string | null, admin?: string, index?: number }[] = [
    {
        admin: 'Création du compte',
        user: null,
        index: 0,
    },
    {
        admin: 'Envoi du questionnaire',
        user: 'Décrivez-nous votre activité',
        index: 1,
    },
    {
        admin: 'Vérification du questionnaire',
        user: 'Vérification de vos réponses',
        index: 2,
    },
    {
        admin: 'Validation du questionnaire',
        user: 'Vos réponses au(x) questionnaire(s) ont été validées',
        index: 3,
    },
    {
        admin: 'Refus du questionnaire',
        user: 'Attente de modification de vos réponses au(x) questionnaire(s)',
        index: 4,
    },
    {
        admin: 'Nomination DPO',
        user: 'Nous sommes officiellement votre DPO',
        index: 5,
    },
    {
        admin: 'Documents Registres/Inventaire PBD sont prêts',
        user: 'Réalisation des registres et contrôle de vos prestataires techniques',
        index: 6,
    },
    {
        admin: 'Documents Cookies/Politique/Mention RGPD sont prêts',
        user: 'Nous avons analysé votre service',
        index: 7,
    },
    {
        admin: 'Documents RH/Sécurité sont prêts',
        user: 'Actions techniques à réaliser sur vos Sites Web / Applications / Webapps',
        index: 8,
    },
    {
        admin: 'Nous verifions vos bases de données',
        user: 'Nous verifions vos bases de données',
        index: 9,
    },
    {
        admin: 'Sensibilisation de vos équipes',
        user: 'Sensibilisation de vos équipes',
        index: 10,
    },
    {
        admin: 'Maintien opérationnel de votre structure',
        user: 'Maintien opérationnel de votre structure',
        index: 11,
    },
];

export const PROCESSOR_MONITORING_STATUS = [
    {
        label: 'Conforme',
        value: 'CONFORM',
        color: 'FF82D0A5',
    },
    {
        label: 'Audit à réaliser',
        value: 'TO_BE_AUDITED',
        color: 'FF1179A0',
    },
    {
        label: 'Document à signer',
        value: 'TO_BE_SIGNED',
        color: 'FFFFA500',
    },
    {
        label: 'Non-conforme',
        value: 'NOT_CONFORM',
        color: 'FFDD0615',
    },
]

export const COLORS = {
    lightNavy: '#0f3e60',
    NiceBlue: '#1179a0',
    AquaMarine: '#49bfbd',
    AquaBlue: '#00d2e2',
    Black: '#2b2b2b',
    Squash: '#f49d1d',
    Red: '#dd0615',
    Green: '#82d0a5',
}

export const COUNTER_TYPES = [
    {
        label: 'Demandes d\'assistance',
        description: 'Nombre total de demandes d\'assistance',
        amount: 0,
        id: 'assistance_requests'
    },
    {
        label: 'Droits des personnes',
        description: 'Nombre total de demande droits des personnes',
        amount: 0,
        id: 'user_rights'
    },
    {
        label: 'Violations de données',
        description: 'Nombre total de violations de données',
        amount: 0,
        id: 'data_violation'
    },

]

export const USER_ROLES = {
    ADMIN: 'superadmin',
    AGENCY: 'agency',
    CLIENT: 'user',
}
