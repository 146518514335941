import * as React from 'react';
import styled from 'styled-components';
import {InputComponent} from "../form/InputComponent";
import {useState} from "react";
import {getRelationsBetweenSurveys, getRelationsBetweenSurveysAndRegistries} from "../../utils/utils";
import {selectAllContentfulData} from "../../reducers/contentful.reducer";
import {useDispatch, useSelector} from "react-redux";
import {selectActionRights, selectDownloadOk, selectUser, updateDbRemaining} from "../../reducers/user.reducer";
import {selectEntities, selectLang, setLang} from "../../reducers/app.reducer";
import _ from "lodash";
import {getAllDocs, getOneDoc} from "../docGeneration/docx-utils";
import {getAllExcel, getOneExcel} from "../docGeneration/excel-utils";
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {selectSelectedUser} from "../../reducers/dashboard.reducer";
import {USER_ROLES} from "../../utils/constants";

let GENERATION_MODES = {
    WORD: 'word',
    EXCEL: 'excel',
    ALL: 'all'
}

export const DashboardGeneration = () => {

    //redux
    const dispatch = useDispatch();
    const contentfulData = useSelector(selectAllContentfulData);
    const actionRights = useSelector(selectActionRights);
    const lang = useSelector(selectLang);
    const entities = useSelector(selectEntities);
    const downloadOk = useSelector(selectDownloadOk);
    const user = useSelector(selectUser);
    const selectedUser = useSelector(selectSelectedUser);

    //state
    const [generationMode, setGenerationMode] = useState(GENERATION_MODES.WORD);
    const [targetGeneration, setTargetGeneration] = useState(null);

    const getDocxOptions = () => {
        return contentfulData.documents
            .filter(document => selectedUser?.actionRights?.documents?.some(documentRight => document.id === documentRight.value))
            .filter(document => document.lang === lang)
            //.filter(document => handleConditions(document, formValues || {}))
            .map(document => ({value: document.id, label: document.name})) || [];
    };

    const handleTargetDoc = (name, value) => {
        let newGenerationEntity = {
            ...targetGeneration,
            docId: value
        }
        if (value === undefined) {
            delete newGenerationEntity.docId;
        }
        setTargetGeneration(newGenerationEntity);
    }

    const handleTargetGeneration = (name, value) => {
        let newGenerationEntity = {
            ...targetGeneration,
            [name]: value
        }
        setTargetGeneration(newGenerationEntity);
    };

    const handleTargetEntity = (name, value) => {
        let newGenerationEntity = {
            ...targetGeneration,
            name: value
        }
        setTargetGeneration(newGenerationEntity);
    };

    const getSurveysByDocId = (docId) => {
        let surveys;
        let links = getRelationsBetweenSurveys(contentfulData.surveys, contentfulData.documents, actionRights?.surveys).docToSurveys;

        if (!docId) {
            surveys = _.cloneDeep(contentfulData.surveys).filter(survey => actionRights?.surveys?.some(userSurvey => userSurvey.value === survey.id));
        } else {
            surveys = _.cloneDeep(contentfulData.surveys).filter(survey => links[docId]?.some(linkedSurvey => linkedSurvey === survey.id));
        }

        if (!targetGeneration.name) {
            return;
        }

        let versions = surveys.map(survey => {
            let surveyResponsesVersions = getEntityResponseByEntityName(targetGeneration.name)[survey.id];
            let versionsNames = []
            if (surveyResponsesVersions) {
                surveyResponsesVersions.map((surveyResponsesVersion) => versionsNames.push(surveyResponsesVersion.name));
            }
            return {
                survey: survey,
                versioning: survey.versioning,
                versions: versionsNames,
            }
        });
        return versions.filter(version => version.versioning.enabled);
    };

    const getEntityResponseByEntityName = (entityName) => {
        if (!entities) {
            return;
        }
        return selectedUser?.responses?.find(entity => entity.name === entityName)?.responses;
    };

    const handleDocxSubmit = async () => {
        let values = getTargetedValues();
        await dispatch(updateDbRemaining({userId: user._id, actions: {}}));
        if (downloadOk) {
            targetGeneration?.docId
                ? await getOneDoc(values, contentfulData.documents.find(document => document.id === targetGeneration.docId))
                : await getZippedDocx(await getAllDocs(values, contentfulData.documents), values);
        } else {
            alert('Vous ne disposez pas d\'assez de crédits pour la génération')
        }
    };

    const getTargetedValues = () => {
        let targetedValues = {};
        let entityResponses = selectedUser.responses.find(entity => entity.name === targetGeneration.name).responses;
        if (!entityResponses) {
            return;
        }
        for (let surveyId in entityResponses) {
            targetedValues = {
                ...targetedValues,
                ...(targetGeneration[surveyId]
                        ? entityResponses[surveyId].find(version => version.name === targetGeneration[surveyId])?.responses
                        : entityResponses[surveyId].find(version => version.name === "version_1")?.responses
                )
            }
        }
        return targetedValues;
    };

    const handleExcelSubmit = async () => {
        let values = getTargetedValues();
        await dispatch(updateDbRemaining({userId: user._id, actions: {}}))
        if (downloadOk) {
            targetGeneration?.registryId
                ? await getOneExcel(values, contentfulData.registries.find(registry => registry.id === targetGeneration.registryId))
                : await getZippedRegistries(await getAllExcel(values, contentfulData.registries), values);
        } else {
            alert('Vous ne disposez pas d\'assez de crédits pour la génération')
        }
    };

    const getZippedDocx = async (blobs, values) => {
        let zip = new JSZip();
        blobs.forEach(doc => {
            zip.file(doc.name + '.docx', doc.blob);
        });
        let content = await zip.generateAsync({type: "blob"});
        let name = values.sign ?? 'dipeeo';
        saveAs(content, name.replaceAll(' ', '_') + '-docs.zip');
    }

    const getZippedRegistries = async (blobs, values) => {
        let zip = new JSZip();
        blobs.forEach(doc => {
            zip.file(doc.name + '.xlsx', doc.blob);
        })
        let content = await zip.generateAsync({type: "blob"});
        let name = values.sign ?? 'dipeeo';
        saveAs(content, name.replaceAll(' ', '_') + '-registries.zip');
    };

    const getRegistriesOptions = () => {
        return contentfulData.registries
            .filter(registry => selectedUser?.actionRights?.registries?.some(registryRights => registry.id === registryRights.value))
            .filter(document => document.lang === lang)
            .map(registry => ({value: registry.id, label: registry.name})) || [];
    };

    const handleTargetRegistry = (name, value) => {
        let newGenerationEntity = {
            ...targetGeneration,
            registryId: value
        }
        if (value === undefined) {
            delete newGenerationEntity.registryId;
        }
        setTargetGeneration(newGenerationEntity);
    }

    const handleTargetLang = (name, value) => dispatch(setLang(value));

    const getSurveysByRegistryId = (registryId) => {
        let surveys;
        let links = getRelationsBetweenSurveysAndRegistries(contentfulData.surveys, contentfulData.registries, actionRights?.surveys).registryToSurveys;
        if (!registryId) {
            surveys = _.cloneDeep(contentfulData.surveys).filter(survey => actionRights?.surveys?.some(userSurvey => userSurvey.value === survey.id));
        } else {
            surveys = _.cloneDeep(contentfulData.surveys).filter(survey => links[registryId]?.some(linkedSurvey => linkedSurvey === survey.id));
        }
        if (!targetGeneration.name) {
            return;
        }

        let versions = surveys.map(survey => {
            let surveyResponsesVersions = getEntityResponseByEntityName(targetGeneration.name)[survey.id];
            let versionsNames = []
            if (surveyResponsesVersions) {
                surveyResponsesVersions.map((surveyResponsesVersion) => versionsNames.push(surveyResponsesVersion.name));
            }

            return {
                survey: survey,
                versioning: survey.versioning,
                versions: versionsNames,
            }
        });
        return versions.filter(version => version.versioning.enabled);
    };

    const handleChangeGenerationMode = (targetMode) => {
        setGenerationMode(targetMode);
    }

    const getEntitiesOptions = () => selectedUser?.responses?.map(entity => ({
        label: entity.name.replaceAll('_', ' '),
        value: entity.name
    }));

    const getLangOptions = () => [
        {value: 'FR', label: 'Français'},
        {value: 'EN', label: 'Anglais'},
    ];

    return (
        <DashboardGenerationContainer>
            <TopContainer>
                <TabContainer>
                    <Tab current={generationMode === GENERATION_MODES.WORD}
                         onClick={() => handleChangeGenerationMode(GENERATION_MODES.WORD)}>WORD</Tab>
                    {user?.userType === USER_ROLES.ADMIN &&
                        <Tab current={generationMode === GENERATION_MODES.EXCEL}
                             onClick={() => handleChangeGenerationMode(GENERATION_MODES.EXCEL)}>EXCEL</Tab>
                    }
                    <span>Langue Actuelle: {lang}</span>
                </TabContainer>
                ({selectedUser.remainingDownload} générations restantes)
            </TopContainer>
            <Content>
                {generationMode === GENERATION_MODES.WORD &&
                    <>
                        <FixInputContainer>
                            <InputContainer>
                                <InputComponent
                                    name={'word_choice'}
                                    type={'select'}
                                    label={'Choix du Document'}
                                    options={getDocxOptions()}
                                    onChange={handleTargetDoc}
                                    clearable={true.toString()}
                                    value={getDocxOptions().filter(value => {
                                        return value.value === targetGeneration?.docId;
                                    })}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputComponent
                                    key={'entity_choice'}
                                    name={'entity_choice'}
                                    type={'select'}
                                    label={'Choix de l\'entreprise'}
                                    options={getEntitiesOptions()}
                                    onChange={handleTargetEntity}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputComponent
                                    key={'lang_choice'}
                                    name={'lang_choice'}
                                    type={'select'}
                                    label={'Choix de la langue'}
                                    options={getLangOptions()}
                                    onChange={handleTargetLang}
                                />
                            </InputContainer>
                        </FixInputContainer>
                        <VersionChoiceContainer>
                            {targetGeneration?.name && getSurveysByDocId(targetGeneration?.docId)?.map((link, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {link.versioning.enabled &&
                                            <VersionItem>
                                                <InputComponent
                                                    key={link.survey.name + index}
                                                    name={link.survey.id}
                                                    type={'select'}
                                                    label={link.survey.name}
                                                    options={link.versions.map(version => ({
                                                        label: version.replaceAll('_', ' '),
                                                        value: version
                                                    }))}
                                                    onChange={handleTargetGeneration}
                                                    value={targetGeneration[link.survey.id] || ''}
                                                />
                                            </VersionItem>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </VersionChoiceContainer>
                    </>
                }
                {generationMode === GENERATION_MODES.EXCEL &&
                    <>
                        <FixInputContainer>
                            <InputContainer>
                                <InputComponent
                                    name={'excel_choice'}
                                    type={'select'}
                                    label={'Choix du Registre'}
                                    options={getRegistriesOptions()}
                                    onChange={handleTargetRegistry}
                                    clearable={true.toString()}
                                    value={getRegistriesOptions().filter(value => value.value === targetGeneration?.registryId)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputComponent
                                    key={'entity_choice'}
                                    name={'entity_choice'}
                                    type={'select'}
                                    label={'Choix de l\'entreprise'}
                                    options={getEntitiesOptions()}
                                    onChange={handleTargetEntity}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputComponent
                                    key={'lang_choice'}
                                    name={'lang_choice'}
                                    type={'select'}
                                    label={'Choix de la langue'}
                                    options={getLangOptions()}
                                    onChange={handleTargetLang}
                                />
                            </InputContainer>
                        </FixInputContainer>
                        <VersionChoiceContainer>
                            {targetGeneration?.name && getSurveysByRegistryId(targetGeneration?.registryId)?.map((link, index) => {
                                return (
                                    <VersionItem key={index}>
                                        <InputComponent
                                            key={link.survey.name + index}
                                            name={link.survey.id}
                                            type={'select'}
                                            label={link.survey.name}
                                            options={link.versions.map(version => ({
                                                label: version.replaceAll('_', ' '),
                                                value: version
                                            }))}
                                            onChange={handleTargetGeneration}
                                            value={targetGeneration[link.survey.id] || ''}
                                        />
                                    </VersionItem>
                                )
                            })}
                        </VersionChoiceContainer>
                    </>
                }
                <ActionContainer>
                    <Action onClick={generationMode === GENERATION_MODES.WORD ? handleDocxSubmit : handleExcelSubmit}>Générer
                        les documents</Action>
                </ActionContainer>
            </Content>
        </DashboardGenerationContainer>
    );
}

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
`;

const Tab = styled.div<{ current: boolean }>`
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  ${({current}) => current && `
    border-bottom: 1px solid #187ead;
  `};
`;

const DashboardGenerationContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div``;

const VersionChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 20px;
  column-gap: 20px;
  //flex: 2;
  overflow-y: auto;
  //grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  padding: 20px 20px 60px 20px;

  > * {
    //width: 50%;
  }

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c7c7c7;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 2.1px -1px 12px 0px #f0f0f0;
  }
`;

const FixInputContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  margin-top: 15px;
  
  column-gap: 15px;

  > * {
    flex: 1;
  }
`;

const VersionItem = styled.div``;

const ActionContainer = styled.div`
  display: flex;
  column-gap: 15px;
  justify-content: flex-end;
  padding: 0 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  align-items: center;
  min-height: 70px;
`;

const Action = styled.div`
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 10px;
  background-color: #187EAD;
  color: #fff;

  &:hover {
    color: #187EAD;
    border-color: #187EAD;
    background-color: transparent;
  }
`;


