import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {createUser, getDbUsers, updateUserResponses, updateUserRights} from "../utils/api";
import {selectImpersonated, selectUser, setUser} from "./user.reducer";
import {setLoading} from "./app.reducer";
import {NewUserInfos} from "../components/dashboard-create-user/DashboardCreateUser";

export const createDbUser = createAsyncThunk(
    'user/CREATE_USER',
    async (userInfos: NewUserInfos, thunkApi) => {
        let dispatch = thunkApi.dispatch;
        dispatch(setLoading(true));
        let createdUser = await createUser(userInfos);
        dispatch(setLoading(false));
        return {token: createdUser.xsrfToken, user: createdUser.user};
    }
);
export const getAllUsers = createAsyncThunk(
    'dashboard/GET_ALL_USERS',
    async (args: any, thunkApi) => {
        let allUsers = await getDbUsers();
        let parsedUsers = allUsers.result.map(user => {
            let rights = user.actionRights ? user.actionRights : {};
            return {...user, actionRights: rights}
        });
        return parsedUsers;
    }
);

export const updateRights = createAsyncThunk(
    'user/UPDATE_RIGHTS',
    async (options: { userId: string, actionRights: any }, thunkApi) => {
        let state = thunkApi.getState();
        let user = selectUser(state);
        let isCurrentUser = user._id === options.userId;

        let updatedUser = await updateUserRights(options.userId, options.actionRights);

        if (isCurrentUser) {
            thunkApi.dispatch(setUser(updatedUser.user));
        }

        return updatedUser;
    });

export const updateSelectedUserResponses = createAsyncThunk(
    'user/UPDATE_SELECTED_USER_RESPONSES',
    async (data: { userId: string, values: any }, thunkApi) => {
        let state = thunkApi.getState();
        let impersonated = selectImpersonated(state);
        let userId = impersonated ? selectImpersonated(state)._id : selectUser(state)._id;

        if (data.userId !== undefined) {
            userId = data.userId;
        }
        await updateUserResponses(userId, data.values);

        return data.values;
    });

export const dashboardReducer = createSlice({
    name: 'USER',
    initialState: {
        allUsers: [],
        selectedUser: undefined
    },
    reducers: {
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },
        setAllUsers: (state, action) => {
            state.allUsers = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsers.pending, (state, action) => {
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.allUsers = action.payload;
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                console.log("REJECTED", action.payload)
            })
            .addCase(createDbUser.fulfilled, (state, action) => {
            })
            .addCase(updateRights.fulfilled, (state, action) => {
                state.selectedUser = action.payload.user;
            })
            .addCase(updateSelectedUserResponses.fulfilled, (state, action) => {
                state.selectedUser.responses = JSON.stringify(action.payload);
            })


    }
});

export const {setSelectedUser, setAllUsers} = dashboardReducer.actions;

export const selectAllUsers = state => state.dashboard.allUsers;

export const selectSelectedUser = state => state.dashboard.selectedUser;

export default dashboardReducer.reducer;
