import * as React from 'react';
import styled from 'styled-components';
import {GenericTabRow} from "./GenericTabRow";
import {RiSearchLine} from "react-icons/all";
import {useState} from "react";

export interface DashboardGenericTableProps {
    title?: string;
    headers: any;
    content: any;
    searchable?: boolean;
    action?: any;
    size?: { col: number, row: number };
    subItems?: boolean;
    addFn?: any;
    current?: string;
}

export const DashboardGenericTable = ({
                                          title,
                                          headers,
                                          content,
                                          searchable,
                                          action,
                                          size,
                                          subItems,
                                          addFn,
                                          current
                                      }: DashboardGenericTableProps) => {

    const [search, setSearch] = useState(null);
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value === "" ? null : value);
    };

    const getFilteredRow = () => search === null
        ? content
        : content.filter((row) => row.items[0].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));

    const isAnyAction = () => content && content.some(row => row.actions !== undefined);

    return (
        <DashboardGenericTableContainer size={size}>
            <DashboardTop>
                <DashboardGenericTableTitle>{title}
                    <span>( {content.length} {`entrée${content.length > 1 ? 's' : ''}`} )</span></DashboardGenericTableTitle>
                {searchable &&
                    <SearchContainer>
                        <input onChange={handleSearchChange} placeholder={`Rechercher`} type="text"/>
                        <RiSearchLine/>
                    </SearchContainer>
                }
                {action &&
                    <ActionContainer onClick={action.onClick}>
                        <span>{action.label}</span>
                    </ActionContainer>
                }
            </DashboardTop>
            <DashboardGenericTableContent>
                <GenericTabRow isAnyActions={isAnyAction()} header={true} data={headers}/>
                <Separator/>
                <RowSlider>
                    <RowList>
                        {(content && content.length > 0) && getFilteredRow().map((row, index) => <GenericTabRow current={current}
                            addFn={addFn} subItems={subItems} isAnyActions={isAnyAction()} key={index} data={row}/>)}
                    </RowList>
                </RowSlider>
            </DashboardGenericTableContent>
        </DashboardGenericTableContainer>
    )
};

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #E9EDF7;
`;

const RowSlider = styled.div`
  overflow-y: scroll;
  flex: 1;
  margin-bottom: 10px;
`;

const RowList = styled.div`
  padding-bottom: 10px;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  padding: 20px;

  &:hover {
    color: #187ead;
  }
`;

const DashboardGenericTableContainer = styled.div<{ size: { col: number, row: number } }>`
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;

  ${({size}) => size && `
        grid-column: span ${size.col};
        grid-row: span ${size.row};
    `}
`;

const DashboardGenericTableTitle = styled.div`
  font-size: 1.3em;
  font-weight: 500;
  color: #187EAD;
  padding: 20px;

  span {
    color: #A3AED0;
    font-size: 0.8em;
    margin-left: 5px;
  }
`;

const DashboardGenericTableContent = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);
`;

const DashboardTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
  height: fit-content;

  input {
    border-radius: 20px;
    border: 1px solid #D4D4D4;
    padding-left: 35px;
    font-family: "Baloo 2";
    font-size: 1.125em;

    &:focus-visible {
      outline: none;
    }
  }

  svg {
    font-size: 20px;
    position: absolute;
    left: 6px;
    top: 5px;
  }
`;

