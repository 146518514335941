import styled from "styled-components";
import dipeeoLogoWhite from '../../assets/logo-blanc.svg';
import dipeeoLogoBlue from '../../assets/logo-bleu.png';

interface LogoProps {
    subtitle?: string;
    className?: string;
    onClick?: () => void;
    whiteBg: boolean;
    large?: boolean;
}

export const Logo = ({subtitle, className, onClick, whiteBg, large}: LogoProps) => {
    const logo = whiteBg ? dipeeoLogoBlue : dipeeoLogoWhite;
    return (
        <LogoContainer large={large} onClick={onClick} className={className}>
            <img src={logo} alt="Logo Dipeeo"/>
            {/*<Subtitle>{subtitle}</Subtitle>*/}
        </LogoContainer>
    )
}

const LogoContainer = styled.div<{ large: boolean }>`
  position: relative;

  img {
    max-width: ${({large}) => large ? '300px' : '150px'};
    ${({large}) => large && 'height: 70px;'}
  }
`;

const Subtitle = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
  color: #f49d1d;
  font-size: 0.8125em;
  font-family: 'Baloo 2';
  font-weight: 700;
`;
