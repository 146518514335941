import * as React from 'react';
import styled from 'styled-components';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {FormSteps} from "../form-steps/FormSteps";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCurrentEntity, selectCurrentStep,
    selectCurrentSurvey, selectCurrentVersion, selectEntities,
    setCurrentStep,
} from "../../reducers/app.reducer";
import {handleConditions} from "../../utils/utils";
import {StepQuestions} from "../form/StepQuestions";

ChartJS.register(ArcElement, Tooltip, Legend);

export interface NewFormProps {
};

export const ModularForm = ({}: NewFormProps) => {

    const dispatch = useDispatch();
    const currentEntity = useSelector(selectCurrentEntity);
    const currentSurvey = useSelector(selectCurrentSurvey);
    const currentVersion = useSelector(selectCurrentVersion);
    const currentStep = useSelector(selectCurrentStep);
    const entities = useSelector(selectEntities);

    const getCurrentEntityResponses = () => {
        if (!entities) {
            return;
        }
        return entities?.find(entity => entity.name === currentEntity.name)?.responses
    };

    const getSteps = () => {
        let values = getCurrentEntityResponses()?.[currentSurvey.id]?.[currentVersion];
        return currentSurvey.steps.map((step, index) => {
            return {
                onClick: () => dispatch(setCurrentStep(index + 1)),
                current: index + 1 === currentStep,
                label: step.name,
                condition: handleConditions(step, values || {})
            }
        });
    };

    return (
        <FormWrapper>
            <FormContainer>
                <FormSteps steps={getSteps()}/>
                <StepQuestions/>
            </FormContainer>
        </FormWrapper>
    )
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  width: 100%;
  row-gap: 20px;
  flex: 1;
`;

const FormContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
`;

