import './App.scss';
import 'flag-icons';
import {
    Switch,
    Route,
    withRouter,
    useHistory,
    Prompt
} from "react-router-dom";
import {Login} from "./components/login/login";
import {AdminPanel} from "./components/admin-panel/AdminPanel";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import Loader from "./components/loader/loader";
import {
    selectCurrentEntity, selectCurrentSurvey, selectEntities,
    selectLoading,
    selectErrors,
    resetState,
    selectDirty
} from "./reducers/app.reducer";
import {selectToken, selectUser, setUser, disconnectUser} from "./reducers/user.reducer";
import styled from "styled-components";
import {
    RiDashboardLine,
    RiAdminLine,
} from "react-icons/all";
import {SideNav} from "./components/side-nav/Sidenav";
import {Dashboard} from "./components/dashboard/Dashboard";
import {UserComponent} from "./components/userComponent/UserComponent";
import {ModularForm} from "./components/modular-form/ModularForm";
import {fetchAllContentfulData, resetContentfulReducer} from "./reducers/contentful.reducer";
import {DashboardDataModuleLight} from "./components/dashboard-data-module/DashboardDataModuleLight";
import {DashboardCardSlider} from "./components/dashboard-card-slider/DashboardCardSlider";
import {Modal} from "./components/modal/Modal";
import {Button} from "./components/button";
import {getSessionToken, getSessionUserId, setSessionToken, setSessionUser} from "./utils/utils";
import {USER_ROLES} from "./utils/constants";
import {UserDetails} from "./components/dashboard-user-details/UserDetails";
import {getUserById} from "./utils/api";

export enum pathes {
    login = '/connexion',
    compliance = '/conformite-rgpd',
    dashboard = '/tableau-de-bord',
    register = '/registres',
    form = '/form',
    inventory = '/inventaires-des-sous-traitants',
    documents = '/documents',
    conservation = '/durees-de-conservation',
    quiz = '/quiz',
    question = '/foire-aux-question',
    admin = '/panneau-administrateur'
}

const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event) => {
        if (showExitPrompt) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = ''
            }
            return '';
        }
    };
}

function App() {
    const loading = useSelector(selectLoading);
    const user = useSelector(selectUser);
    const currentEntity = useSelector(selectCurrentEntity);
    const entities = useSelector(selectEntities);
    const currentSurvey = useSelector(selectCurrentSurvey);
    const errors = useSelector(selectErrors);
    const history = useHistory();
    const dispatch = useDispatch();
    const surveyIsDirty = useSelector(selectDirty);

    useEffect( () => {
        initBeforeUnLoad(surveyIsDirty);
    }, [surveyIsDirty]);

    useEffect(() => {
        (async () => {
            if (user) {
                if (history.location.pathname === pathes.login) {
                    history.push(pathes.dashboard);
                } else {
                    history.push(history.location.pathname);
                }
            } else {
                history.push(pathes.login);
            }
        })()
    }, [user]);

    useEffect(() => {
        initBeforeUnLoad(false);
        (async () => {
            if (user) {
                return;
            }
            let sessionToken = getSessionToken();
            let loggedUserId = getSessionUserId();
            if (!loggedUserId || !sessionToken) {
                history.push(pathes.login);
                return;
            }
            let userId = JSON.parse(loggedUserId)
            const userData = await getUserById(userId);
            dispatch(setUser(userData));
        })()

    }, []);

    const getEntitiesCountersTotals = () => {
        let counters = [];
        entities?.forEach(entity => {
            entity?.counters?.forEach((counter, index) => {
                if (!counters[index]) {
                    counters[index] = {
                        label: counter.label,
                        description: counter.description,
                        amount: 0,
                        color: counter.color,
                    }
                }
                counters[index].amount += counter.amount;
            })
        });
        return counters;
    };

    const menuItems = [
        {
            label: 'Tableau de bord',
            path: pathes.dashboard,
            icon: RiDashboardLine
        },
        {
            label: 'Panneau D\'administration',
            path: pathes.admin,
            icon: RiAdminLine,
            admin: true
        }
    ]

    const disconnect = () => {
        let leaveAnyway = true;
        if(surveyIsDirty) {
            leaveAnyway = window.confirm("Vous avez des changements non sauvegardés. Etes vous sûr de vouloir quitter la page?")
        }
        if(leaveAnyway) {
            window.sessionStorage.removeItem('logged_user');
            window.sessionStorage.removeItem('access_token');
            window.localStorage.clear();
            window.sessionStorage.clear();
            dispatch(resetState());
            dispatch(resetContentfulReducer());
            dispatch(disconnectUser());
        }
    }

    return (
        <AppContainer className="App">
            {loading && <Loader/>}
            {user && <SideNav menuItems={menuItems}/>}
            <Prompt
                when={surveyIsDirty}
                message="Vous avez des changements non sauvegardés. Etes vous sûr de vouloir quitter la page?" />
            <Modal onClose={() => disconnect()} size={{height: undefined, width: '40%'}}
                   show={errors}
                   title={'Erreur détectée'}>

                <ErrorModalContainer>
                    <ErrorMessageContainer>
                        <ErrorMessage>Vous avez été déconnecté.</ErrorMessage>
                        <ErrorMessage>Pour vous reconnecter, veuillez cliquer sur le bouton ci-dessous</ErrorMessage>
                    </ErrorMessageContainer>
                    <Button label={'Me reconnecter'} onClick={disconnect}/>
                </ErrorModalContainer>
            </Modal>
            {/*{currentEntity && <ConformitySteps currentStep={parseInt(currentEntity.currentStep) + 1} steps={ENTITY_STEPS}/>}*/}
            <Content>
                {(history.location.pathname !== pathes.login || history.location.pathname !== pathes.login) &&
                    <Header location={history.location.pathname}>
                        <HeaderContent>
                            {history.location.pathname === pathes.dashboard && user?.userType !== USER_ROLES.AGENCY &&
                                <DashboardDataModuleLight
                                    dataArray={currentEntity ? currentEntity.counters : getEntitiesCountersTotals()}/>
                            }
                            {history.location.pathname === pathes.compliance &&
                                <Title>{currentSurvey?.name} - {currentEntity?.name}</Title>
                            }
                            {user && <UserComponent user={user}/>}
                        </HeaderContent>
                    </Header>
                }
                {history.location.pathname === pathes.dashboard &&
                    <DashboardCardSlider/>
                }
                <ContentContainer>
                    <Switch>
                        <Route path={pathes.admin}>
                            {user && <AdminPanel/>}
                        </Route>
                        <Route path={pathes.dashboard}>
                            {user && <Dashboard/>}
                        </Route>
                        <Route path={pathes.compliance}>
                            {user && <ModularForm/>}
                        </Route>
                        <Route path={pathes.register}>
                            <ContentTitle>Registres</ContentTitle>
                        </Route>
                        <Route path={pathes.inventory}>
                            <ContentTitle>Inventaires</ContentTitle>
                        </Route>
                        <Route path={pathes.conservation}>
                            <ContentTitle>Durées de conservation</ContentTitle>
                        </Route>
                        <Route path={pathes.documents}>
                            <ContentTitle>Documents</ContentTitle>
                        </Route>
                        <Route path={pathes.quiz}>
                            <ContentTitle>Quiz</ContentTitle>
                        </Route>
                        <Route path={pathes.question}>
                            <ContentTitle>Foire aux questions</ContentTitle>
                        </Route>
                        <Route path={pathes.login}>
                            <Login/>
                        </Route>
                    </Switch>
                </ContentContainer>
            </Content>
        </AppContainer>
    );
}

export default withRouter(App);

const Title = styled.div`
  color: white;
  font-size: 1.3em;
  text-transform: uppercase;
`;


const Header = styled.header<{ location: string }>`
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;

  ${({location}) => location === pathes.dashboard && `
      height: 250px;
      min-height: 250px;
  `}

  background-image: linear-gradient(90deg, #49BFBD 0%, #1179A0 100%);
`;

const ContentTitle = styled.div`
  position: absolute;
  top: 28px;
  left: 200px;
  font-family: 'Baloo 2', serif;
  font-weight: 600;
  font-size: 2em;
  color: #57606f;
`;

const AppContainer = styled.div`
  width: 100%;
  //background-color: #F4F7FE;
  height: 100vh;
  position: relative;
  display: flex;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 300px);
  flex: 1;
`;

const ContentContainer = styled.div`
  //display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 20px;
  flex: 1;
  height: calc(100% - 406px);
  /*padding: 40px 100px 40px 100px;
  height: calc(100% - 160px);*/
`;

const SidenavItem = styled.div`

`;

const HeaderContent = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
`;
const ErrorModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px
`;
const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 40px);
`;

const ErrorMessage = styled.div`
  font-size: 1.2em;
  text-align: center;

  b {
    color: #1179A0;
  }
`;


