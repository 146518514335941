import {configureStore, MiddlewareArray} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import ContentfulReducer from "../reducers/contentful.reducer";
import {useDispatch} from "react-redux";
import appReducer from "../reducers/app.reducer";
import userReducer from "../reducers/user.reducer";
import dashboardReducer from "../reducers/dashboard.reducer";

export type AppDispatch = typeof store.dispatch;

const store = configureStore({
    reducer: {
        contentful: ContentfulReducer,
        user: userReducer,
        dashboard: dashboardReducer,
        app: appReducer
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});

export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store;
