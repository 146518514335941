import * as React from 'react';
import styled from 'styled-components';
import {selectAllUsers} from "../../reducers/dashboard.reducer";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FaRegTimesCircle, FaTimes} from "react-icons/all";
import {USER_ROLES} from "../../utils/constants";
import {createDbUser} from "../../reducers/user.reducer";

export interface DashboardCreateUserProps {
    close: () => void;
}

export interface NewUserInfos {
    email: string;
    password: string;
    userName: string;
    userType: string;
    cgu: boolean;
    withProduct: boolean;
    processor: boolean;
}

export const DashboardCreateUser = ({close}: DashboardCreateUserProps) => {

    const dispatch = useDispatch();
    const allUsers = useSelector(selectAllUsers);

    const initialState = {
        email: '',
        password: '',
        userName: '',
        userType: 'user',
        cgu: false,
        withProduct: false,
        processor: false
    }

    //state
    const [newUserInfos, setNewUserInfos] = useState(initialState);

    const handleCreateUser = async () => {
        await dispatch(createDbUser({
            email: newUserInfos.email,
            password: newUserInfos.password,
            userName: newUserInfos.userName,
            userType: newUserInfos.userType,
            cgu: newUserInfos.cgu,
            withProduct: newUserInfos.withProduct,
            processor: newUserInfos.processor
        }));
        handleCloseCreateUser();
    }

    const handleUserInfoChange = (e) => {
        setNewUserInfos({
            ...newUserInfos,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }

    const handleCloseCreateUser = () => {
        setNewUserInfos(initialState);
        close();
    }

    const isEmailAlreadyUsed = (email) => allUsers.some(user => user.email === email);

    return (
        <CreateUserContainer>
            <BrowserAutofillCatcher type="text" name={'email'}/>
            <BrowserAutofillCatcher type="password" name={'password'}/>
            <CreateUserTop>
                <span>Créer un utilisateur</span>
                <CloseIcon onClick={handleCloseCreateUser}/>
            </CreateUserTop>
            <CreateUserForm>
                <CreateUserInputContainer>
                    <CreateUserInputLabel>Nom de l'utilisateur</CreateUserInputLabel>
                    <CreateUserInput
                        type="text"
                        name="userName"
                        value={newUserInfos.userName}
                        onChange={handleUserInfoChange}
                    />
                    {!newUserInfos.userName &&
                        <CreateUserInputError>Ce champ ne doit pas être vide</CreateUserInputError>}
                </CreateUserInputContainer>
                <CreateUserInputContainer>
                    <CreateUserInputLabel>Email</CreateUserInputLabel>
                    <CreateUserInput
                        type="text"
                        name="email"
                        value={newUserInfos.email}
                        onChange={handleUserInfoChange}
                    />
                    {isEmailAlreadyUsed(newUserInfos.email) &&
                        <CreateUserInputError>Cet email est déjà utilisé</CreateUserInputError>}
                </CreateUserInputContainer>
                <CreateUserInputContainer>
                    <CreateUserInputLabel>Mot de passe</CreateUserInputLabel>
                    <CreateUserInput
                        type="password"
                        name="password"
                        value={newUserInfos.password}
                        onChange={handleUserInfoChange}
                    />
                </CreateUserInputContainer>
                <CreateUserInputContainer>
                    <CreateUserInputLabel>Rôle</CreateUserInputLabel>
                    <CreateUserSelect name={'userType'} onChange={handleUserInfoChange}>
                        <CreateUserOption value={USER_ROLES.CLIENT}>Client</CreateUserOption>
                        <CreateUserOption value={USER_ROLES.ADMIN}>Administrateur</CreateUserOption>
                        <CreateUserOption value={USER_ROLES.AGENCY}>Agence</CreateUserOption>
                    </CreateUserSelect>
                </CreateUserInputContainer>
                <CreateUserInputContainer>
                    <CreateUserInputLabel>Le client est-il un sous-traitant ? </CreateUserInputLabel>
                    <CreateUserInput
                        type="checkbox"
                        name="processor"
                        value={newUserInfos.processor}
                        onChange={handleUserInfoChange}
                    />
                </CreateUserInputContainer>
                <CreateUserInputContainer>
                    <CreateUserInputLabel>Le client a-t-il un produit ?</CreateUserInputLabel>
                    <CreateUserInput
                        type="checkbox"
                        name="withProduct"
                        value={newUserInfos.withProduct}
                        onChange={handleUserInfoChange}
                    />
                </CreateUserInputContainer>
                <CreateUserInputContainer>
                    <CreateUserInputLabel>Client avec des CGU ? </CreateUserInputLabel>
                    <CreateUserInput
                        type="checkbox"
                        name="cgu"
                        value={newUserInfos.cgu}
                        onChange={handleUserInfoChange}
                    />
                </CreateUserInputContainer>
                <CreateUserButton {...((isEmailAlreadyUsed(newUserInfos.email) || !newUserInfos.userName) && {disabled: 'disabled'})}
                                  onClick={handleCreateUser}>Créer</CreateUserButton>
            </CreateUserForm>

        </CreateUserContainer>
    )
}

const BrowserAutofillCatcher = styled.input`
  opacity: 0;
  position: absolute;
`;

const CloseIcon = styled(FaRegTimesCircle)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 25px;
  height: 25px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    transform: rotate(90deg);
  }
`;

const CreateUserInputError = styled.div`
  color: red;
`;

const CreateUserContainer = styled.div`
  grid-column: span 5;
  grid-row: span 8;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
  width: 100%;
  height: 100%;
`;

const CreateUserTitle = styled.div``;

const CreateUserForm = styled.div`
  padding: 20px;
  width: calc(100% - 40px);
  //height: calc(100% - 40px);
  /* display: flex;
   flex-direction: column;*/
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
`;

const CreateUserInputContainer = styled.div``;

const CreateUserSelect = styled.select``;

const CreateUserOption = styled.option``;

const CreateUserInputLabel = styled.div``;

const CreateUserInput = styled.input``;

const CreateUserButton = styled.button``;

const CreateUserTop = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  width: calc(100% - 40px);
  color: black;
  column-gap: 20px;
  justify-content: space-between;

  span {
    font-size: 1.5em;
  }
`;
