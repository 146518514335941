import * as React from 'react';
import styled from 'styled-components';
import {BiAddToQueue, TbVersions} from "react-icons/all";

export interface GenericTabRowProps {
    header?: boolean;
    data: any;
    isAnyActions?: boolean;
    subItems?: boolean;
    addFn?: any;
    current?: string;
};

export const GenericTabRow = ({header, data, isAnyActions, subItems, addFn, current}: GenericTabRowProps) => {
    return (
        <>
            <GenericTabRowContainer versioned={data?.versioning?.enabled} subItems={subItems} current={current === data?.items[0]}
                                    onClick={data.onClick} header={header}>
                {data.items.map((value, index) =>
                    <RowData
                        key={index}
                        expanded={index === 0}
                        header={header}
                    >
                        <span>{value?.replace('_', ' ')}</span>
                    </RowData>)}
                {header && isAnyActions &&
                    <Spacer/>
                }
                {data.actions &&
                    <>
                        <RowAction>
                            {data.actions.map((action, index) => {
                                let ActionIcon = action?.icon;
                                return (
                                    <RowActionItem
                                        key={index}
                                        onClick={action.onClick}>
                                        <ActionIcon/>
                                    </RowActionItem>
                                )
                            })}
                        </RowAction>
                    </>
                }
            </GenericTabRowContainer>
            {!header && data?.versioning?.enabled &&
                <>
                    {data?.subItems?.map((subItem, index) => <SubItems onClick={subItem.onClick}
                                                                       key={index}><TbVersions/>{subItem.label.replace('_', ' ')}
                    </SubItems>)}
                    {!header && subItems &&
                        <SubItems onClick={() => addFn(data._id)}><BiAddToQueue/>Ajouter une version +</SubItems>}
                </>
            }
        </>
    )
}

const SubItems = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    background-color: gray;
    color: white;
  }
;
`;

const Spacer = styled.div`
  width: 80px;
  height: 100%;
`;

const GenericTabRowContainer = styled.div<{ header: boolean, current: boolean, subItems: boolean, versioned?: boolean }>`
  display: flex;
  width: calc(100% - 40px);
  height: 40px;
  align-items: center;
  transition: all 0.2s ease-in-out;
  padding: 5px 20px;
  user-select: none;
  column-gap: 10px;
  justify-content: space-between;

  ${({versioned}) => versioned && `
    span {
        color: #187EAD;
        font-size: 1.1em;
        text-decoration: underline;
    }
  `}

  ${({current}) => current && `
    background-color: #187ead !important;
    color: white !important;
    > div {
        color: white !important;
    }
  `}

  ${({header, versioned}) => !header && !versioned && `
    cursor: pointer;
    &:hover {
        background-color: gray;
       
        span {
            color: white;
        }
    }
  `}

  ${({header}) => header && `
    width: calc(100% - 50px);
    margin-right: 10px;
    margin-bottom: -12px;
  `}
`;

const Separator = styled.div`
  height: calc(100% - 20px);
  width: 1px;
  margin: 10px 0;
  background-color: #E9EDF7;
`;

const RowData = styled.div<{ header: boolean, expanded: boolean, current: boolean }>`
  width: 140px;
  color: ${({header}) => header ? '#A3AED0' : '#57606f'};
  font-weight: ${({header}) => header ? '600' : '400'};
  ${({expanded}) => expanded && `flex: 1;`}
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;

  ${({current}) => current && `
        color: white;
    `}
`;

const RowAction = styled.div<{ current: boolean }>`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  ${({current}) => current && `
        color: white
    `}
`;

const RowActionItem = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: #187ead;
    }
  }

  &:hover {
    svg {
      color: white;
    }
  }
`;
