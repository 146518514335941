import * as React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from "react-redux";
import {
    selectAllUsers,
    selectSelectedUser,
    setAllUsers,
    setSelectedUser,
    updateRights
} from "../../reducers/dashboard.reducer";
import {useEffect, useRef, useState} from "react";
import {selectLoading, setLoading} from "../../reducers/app.reducer";
import {selectUser, updateResponses, updateUser} from "../../reducers/user.reducer";
import {selectDocuments, selectRegistries, selectSurveys} from "../../reducers/contentful.reducer";
import _ from "lodash";
import {setSessionUser} from "../../utils/utils";
import {DashboardGeneration} from "../dashboard-generation/DashboardGeneration";
import {COUNTER_TYPES, ENTITY_STATUS, ENTITY_STEPS, USER_ROLES} from "../../utils/constants";
import {getUserById} from "../../utils/api";

export interface UserDetailsProps {
}

export enum UserDetailsType {
    AUTHORIZATIONS = 'surveys',
    STEPS = 'steps',
    STATS = 'counters',
    VALIDATIONS = 'validations',
    GENERATIONS = 'generations',
    INFOS = 'infos',
}

export const UserDetails = ({}: UserDetailsProps) => {
    //REDUX
    const dispatch = useDispatch();
    const selectedUser = useSelector(selectSelectedUser);
    const contentfulDocuments = useSelector(selectDocuments);
    const contentfulRegistries = useSelector(selectRegistries);
    const contentfulSurveys = useSelector(selectSurveys);
    const loading = useSelector(selectLoading);
    const user = useSelector(selectUser);
    const allUsers = useSelector(selectAllUsers);

    //STATE
    const [currentActionRights, setCurrentActionRights] = useState(selectedUser?.actionRights);
    const [currentTab, setCurrentTab] = useState(UserDetailsType.INFOS);
    const [currentResponses, setCurrentResponses] = useState(null);

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    let previousUser: any | undefined = usePrevious(selectedUser);

    useEffect(() => {
        if (!selectedUser) {
            return;
        }
        let parseActionRights = typeof selectedUser.actionRights === "string" ? JSON.parse(selectedUser.actionRights) : selectedUser.actionRights;
        setCurrentActionRights(_.cloneDeep(parseActionRights));
        if (previousUser?._id !== selectedUser._id) {
            if (user?.userType === USER_ROLES.AGENCY) {
                handleChangeTab(UserDetailsType.GENERATIONS);
            } else {
                handleChangeTab(UserDetailsType.INFOS);
            }
        }
        setCurrentResponses([...selectedUser?.responses]);
    }, [selectedUser]);


    const handleToggleSurvey = (survey) => {
        const newActionRights = _.cloneDeep(currentActionRights);
        if (isSurveyChecked(survey)) {
            newActionRights.surveys = newActionRights.surveys.filter(currentSurvey => currentSurvey.value !== survey.id);
        } else {
            newActionRights.surveys.push({value: survey.id, label: survey.name});
        }
        setCurrentActionRights(newActionRights);
    }

    const handleToggleRegistry = (registry) => {
        const newActionRights = _.cloneDeep(currentActionRights);
        if (isRegistryChecked(registry)) {
            newActionRights.registries = newActionRights.registries.filter(currentRegistry => currentRegistry.value !== registry.id);
        } else {
            newActionRights.registries.push({value: registry.id, label: registry.name});
        }
        setCurrentActionRights(newActionRights);
    }

    const getUserCreationDate = () => {
        if (!selectedUser) {
            return;
        }
        return new Date(selectedUser.createdAt).toLocaleDateString();
    }

    const getLegalOfficer = (userName) => allUsers.find(userItem => userItem.userName === userName);

    const getSuperAdmin = () => {
        let admins = allUsers.filter(user => user.userType === USER_ROLES.ADMIN);
        return admins.map((adminUser, index) => {
            return (
                <option
                    key={index}
                    value={adminUser.userName}>{adminUser.userName}
                </option>
            )
        })
    }

    const handleChangeTab = (tab) => {
        setCurrentTab(tab);
    }

    const handleEntityCurrentStepChange = (e) => {
        const newUserResponses = _.cloneDeep(currentResponses);
        const targetEntityName = e.target.name;
        const targetEntity = newUserResponses.find(entity => entity.name === targetEntityName);
        targetEntity.currentStep = parseInt(e.target.value);
        setCurrentResponses(newUserResponses);
    };

    const handleEntityRejection = async (entityName) => {
        dispatch(setLoading(true));
        let entitiesCopy = [...selectedUser.responses];
        let currentEntityCopy = entitiesCopy.find(entityItem => entityItem.name === entityName);
        currentEntityCopy.status = ENTITY_STATUS.REJECTED;
        await dispatch(updateResponses({userId: selectedUser.id, values: entitiesCopy}));
        dispatch(setLoading(false));
    }

    const handleEntityValidation = async (entityName) => {
        dispatch(setLoading(true));
        let entitiesCopy = [...selectedUser.responses];
        let currentEntityCopy = entitiesCopy.find(entityItem => entityItem.name === entityName);
        currentEntityCopy.status = ENTITY_STATUS.VALIDATED;
        await dispatch(updateResponses({userId: selectedUser.id, values: entitiesCopy}));
        dispatch(setLoading(false));
    }

    const handleLinkChange = (e) => {
        const newSelectedUser = _.cloneDeep(selectedUser);
        if (e.target.value.trim().length > 0) {
            if (!newSelectedUser.links.some(link => link.name === e.target.name)) {
                newSelectedUser.links.push({
                    name: e.target.name,
                    value: e.target.value
                });
            } else {
                newSelectedUser.links.map(link => {
                    if (link.name === e.target.name) {
                        link.value = e.target.value;
                    }
                    return link;
                })
            }
        } else {
            newSelectedUser.links = newSelectedUser.links.filter(link => link.name !== e.target.name);
        }
        dispatch(setSelectedUser(newSelectedUser));
    };

    const handleSaveStats = async () => {
        dispatch(setLoading(true))
        await dispatch(updateResponses({userId: selectedUser._id, values: currentResponses}));
        dispatch(setLoading(false))
    };

    const handleToggleDocument = (docx) => {
        const newActionRights = _.cloneDeep(currentActionRights);
        if (isDocumentChecked(docx)) {
            newActionRights.documents = newActionRights.documents.filter(currentDocx => currentDocx.value !== docx.id);
        } else {
            newActionRights.documents.push({value: docx.id, label: docx.name});
        }
        setCurrentActionRights(newActionRights);
    };

    const handleUserInfosChange = (e, isBoolean: boolean = false) => {
        console.log("handleUserInfosChange - SelectedUser :", e.target.value,e.target.name, isBoolean, selectedUser)
        const newSelectedUser = _.cloneDeep(selectedUser);
        newSelectedUser[e.target.name] = isBoolean ? e.target.value === "true" : e.target.value;
        dispatch(setSelectedUser(newSelectedUser));
    }

    const handleUserLegalOfficerChange = (e) => {
        const newSelectedUser = _.cloneDeep(selectedUser);
        let legalOfficer = getLegalOfficer(e.target.value);
        newSelectedUser.legalOfficer = {
            name: legalOfficer.userName,
            email: legalOfficer.email,
        };
        dispatch(setSelectedUser(newSelectedUser));
    };

    const isDocumentChecked = (docx) => {
        if (!currentActionRights) {
            return false;
        }
        return currentActionRights.documents.some(currentDocx => currentDocx.value === docx.id);
    }

    const isRegistryChecked = (registry) => {
        if (!currentActionRights) {
            return false;
        }
        return currentActionRights.registries.some(currentRegistry => currentRegistry.value === registry.id);
    }

    const isSurveyChecked = (survey) => {
        if (!currentActionRights) {
            return false;
        }
        return currentActionRights.surveys.some(currentSurvey => currentSurvey.value === survey.id);
    }

    const refreshUserData = async () => {
        const userRefreshedData = await getUserById(selectedUser._id);
        let allUsersCopy = [...allUsers];
        let removedUserIdx = null;
        allUsersCopy = allUsersCopy.filter((user, index) => {
            if (user._id !== selectedUser._id) {
                return user;
            }
            removedUserIdx = index;
        });

        allUsersCopy.splice(removedUserIdx, 0, userRefreshedData);

        dispatch(setAllUsers(allUsersCopy));
    }

    const saveCurrentSteps = async () => {
        dispatch(setLoading(true))
        await dispatch(updateResponses({userId: selectedUser._id, values: currentResponses}));
        await refreshUserData();
        dispatch(setLoading(false))
    }

    const saveSelectedUserInfos = async () => {
        dispatch(setLoading(true));
        await dispatch(updateUser({userId: selectedUser._id, selectedUser: selectedUser}));
        await refreshUserData();
        dispatch(setLoading(false));
    }

    const saveSelectedUserRights = async () => {
        dispatch(setLoading(true))
        await dispatch(updateRights({userId: selectedUser._id, actionRights: currentActionRights}));
        await refreshUserData();
        dispatch(setLoading(false));
    }

    const updateCurrentEntityCounterById = (counterId, amount, targetEntity) => {
        const newUserResponses = _.cloneDeep(currentResponses);
        const entity = newUserResponses.find(entity => entity.name === targetEntity.name);
        const counter = entity.counters.find(counter => counter.id === counterId);

        if (counter) {
            counter.amount = parseInt(amount);
            let currentCounterIndex = entity.counters.findIndex(counter => counter.id === counterId);
            let newCounterIndex = COUNTER_TYPES.findIndex(counter => counter.id === counterId)
                if (currentCounterIndex !== newCounterIndex) {
                    let changedCounter = entity.counters.splice(currentCounterIndex, 1)[0];
                    entity.counters.splice(newCounterIndex, 0, changedCounter);
                }
        } else {
            let newCounter = COUNTER_TYPES.find(counter => counter.id === counterId);
            let newCounterIndex = COUNTER_TYPES.findIndex(counter => counter.id === counterId);
            newCounter.amount = parseInt(amount);
            entity.counters.splice(newCounterIndex, 0, newCounter)
        }
        setCurrentResponses(newUserResponses);
    }

    return (
        <>
            {selectedUser &&
                <UserDetailsContainer>
                    <UserDetailsTop>
                        <span>{selectedUser?.userName}</span> <Since>Client depuis le {getUserCreationDate()}</Since>
                    </UserDetailsTop>
                    <Separator/>
                    <TabContainer>
                        {user?.userType !== USER_ROLES.AGENCY &&
                            <>
                                <Tab
                                    current={currentTab === UserDetailsType.INFOS}
                                    onClick={() => handleChangeTab(UserDetailsType.INFOS)}>Infos</Tab>
                                <Tab
                                    current={currentTab === UserDetailsType.AUTHORIZATIONS}
                                    onClick={() => handleChangeTab(UserDetailsType.AUTHORIZATIONS)}>
                                    Autorisations</Tab>
                                {/*<Tab
                                    current={currentTab === UserDetailsType.VALIDATIONS}
                                    onClick={() => handleChangeTab(UserDetailsType.VALIDATIONS)}>Validations</Tab>*/}
                                <Tab
                                    current={currentTab === UserDetailsType.STEPS}
                                    onClick={() => handleChangeTab(UserDetailsType.STEPS)}>Étapes</Tab>
                                <Tab
                                    current={currentTab === UserDetailsType.STATS}
                                    onClick={() => handleChangeTab(UserDetailsType.STATS)}>Stats</Tab>
                            </>
                        }
                        <Tab
                            current={currentTab === UserDetailsType.GENERATIONS}
                            onClick={() => handleChangeTab(UserDetailsType.GENERATIONS)}>Générations</Tab>
                    </TabContainer>
                    <Separator/>
                    {!loading &&
                        <Content>
                            {currentTab === UserDetailsType.INFOS &&
                                <GridContainer>
                                    <InfoItem>
                                        <span>Nom </span>
                                        <input defaultValue={selectedUser?.userName}
                                               name={'userName'}
                                               onChange={handleUserInfosChange}
                                               placeholder={'nom complet de l\'utilisateur'} type="text"/>
                                    </InfoItem>
                                    <InfoItem>
                                        <span>Email </span>
                                        <input defaultValue={selectedUser?.email}
                                               name={'email'}
                                               onChange={handleUserInfosChange}
                                               placeholder={'adresse mail de l\'utilisateur'} type="email"/>
                                    </InfoItem>
                                    <InfoItem>
                                        <span>L'utilisateur à un produit : </span>
                                        <label htmlFor={'with_product_true'}>
                                            <input defaultChecked={selectedUser.withProduct === true}
                                                   name={'withProduct'}
                                                   onChange={e => handleUserInfosChange(e, true)}
                                                   type="radio"
                                                   id={'with_product_true'}
                                                   value='true'/>
                                            Oui
                                        </label>
                                        <label htmlFor={'with_product_false'}>
                                            <input
                                                defaultChecked={selectedUser.withProduct === false || !selectedUser.hasOwnProperty('withProduct')}
                                                name={'withProduct'}
                                                onChange={e => handleUserInfosChange(e, true)}
                                                type="radio"
                                                id={'with_product_false'}
                                                value='false'/>
                                            Non
                                        </label>
                                    </InfoItem>
                                    <InfoItem>
                                        <span>L'utilisateur à des CGU : </span>
                                        <label htmlFor={'cgu_true'}>
                                            <input defaultChecked={selectedUser.cgu === true}
                                                   name={'cgu'}
                                                   onChange={e => handleUserInfosChange(e, true)}
                                                   type="radio"
                                                   id={'cgu_true'}
                                                   value='true'/>
                                            Oui
                                        </label>
                                        <label htmlFor={'cgu_false'}>
                                            <input
                                                defaultChecked={selectedUser.cgu === false || !selectedUser.hasOwnProperty('cgu')}
                                                name={'cgu'}
                                                onChange={e => handleUserInfosChange(e, true)}
                                                type="radio"
                                                id={'cgu_false'}
                                                value='false'/>
                                            Non
                                        </label>
                                    </InfoItem>
                                    <InfoItem>
                                        <span>L'utilisateur est un sous-traitant :</span>
                                        <label htmlFor={'processor_true'}>
                                            <input defaultChecked={selectedUser.cgu === true}
                                                   name={'processor'}
                                                   onChange={e => handleUserInfosChange(e, true)}
                                                   type="radio"
                                                   id={'processor_true'}
                                                   value='true'/>
                                            Oui
                                        </label>
                                        <label htmlFor={'processor_false'}>
                                            <input
                                                defaultChecked={selectedUser.cgu === false || !selectedUser.hasOwnProperty('processor')}
                                                name={'processor'}
                                                onChange={e => handleUserInfosChange(e, true)}
                                                type="radio"
                                                id={'processor_false'}
                                                value='false'/>
                                            Non
                                        </label>
                                    </InfoItem>
                                    <InfoItem><span>Client depuis le </span>{getUserCreationDate()}</InfoItem>
                                    <InfoItem>
                                        <span>Type d'utilisateur </span>
                                        {user?.userType === USER_ROLES.ADMIN &&
                                            <select defaultValue={selectedUser.userType} onChange={handleUserInfosChange}
                                                    name="userType" id="">
                                                {Object.keys(USER_ROLES).map((type, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={USER_ROLES[type]}>{USER_ROLES[type]}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        }
                                    </InfoItem>
                                    <InfoItem>
                                        <span>Téléchargement restant </span>
                                        {user?.userType === USER_ROLES.ADMIN ?
                                            <input defaultValue={selectedUser.remainingDownload}
                                                   name={'remainingDownload'}
                                                   type="number"
                                                   onChange={handleUserInfosChange}/>
                                            :
                                            <span>{selectedUser.remainingDownload}</span>
                                        }

                                    </InfoItem>
                                    <InfoItem>
                                        <span>Google Drive </span>
                                        <input defaultValue={!!selectedUser?.links && selectedUser?.links[0]?.value}
                                               name={'google_drive'}
                                               onChange={handleLinkChange}
                                               placeholder={'adresse du drive '} type="text"/>
                                    </InfoItem>
                                    <InfoItem>
                                        <span>Responsable juridique</span>
                                        {user?.userType === USER_ROLES.ADMIN &&
                                            <select defaultValue={selectedUser?.legalOfficer?.name}
                                                    onChange={handleUserLegalOfficerChange}
                                                    name="" id="">
                                                {getSuperAdmin()}
                                            </select>
                                        }
                                    </InfoItem>
                                </GridContainer>
                            }
                            {user?.userType !== USER_ROLES.AGENCY && currentTab === UserDetailsType.AUTHORIZATIONS &&
                                <RightsContainer>
                                    <SectionContainer>
                                        <List>
                                            <ListTitle>
                                                Questionnaires autorisés
                                            </ListTitle>
                                            {contentfulSurveys.map((survey, index) => {
                                                return (
                                                    <React.Fragment key={`${survey.name}-${index}`}>
                                                        <label htmlFor={`surveyID-${survey.id}`}>
                                                            <input
                                                                checked={isSurveyChecked(survey)}
                                                                type="checkbox"
                                                                name={`survey-${survey.id}`}
                                                                id={`surveyID-${survey.id}`}
                                                                onChange={() => handleToggleSurvey(survey)}
                                                            />
                                                            {survey.name}
                                                        </label>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </List>
                                        <List>
                                            <ListTitle>Documents authorisés</ListTitle>
                                            {contentfulDocuments.map((document, index) => {
                                                return (
                                                    <React.Fragment key={`${document.name}-${index}`}>
                                                        <label htmlFor={`surveyID-${document.id}`}>
                                                            <input
                                                                checked={isDocumentChecked(document)}
                                                                type="checkbox"
                                                                name={`survey-${document.id}`}
                                                                id={`surveyID-${document.id}`}
                                                                onChange={() => handleToggleDocument(document)}
                                                            />
                                                            {document.name}
                                                        </label>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </List>
                                        {contentfulRegistries.length > 0 &&
                                            <List>
                                                <ListTitle>Registres autorisés</ListTitle>
                                                {contentfulRegistries.map((registry, index) => {
                                                    return (
                                                        <React.Fragment key={`${registry.name}-${index}`}>
                                                            <label htmlFor={`surveyID-${registry.id}`}>
                                                                <input
                                                                    checked={isRegistryChecked(registry)}
                                                                    type="checkbox"
                                                                    name={`survey-${registry.id}`}
                                                                    id={`surveyID-${registry.id}`}
                                                                    onChange={() => handleToggleRegistry(registry)}
                                                                />
                                                                {registry.name}
                                                            </label>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </List>
                                        }
                                    </SectionContainer>
                                </RightsContainer>
                            }
                            {currentTab === UserDetailsType.VALIDATIONS &&
                                <SurveysContainer>
                                    <EntityContainer>
                                        <Split>
                                            <List>
                                                <ListTitle>A valider</ListTitle>
                                                {currentResponses?.filter(entity => entity.status === ENTITY_STATUS.SUBMITTED).map((entity, index) => {
                                                    return (
                                                        <Entity key={index}>
                                                            <EntityName>{entity.name}</EntityName>
                                                            <EntityActionsContainer>
                                                                <EntityActions
                                                                    onClick={() => handleEntityValidation(entity.name)}
                                                                    validation={true}>Valider</EntityActions>
                                                                <EntityActions
                                                                    onClick={() => handleEntityRejection(entity.name)}>Rejeter</EntityActions>
                                                            </EntityActionsContainer>
                                                        </Entity>
                                                    )
                                                })}
                                            </List>
                                        </Split>
                                        <Split>
                                            <List>
                                                <ListTitle>Validées</ListTitle>
                                                {currentResponses?.filter(entity => entity.status === ENTITY_STATUS.VALIDATED).map((entity, index) => {
                                                    return (
                                                        <Entity key={index}>
                                                            <EntityName>{entity.name}</EntityName>
                                                            <EntityActionsContainer>
                                                                <EntityActions
                                                                    onClick={() => handleEntityRejection(entity.name)}>Rejeter</EntityActions>
                                                            </EntityActionsContainer>
                                                        </Entity>
                                                    )
                                                })}
                                            </List>
                                        </Split>
                                    </EntityContainer>
                                </SurveysContainer>
                            }
                            {currentTab === UserDetailsType.STEPS &&
                                <StepsContainer>
                                    {currentResponses?.map((entity, index) => {
                                        return (
                                            <List key={index}>
                                                <ListTitle>{entity.name}</ListTitle>
                                                {ENTITY_STEPS.map((step, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <label htmlFor={`${entity.name}-step-${index}`}>
                                                                <input defaultChecked={entity.currentStep === index}
                                                                       onChange={handleEntityCurrentStepChange}
                                                                       type="radio"
                                                                       id={`${entity.name}-step-${index}`}
                                                                       name={entity.name}
                                                                       value={index}
                                                                />
                                                                {step.admin}
                                                            </label>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </List>
                                        );
                                    })}
                                </StepsContainer>
                            }
                            {currentTab === UserDetailsType.STATS &&
                                <GridContainer>
                                    {currentResponses?.map((entity, index) => {
                                        return (
                                            <List key={index}>
                                                <ListTitle>Compteurs pour {entity.name}</ListTitle>
                                                <Counters>
                                                    <label htmlFor="assistance_requests">
                                                        <div>Demandes d'assistance</div>
                                                        <input
                                                            defaultValue={entity.counters.find(counters => counters.id === 'assistance_requests')?.amount}
                                                            onChange={(e) => updateCurrentEntityCounterById('assistance_requests', e.target.value, entity)}
                                                            name={'assistance_requests'} type="text"/>
                                                    </label>
                                                    <label htmlFor="user_rights">
                                                        <div>Droits des personnes</div>
                                                        <input
                                                            defaultValue={entity.counters.find(counters => counters.id === 'user_rights')?.amount}
                                                            onChange={(e) => updateCurrentEntityCounterById('user_rights', e.target.value, entity)}
                                                            name={'user_rights'} type="text"/>
                                                    </label>
                                                    <label htmlFor="data_violation">
                                                        <div>Violations de données</div>
                                                        <input
                                                            defaultValue={entity.counters.find(counters => counters.id === 'data_violation')?.amount}
                                                            onChange={(e) => updateCurrentEntityCounterById('data_violation', e.target.value, entity)}
                                                            name={'data_violation'} type="text"/>
                                                    </label>
                                                </Counters>
                                            </List>
                                        );
                                    })}
                                </GridContainer>
                            }
                            {currentTab === UserDetailsType.GENERATIONS &&
                                <DashboardGeneration></DashboardGeneration>
                            }
                        </Content>
                    }
                    {currentTab === UserDetailsType.INFOS &&
                        <ActionContainer>
                            <Action onClick={saveSelectedUserInfos}>Mettre à jour les infos</Action>
                        </ActionContainer>
                    }
                    {user?.userType !== USER_ROLES.AGENCY && currentTab === UserDetailsType.AUTHORIZATIONS &&
                        <ActionContainer>
                            <Action onClick={saveSelectedUserRights}>Mettre à jour les autorisations</Action>
                        </ActionContainer>
                    }
                    {currentTab === UserDetailsType.STEPS &&
                        <ActionContainer>
                            <Action onClick={saveCurrentSteps}>Mettre à jour les étapes</Action>
                        </ActionContainer>
                    }
                    {currentTab === UserDetailsType.STATS &&
                        <ActionContainer>
                            <Action onClick={handleSaveStats}>Mettre à jour les compteurs</Action>
                        </ActionContainer>
                    }
                    {currentTab === UserDetailsType.VALIDATIONS &&
                        <ActionContainer>

                        </ActionContainer>
                    }
                </UserDetailsContainer>
            }
        </>
    )
}

const EntityContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 20px;
`;

const Split = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
`;

const Entity = styled.div`
  height: 40px;
  width: calc(100% - 40px);
  //background-color: #82d0a5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border: 1px solid #E9EDF7
`;

const EntityName = styled.div``;

const EntityActionsContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;

const EntityActions = styled.div<{ validation: true }>`
  cursor: pointer;
  color: ${props => props.validation ? '#82d0a5' : '#ff5c5c'};
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const SurveysContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const TabContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2px;
`;

const Tab = styled.div<{ current: boolean }>`
  height: 100%;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #187ead;
    color: #fff;
  }

  ${props => props.current && `
         background-color: #187ead;
         color: #fff;
  `}
`;

const ActionContainer = styled.div`
  display: flex;
  column-gap: 15px;
  justify-content: flex-end;
  padding: 0 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  align-items: center;
  min-height: 70px;
`;

const Action = styled.div`
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 10px;
  background-color: #187EAD;
  color: #fff;

  &:hover {
    color: #187EAD;
    border-color: #187EAD;
    background-color: transparent;
  }
`;

const Counters = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const ListTitle = styled.div`
  font-size: 1.3em;
  font-weight: 500;
  color: #187EAD;
  border-bottom: 1px solid #E9EDF7;
  margin-bottom: 10px;
  width: max-content;
`;

const Since = styled.div`
  font-size: 1em;
  color: #A3AED0;
`;

const RightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;

const FlexContainer = styled.div`
  display: grid;
  row-gap: 20px;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr;
  width: fit-content;

`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  row-gap: 40px;
  //height: 100%;
  width: 100%;
`;

const StepsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  row-gap: 40px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  flex: 1;

  > label {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;

    > input {
      margin: 0;
    }
  }
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: 10px;

  span {
    font-size: 1em;
    color: #000;
    font-weight: 500;
  }
`;

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;

  label {
    display: flex;
    align-items: center;
  }

  label div {
    width: 140px;
  }

  label input {
    margin-right: 10px;
    margin-left: 10px;
  }
`;

const Content = styled.div`
  padding: 20px;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const UserDetailsContainer = styled.div`
  grid-column: span 5;
  grid-row: span 8;
  border-radius: 15px;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const UserDetailsTop = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  width: calc(100% - 40px);
  color: black;
  column-gap: 20px;
  justify-content: space-between;

  span {
    font-size: 1.5em;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #E9EDF7;
`;
