import * as React from 'react';
import styled from 'styled-components';
import {InputComponent} from "./InputComponent";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCurrentEntity,
    selectCurrentStep,
    selectCurrentSurvey, selectCurrentVersion, selectDarkMode,
    selectDisplayDebug,
    selectEntities, selectEntitiesSetup,
    selectLoading, setCurrentEntity, setDisplayDebug,
    setEntities, setEntitiesSetup, setLang, setLoading, setDirty, selectDirty
} from "../../reducers/app.reducer";
import {selectActionRights, selectImpersonated, selectUser, updateResponses} from "../../reducers/user.reducer";
import {selectAllContentfulData} from "../../reducers/contentful.reducer";
import _ from "lodash";
import {useForm} from "react-hook-form";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {BiSave} from "react-icons/all";
import {ENTITY_STATUS} from "../../utils/constants";

export interface FormProps {

}

export const StepQuestions = ({}: FormProps) => {

    const dispatch = useDispatch();
    const currentSurvey = useSelector(selectCurrentSurvey);
    const currentStep = useSelector(selectCurrentStep);
    const displayDebug = useSelector(selectDisplayDebug);
    const entities = useSelector(selectEntities);
    const currentEntity = useSelector(selectCurrentEntity);
    const loading = useSelector(selectLoading);
    const user = useSelector(selectUser);
    const contentfulData = useSelector(selectAllContentfulData);
    const actionRights = useSelector(selectActionRights);
    const entitiesSetup = useSelector(selectEntitiesSetup);
    const currentVersion = useSelector(selectCurrentVersion);
    const darkMode = useSelector(selectDarkMode);
    const impersonated = useSelector(selectImpersonated);
    const surveyIsDirty = useSelector(selectDirty);
    const {register} = useForm();

    const questionsSliderElt = useRef(null);

    const isFormReady = () => {
        return !loading &&
            !!entities &&
            !!user &&
            !!contentfulData.surveys &&
            !!actionRights &&
            !!currentSurvey &&
            entitiesSetup && !!currentVersion;
    }

    const getCurrentEntityResponses = () => {
        if (!entities) {
            return;
        }
        return entities?.find(entity => entity.name === currentEntity.name)?.responses
    };

    const deleteResponseFromVersion = (responseName) => {
        if (getEntityResponseByName(responseName) === null || getEntityResponseByName(responseName) === undefined) {
            return;
        }
        let newEntities = _.cloneDeep(entities);
        newEntities.map(entity => {
            if (entity.name === currentEntity.name) {
                entity.responses[currentSurvey.id] = entity.responses[currentSurvey.id].map(version => {
                    if (version.name === currentVersion) {
                        delete version.responses[responseName];
                    }
                    return version;
                });
                dispatch(setCurrentEntity(entity));
            }
            return entity;
        });
        if (!_.isEqual(newEntities, entities)) {
            dispatch(setEntities(newEntities));
        }
    };

    const isQuestionConditionOK = (question) => {
        if (!question?.conditions?.length || !(entities.length > 0) || !isFormReady()) {
            return true;
        }
        let conditions = question.conditions.every(condition => {
            let version = getCurrentEntityResponses()[currentSurvey.id].find(version => version.name === currentVersion)?.responses;
            if (!version) {
                deleteResponseFromVersion(question.name);
                return false;
            }
            let currentResponse = version[condition.key];
            if (currentResponse === undefined || currentResponse === null) {
                deleteResponseFromVersion(question.name);
                return false;
            }

            if (Array.isArray(currentResponse)) {
                if (currentResponse.some(response => response === condition.value)) {
                    return true;
                } else {
                    deleteResponseFromVersion(question.name);
                    return false;
                }
            } else {
                if (currentResponse === condition.value) {
                    return true;
                } else {
                    deleteResponseFromVersion(question.name);
                    return false;
                }
            }
        });
        return conditions;
    };

    const getAvailableSurveys = () => {
        return contentfulData.surveys
            .filter(survey => actionRights.surveys.some(surveyRights => survey.id === surveyRights.value))
            .map(survey => ({
                value: survey.id,
                label: survey.name,
                index: survey.index,
                conditions: survey.conditions
            }));
    };

    const GetAuthorizedSurveys = () => contentfulData.surveys.filter(survey => {
        let rights = impersonated ? impersonated.actionRights : user.actionRights;
        return rights.surveys?.some(surveyRights => survey.id === surveyRights.value)
    });

    const updateEntities = (target) => {
        let entitiesCopy = _.cloneDeep(entities);
        let targetEntity = entitiesCopy.find(entity => entity.name === currentEntity.name);
        let currentResponses = targetEntity.responses;
        let newResponses = {...currentResponses};

        for (let survey in currentResponses) {
            if (survey === currentSurvey.id) {
                newResponses[survey] = currentResponses[survey].map(version => {
                    if (version.name === currentVersion) {
                        let updatedVersion = {
                            ...version,
                            responses: {
                                ...version.responses,
                                [target.name]: target.value
                            }
                        }

                        if (!target.value) {
                            delete updatedVersion.responses[target.name];
                        }

                        return updatedVersion;
                    }
                    return version;
                });
            }
        }

        targetEntity.responses = newResponses;
        dispatch(setEntities(entitiesCopy));
        dispatch(setCurrentEntity(targetEntity));
    };

    const initEntities = (data) => {
        dispatch(setLoading(true));
        let userAvailableSurveys = getAvailableSurveys();
        if (!userAvailableSurveys.length) {
            return;
        }
        let newEntities = data.inited ? data : [];
        if (!data.inited) {
            let newResponses;
            let entitiesCopy = _.cloneDeep(data);
            if (!entitiesCopy?.length) {
                entitiesCopy.push({
                    name: 'Entreprise_1',
                    responses: {},
                });
            }

            let authorizedSurveys = GetAuthorizedSurveys();
            entitiesCopy.forEach(entity => {
                let entityResponses = entity.responses;
                let entityKeys = Object.keys(entityResponses);

                newResponses = {...entityResponses};
                //check if all authorized surveys are in the list
                authorizedSurveys.forEach(survey => {
                    if (!entityKeys.some(surveyId => surveyId === survey.id)) {  // if no response for this survey
                        newResponses = {
                            ...newResponses,
                            [survey.id]: {
                                [survey.versioning.enabled ? `${survey.versioning.name}_1` : 'version_1']: {}
                            }
                        };
                    }
                });
                if (entity?.inited) {
                    newEntities.push({
                        ...entity,
                        inited: true,
                        responses: {...newResponses, ...entity.responses}
                    });
                    return;
                }
                newEntities.push({
                    ...entity,
                    inited: true,
                    responses: newResponses
                });
            });
        }

        dispatch(setEntities(newEntities));
        dispatch(setEntitiesSetup(true));
        setTimeout(() => dispatch(setLoading(false)), 300);
    }

    const handleSetValue = async (event) => {
        await updateEntities(event.target);
    };

    const getEntityResponseByName = (questionName) => {
        let version = getCurrentEntityResponses()[currentSurvey.id].find(version => version.name === currentVersion)?.responses;
        if (!version) {
            return;
        }
        return version[questionName];
    }

    const saveResponses = async () => {
        dispatch(setLoading(true));
        let entitiesCopy = _.cloneDeep(entities);
        let currentEntityCopy = entitiesCopy.find(entityItem => entityItem.name === currentEntity.name);
        currentEntityCopy.status = ENTITY_STATUS.EDITED;
        await dispatch(updateResponses({userId: user.id, values: entitiesCopy}));
        await dispatch(setCurrentEntity(entities.find(entity => entity.name === currentEntity.name)));
        dispatch(setLoading(false));
    };

    const isDirty = () => {
        return !_.isEqual(user.responses.find((entity) => entity.name === currentEntity.name).responses, getCurrentEntityResponses())
    };

    useEffect(() => {
        if (impersonated) {
            initEntities(impersonated.responses);
        } else {
            initEntities(entities);
        }
    }, [impersonated, user, contentfulData, currentVersion]);

    useEffect(() => {
        questionsSliderElt.current?.scrollTo({top: 0});
    }, [currentStep])

    useEffect(  () => {
        let savedResponses = user?.responses.find((entity) => entity.name === currentEntity.name)?.responses
        if(savedResponses){
            if(surveyIsDirty !== isDirty()) {
                dispatch(setDirty(isDirty()));
            }
        }
    }, [isDirty])

    useEffect( () => {
        return () => {
            dispatch(setDirty(false));
        }
    }, [])

    return (
        <StepQuestionsContainer>
            <QuestionsContainerTop>
                <Title>{currentSurvey.name}</Title>
                <SaveBtn onClick={saveResponses}><BiSave/> Sauvegarder mes réponses</SaveBtn>
            </QuestionsContainerTop>
            {isFormReady() &&
                <QuestionsContainer ref={questionsSliderElt}>
                    <FormWrapper>
                        {currentSurvey.steps[currentStep - 1].form.map((question, index) => (
                            <React.Fragment key={index}>
                                {displayDebug && isQuestionConditionOK(question) &&
                                    <DebugContainer
                                        dark={darkMode}>{question.required && <span
                                        style={{color: 'red'}}>REQUIRED</span>} ( <span>{question.name + ' : '}</span>{getEntityResponseByName(question.name)} )</DebugContainer>}
                                {isQuestionConditionOK(question) &&
                                    <InputComponent
                                        index={index}
                                        register={register}
                                        key={question.name + index}
                                        label={question.label}
                                        name={question.name}
                                        type={question.type}
                                        longAnswers={question.long}
                                        placeholder={question.placeholder}
                                        options={question?.options}
                                        value={getEntityResponseByName(question.name)}
                                        onChange={handleSetValue}
                                        tooltip={question.tooltipContent}
                                        darkMode={darkMode}
                                        display={"true"}
                                        last={index === currentSurvey.steps[currentStep - 1].form.length - 1}
                                        required={question.required}
                                    />
                                }
                            </React.Fragment>
                        ))}
                        <Spacer/>
                    </FormWrapper>
                </QuestionsContainer>
            }
        </StepQuestionsContainer>
    )
}

const SaveBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 50px;
  position: relative;
  transition: all 0.3s;
  color: white;
  opacity: 0.7;
  padding: 0 20px;
  border-radius: 15px;
  background-color: #187EAD;
  margin-right: 20px;
  column-gap: 10px;

  &:hover {
    opacity: 1;
  }

  svg {
    opacity: 1;
    height: 20px;
    width: 20px;
  }
`;

const QuestionsContainerTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Spacer = styled.div`
  min-height: 30px;
`;

const Title = styled.div`
  font-size: 1.3em;
  font-weight: 500;
  color: #187EAD;
  padding: 20px;
`;

const StepQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-row: span 8;
  grid-column: span 6;
  background-color: white;
  padding-bottom: 20px;
`;

const QuestionsContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
  margin-right: 10px;
  flex: 1;
`;

const FormWrapper = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  column-gap: 50px;
  padding-top: 30px;
`;

const DebugContainer = styled.div`
  margin-bottom: -40px;
  display: block;
  opacity: 0.7;
  color: ${({dark}) => dark ? '#7bed9f' : '#0072ff'};

  span {
    font-family: "Baloo 2 SemiBold";
  }
`;
