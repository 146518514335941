import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {parseParts, parseSteps} from '../utils/utils';
import * as contentful from "contentful";
import {setLoading} from "./app.reducer";

const getClient = async () => {
    const targetCTF = {
        environment: process.env.REACT_APP_CONTENTFUL_TARGET_ENVIRONMENT,
        preview: process.env.REACT_APP_CONTENTFUL_PREVIEW === "true",
    };
    let params = {
        space: '5h3y0cofo0sv',
        environment: targetCTF.environment,
        accessToken: "pE3mUTGDi0EtUXQSuh0cPIUhohBSxfVbaJ0tQfM_3C8",
    }
    if (targetCTF.preview) {
        params = {
            ...params,
            accessToken: "Do8dWdvnag46yLmv_TTQfhYw-2Sh3lO8FBYbW2LvOQY",
            // @ts-ignore
            host: 'preview.contentful.com'
        }
    }
    return contentful.createClient(params);
}

const fetchTargetedEntries = async () => {
    let client = await getClient();
    let response = await client.getEntries({
        content_type: "document",
        include: 10
    });

    return response.items.map((entry: any) => {
        console.log('ENTRY', entry.fields.name);
    });
};

interface ContentfulEntry {
    fields: any,
    sys: any,
}

const fetchDocuments = async (lang) => {
    let client = await getClient();
    let response = await client.getEntries({
        content_type: "document",
        include: 10
    });

    return response.items.map((document: ContentfulEntry) => ({
        lang: document.fields.lang,
        id: document.sys.id,
        name: document.fields.name,
        type: document.sys.contentType.sys.id,
        parts: parseParts(document.fields.parts),
        createdAt: document.sys.createdAt,
        ...(document.fields.conditions && {conditions: document.fields.conditions.conditions})
    }));
}

const fetchSurveys = async (lang) => {
    let client = await getClient();
    let response = await client.getEntries({
        content_type: "survey",
        include: 10,
    });
    let surveys = response.items.map((survey: ContentfulEntry) => {
        return {
            id: survey.sys.id,
            name: survey.fields.name,
            index: survey.fields.index,
            conditions: survey.fields.conditions?.conditions,
            versioning: {
                enabled: survey.fields.versioning || false,
                name: survey.fields.versionEntityType || '',
                label: survey.fields.entityTitleLabel
            },
            steps: parseSteps(survey.fields.steps)
        }
    });

    return surveys;
}

const fetchRegistries = async (lang) => {
    let client = await getClient();
    let response = await client.getEntries({
        content_type: "registry",
        include: 10,
        /*...(lang !== '' && {'fields.lang': lang}),*/
    });

    return response.items.map((registry: ContentfulEntry, index) => {
        return {
            lang: registry.fields.lang,
            id: registry.sys.id,
            conditions: registry.fields.conditions?.conditions,
            name: registry.fields.name,
            sheets: registry.fields.sheets.map(sheet => {
                return {
                    headers: sheet.fields?.headers,
                    name: sheet.fields?.sheetName,
                    rows: sheet.fields?.rows?.map((row, index) => {

                        return {
                            conditions: row.fields?.conditions?.conditions,
                            values: row.fields?.values?.map((value, index) => {

                                return {
                                    values: value.fields?.values?.map(value => ({
                                        text: value.fields?.text,
                                        conditions: value.fields?.conditions?.conditions,
                                    })),
                                    composed: value.sys.contentType.sys.id === "composedRowValue",
                                    text: value.fields?.text,
                                    replace: value.fields?.replace?.replace,
                                    backgroundColor: value.fields?.backgroundColor
                                }
                            })
                        }
                    }),
                    replace: sheet.fields?.replace?.replace
                }
            })
        }
    });
}

export const fetchAllContentfulData = createAsyncThunk(
    'Contentful/FETCH_ALL',
    async (args: { lang?: any, user?: any }, thunkApi) => {
        let dispatch = thunkApi.dispatch;
        let documents = await fetchDocuments(args?.lang);
        let registries = await fetchRegistries(args?.lang);
        let surveys = await fetchSurveys(args?.lang);
        return {documents, registries, surveys};
    }
)

export const contentfulReducer = createSlice({
    name: 'CONTENTFUL',
    initialState: {
        documents: [],
        registries: [],
        surveys: [],
    },
    reducers: {
        resetContentfulReducer: (state) => {
            return {
                documents: [],
                registries: [],
                surveys: [],
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllContentfulData.fulfilled, (state, action) => {
            state.documents = action.payload.documents;
            state.registries = action.payload.registries;
            state.surveys = action.payload.surveys;
            setLoading(false);
        })
    }
});

export const {
    resetContentfulReducer
} = contentfulReducer.actions;

export const selectAllContentfulData = state => state.contentful;

export const selectDocuments = state => state.contentful.documents;

export const selectRegistries = state => state.contentful.registries;

export const selectSurveys = state => state.contentful.surveys;

export const selectDocumentsById = (state, id) => state.contentful.documents.filter(document => document._id === id)

export default contentfulReducer.reducer;
