import * as React from 'react';
import styled from 'styled-components';
import {ConformitySteps} from "../dashboard-conformity-steps/ConformitySteps";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCurrentEntity,
    selectEntities, selectLoading,
    setCurrentEntity,
    setEntities, setEntitiesSetup,
    setLoading
} from "../../reducers/app.reducer";
import {selectImpersonated, selectUser} from "../../reducers/user.reducer";
import {fetchAllContentfulData, selectAllContentfulData} from "../../reducers/contentful.reducer";
import {useEffect} from "react";
import {MySurveys} from "../dashboard-my-surveys/MySurveys";
import {ENTITY_STEPS, USER_ROLES} from "../../utils/constants";
import {getSurveyById} from "../../utils/utils";
import * as _ from "lodash";


export const Dashboard = () => {

    const dispatch = useDispatch();
    //redux
    const user = useSelector(selectUser);
    const impersonated = useSelector(selectImpersonated);
    const currentEntity = useSelector(selectCurrentEntity);
    const entities = useSelector(selectEntities);
    const loading = useSelector(selectLoading);
    const contentfulData = useSelector(selectAllContentfulData);

    const initEntities = (data) => {
        dispatch(setLoading(true));
        let newEntities = [...data] ?? [];
        const currentUser = user ?? impersonated;
        newEntities = _.cloneDeep(newEntities).map((entity) => {
            entity.responses = initNewSurveys(currentUser?.actionRights?.surveys, entity?.responses);
            return entity;
        });

        dispatch(setEntities(newEntities));
        dispatch(setEntitiesSetup(true));
        isSurveysLoaded() && dispatch(setLoading(false));
    }

    const initNewSurveys = (authorizedSurveys, entityResponses) => {
        if (!authorizedSurveys) return {};
        const existingSurveys = entityResponses ? Object.keys(entityResponses) : [];
        authorizedSurveys?.forEach((survey) => {
            if (!existingSurveys.includes(survey.value)) {
                let fullSurvey = getSurveyById(survey.value);
                entityResponses = {
                    ...entityResponses,
                    [survey.value]: [{
                        name: fullSurvey?.versioning?.enabled ? `${fullSurvey?.versioning?.name}_1` : 'version_1',
                    }]
                }
            }
        });

        return entityResponses;
    }

    const isSurveysLoaded = () => contentfulData.surveys.length > 0;

    useEffect(() => {
        if (isSurveysLoaded) {
            if (impersonated) {
                initEntities(impersonated.responses);
            } else {
                initEntities(user.responses);
            }
        }
    }, [impersonated, user, contentfulData]);

    useEffect(() => {
        dispatch(setCurrentEntity(entities[0]));
    }, [entities]);

    useEffect(() => {
        dispatch(fetchAllContentfulData({}));
    }, []);

    return (
        <>
            {!loading && currentEntity &&
                <DashboardContentContainer>
                    {user?.userType !== USER_ROLES.AGENCY &&
                        <ConformitySteps currentStep={parseInt(currentEntity.currentStep) + 1} steps={ENTITY_STEPS}/>}
                    {currentEntity &&
                        <MySurveys/>
                    }
                </DashboardContentContainer>
            }
        </>
    )
};

const DashboardContentContainer = styled.div`
  align-items: start;
  position: relative;
  height: 100%;
  display: flex;
`;


