import * as React from 'react';
import styled from 'styled-components';

export interface DashboardDataModuleLightProps {
    dataArray: Array<{ label: string, amount: string, description: string }>;
}

export const DashboardDataModuleLight = ({dataArray}: DashboardDataModuleLightProps) => {
    return (
        <Container>
            {dataArray?.map((data, index) => (
                <React.Fragment key={index}>
                    <Data>
                        <Title>{data.label}</Title>
                        <Value>{data.amount}</Value>
                    </Data>
                    {index === dataArray.length - 1 ? null : <Separator/>}
                </React.Fragment>
            ))}
        </Container>
    )
}

const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  align-items: center;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 1em;
  color: #dfe6e9;
  text-transform: uppercase;
`;

const Value = styled.div`
  font-size: 1.5em;
  font-weight: 500;
`;

const Separator = styled.div`
  min-width: 1px;
  height: 40px;
  background-color: #dfe6e990;
`;
