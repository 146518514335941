import {saveAs} from "file-saver";
import {
    AlignmentType,
    Document,
    HeadingLevel,
    Packer,
    Paragraph,
    TextRun,
    ExternalHyperlink, Table, TableRow, TableCell, WidthType, convertInchesToTwip, TableLayoutType,
} from "docx";
import store from "../../store/store";
import {ContentTypes, getColorFromProcessorStatus, handleConditions, handleReplace} from "../../utils/utils";
import {selectUser} from "../../reducers/user.reducer";
import {USER_ROLES} from "../../utils/constants";

const MarkdownIt = require('markdown-it')();


const generateDocx = async (formData, docData) => {
    const getChildrenFromMarkdown = (md) => {
        return md.children.map((child, idx) => {
            if (child.type === "text" && child.content !== "") {
                return child.level === 0 ?
                    {type: "text", text: child.content, bold: false}
                    : child.level === 1 ?
                        md.children[idx - 1].tag === "strong" ?
                            {type: "text", text: child.content, bold: true}
                            : {type: "link", text: child.content, bold: false, url: md.children[idx - 1].attrs[0][1]}
                        : {type: "link", text: child.content, bold: true, url: md.children[idx - 3].attrs[0][1]}
            }
        }).filter(Boolean)
    }

    const getDocumentArray = (part) => {
        return [
            new Table({
                    layout:TableLayoutType.FIXED,
                    style: "",
                    rows:
                        [
                            part.headers.length ?
                                new TableRow({
                                    children: part.headers.map((header) => {
                                        return new TableCell({
                                            width: {
                                                size: 9010 / part.headers.length,
                                                type: WidthType.DXA,
                                            },
                                            children: [
                                                new Paragraph({
                                                    children: [
                                                        new TextRun({
                                                            text: header,
                                                            font: "Calibri",
                                                            size: "12pt",
                                                            bold: true,
                                                            color: "#FFFFFF",
                                                        })
                                                    ],
                                                    alignment: AlignmentType.CENTER,
                                                })
                                            ],
                                            shading: {color: "#3D85C6", type: "solid"},
                                            verticalAlign: "center",
                                            margins: {
                                                top: convertInchesToTwip(0.10),
                                                bottom: convertInchesToTwip(0.10),
                                                left: convertInchesToTwip(0.10),
                                                right: convertInchesToTwip(0.10),
                                            },
                                        })
                                    })
                                })
                                : null,
                            ...part.rows.map((row, index) => {
                                if(handleConditions(row, formData)) {
                                    return new TableRow({
                                        children:
                                            row.values.map((rowValue) => {
                                                let parsedMarkdown = MarkdownIt.parse(handleConditions(rowValue, formData) ? handleReplace(rowValue, formData)?.text : 'N/A', {references: {}});
                                                let markdownChildren = getChildrenFromMarkdown(parsedMarkdown[1]);
                                                let argbColor = getColorFromProcessorStatus(rowValue.backgroundColor);
                                                let hexColor = argbColor? '#' + argbColor.slice(2) : "#FFFFFF";

                                                return new TableCell({
                                                    width: {
                                                        size: 9010 / row.values.length,
                                                        type: WidthType.DXA,
                                                    },
                                                    children: [
                                                        new Paragraph({
                                                            children: getFormatedChildren(markdownChildren),
                                                        })
                                                    ],
                                                    margins: {
                                                        top: convertInchesToTwip(0.15),
                                                        bottom: convertInchesToTwip(0.15),
                                                        left: convertInchesToTwip(0.25),
                                                        right: convertInchesToTwip(0.25),
                                                    },
                                                    shading: {color: hexColor, type: "solid"},

                                                })
                                            }),
                                    })
                                }
                            }).filter(Boolean),
                        ]
                }),
            new Paragraph({
                spacing: {
                    after: 200,
                },
            })
            ];
    }


    const getTitle = (part) => {
        return new Paragraph({
            children: [
                new TextRun({
                    text: part.title,
                    font: "Calibri",
                    size: part.level === 1 ? "28pt" : "18pt",
                }),
            ],
            heading: HeadingLevel[`HEADING_${part.level}`],
            spacing: {
                before: 600,
                after: part.level === 1 ? 600 : 200,
            },
            alignment: part.level === 1 ? AlignmentType.CENTER : AlignmentType.JUSTIFIED,
        });
    };

    const getParagraph = (part) => {
        let parsedMarkdown = MarkdownIt.parse(part.content, {references: {}});
        let markdownChildren = getChildrenFromMarkdown(parsedMarkdown[1])
        return new Paragraph({
            children: getFormatedChildren(markdownChildren),
            spacing: {
                before: 350,
                after: 350,
            },
            alignment: AlignmentType.JUSTIFIED
        });
    };

    const getSignature = async () => {
        let user = await selectUser(store.getState());
        return new Paragraph({
            children: [
                new TextRun({
                    text: user?.userType !== USER_ROLES.AGENCY ? "Certifié conforme par Dipeeo ®" : "Powered by Dipeeo ®",
                    font: "Calibri",
                    italics: true,
                    size: "10pt"
                })
            ],
            spacing: {
                before: 350,
                after: 350,
            },
        });
    };

    const getFormatedChildren = (content) => {
        // Content is MarkdownChildren and could be either Text or Link
        return content
            .map((part) => {
                if (part.type === "text") {
                    return [
                        new TextRun({
                            text: part.text,
                            font: "Calibri",
                            size: "12pt",
                            bold: part.bold
                        }),
                    ];
                } else {
                    return [
                        new ExternalHyperlink({
                            children: [
                                new TextRun({
                                    text: part.text,
                                    font: "Calibri",
                                    size: "12pt",
                                    bold: part.bold,
                                    style: "Hyperlink"
                                }),
                            ],
                            link: part.url
                        }),
                    ]
                }
            })
            .flat();
    };

    const getBulletpointList = (part) => {
        return part.listItems
            .map((item) => {
                return handleConditions(item, formData) ? getBulletPoint(item) : {};
            })
            .flat();
    };

    const getBulletPoint = (part) => {
        part = handleReplace(part, formData);
        let parsedMarkdown = MarkdownIt.parse(part.content, {references: {}});
        let markdownChildren = getChildrenFromMarkdown(parsedMarkdown[1])
        if (part.type === ContentTypes.COMPOSED) {
            return new Paragraph({
                children: getComposedParagraphChildren(part.content),
                bullet: {
                    level: 0,
                },
                spacing: {
                    before: 250,
                    after: 250,
                },
                alignment: AlignmentType.JUSTIFIED,
            });
        }
        return new Paragraph({
            children: getFormatedChildren(markdownChildren),
            bullet: {
                level: 0,
            },
            spacing: {
                before: 250,
                after: 250,
            },
            alignment: AlignmentType.JUSTIFIED,
        });
    };

    const getComposedParagraphChildren = (parts) => {
        return parts.content?.map((item) => {
            if (item.replace) {
                item.content = handleReplace(item, formData);
            }
            let parsedMarkdown = MarkdownIt.parse(item.content, {references: {}});
            let markdownChildren = getChildrenFromMarkdown(parsedMarkdown[1])
            return handleConditions(item, formData) ? getFormatedChildren(markdownChildren) : {};
        })
            .flat();
    };

    const getComposedParagraph = (part) => {
        return new Paragraph({
            children: getComposedParagraphChildren(part),
            spacing: {
                before: 350,
                after: 350,
            },
            alignment: AlignmentType.JUSTIFIED,
        });
    };

    const getDocFragment = (part) => {
        switch (part.type) {
            case ContentTypes.ARRAY:
                return getDocumentArray(part);
            case ContentTypes.BULLET:
                return getBulletpointList(part);
            case ContentTypes.TITLE:
                return getTitle(part);
            case ContentTypes.TEXT:
                return getParagraph(part);
            case ContentTypes.COMPOSED:
                return getComposedParagraph(part);
            default:
                break;
        }
    };

    const parseDocuments = async () => {
        let parts = docData.parts.map((docPart) => {
            docPart = handleReplace(docPart, formData);
            return handleConditions(docPart, formData) ? getDocFragment(docPart) : {};
        });

        parts.unshift(getTitle({title: docData.name, level: 1}));
        const signature = await getSignature();
        parts.push(signature);
        return parts.flat();
    };

    const doc = new Document({
        creator: "Dipeeo",
        sections: [
            {
                properties: {},
                children: await parseDocuments(),
            },
        ],
    });

    return await Packer.toBlob(doc);
};

const getDocumentName = (docData) => docData.name.replaceAll('"', ' ').replaceAll(' ', '_').replaceAll('/', '-');

export const getOneDoc = async (formData, doc) => {
    let docBlob = await generateDocx(formData, doc);
    saveAs(docBlob, getDocumentName(doc) + '.docx');
}

export const getAllDocs = async (formData, docs) => await docs
    .filter(doc => handleConditions(doc, formData))
    .map(doc => ({
        blob: generateDocx(formData, doc),
        name: getDocumentName(doc)
    }))

