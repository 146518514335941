import * as React from 'react';
import styled from 'styled-components';
import {FunctionComponent, useState} from "react";
import {faFileWord, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export interface ModalProps {
    show?: boolean;
    title: string;
    onClose: () => void;
    children?: React.ReactNode;
    actions?: Array<{ label: string, onClick?: () => void, icon?: any, selected?: boolean }>;
    size?: { height?: string, width?: string }
}

export const Modal = ({show, onClose, title, actions, children, size}: ModalProps) => {

    const handleOnClose = () => {
        onClose && onClose();
        return;
    };

    return (
        <>
            {show &&
                <>
                    <ModalContainer height={size?.height} width={size?.width}>
                        <ModalHeader>
                            <h4>{title}</h4>
                            {actions &&
                                <ActionsContainer>
                                    {actions.map(
                                        (action, index) => (
                                            <ExportItem className={action.selected ? 'selected' : ''} key={index}
                                                        onClick={action.onClick}>
                                                <FontAwesomeIcon icon={action.icon}/>
                                                <span>{action.label}</span>
                                            </ExportItem>
                                        )
                                    )}
                                </ActionsContainer>
                            }
                            <CloseIcon icon={faTimes} onClick={handleOnClose}/>
                        </ModalHeader>
                        <ChildrenContainer>
                            {children}
                        </ChildrenContainer>
                    </ModalContainer>
                    <Overlay onClick={handleOnClose}/>
                </>
            }
        </>
    )
};


const ModalContainer = styled.div<{ height: number; width: number }>`
  max-height: 70%;
  height: ${({height}) => height ?? 'auto'};
  max-width: 60%;
  width: ${({width}) => width ?? 'auto'};
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 999;
  max-height: 70%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
`;

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

const ExportItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s;
  height: 100%;
  position: relative;

  &:hover, &.selected {
    opacity: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: 5px;
      height: 2px;
      background-color: #f1f2f6;
      width: 100%;
    }
  }

  span {
    font-family: "Baloo 2";
  }
`;

const ModalHeader = styled.div`
  height: 60px;
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #28607a;
  padding: 0 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  h4 {
    flex: 1;
  }

  > * {
    color: #f1f2f6;
  }

  div:not(.close) {
    cursor: pointer
  }

  div {
    font-family: "Baloo 2";
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 25px;
  margin-right: 40px;
  height: 100%;
`;

const ChildrenContainer = styled.div`
  height: calc(100% - 60px);
  //padding: 20px;
  //overflow-y: auto;

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c7c7c7;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 2.1px -1px 12px 0px #f0f0f0;
  }
`;

const CloseIcon = styled(FontAwesomeIcon)`
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: fixed;
  background-color: black;
  opacity: 0.3;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  cursor: pointer;
`
