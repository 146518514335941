import React from 'react';
import styled, {keyframes} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'


interface ButtonInterface {
    label: string;
    loading?: number;
    onClick: any;
}

export const Button = ({label, loading, onClick}: ButtonInterface) => {
    return (
        <ButtonContainer loading={loading} onClick={onClick}>
            {loading && <CustomIcon icon={faSpinner}/>}
            {!loading && <span>{label}</span>}
            {loading && <span>En cours</span>}
        </ButtonContainer>
    )
}

const ButtonContainer = styled.button<{ loading: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
  transition: all 0.3s;
  ${props => props.loading ? 'background-color: #187ead' : ''}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const CustomIcon = styled(FontAwesomeIcon)`
  animation: ${rotate} 1s infinite;
`;




