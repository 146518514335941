import * as React from 'react';
import styled from 'styled-components';
import {useDispatch} from "react-redux";

export interface FormStepsProps {
    steps?: {
        label: string,
        onClick: () => void,
        condition: boolean,
        current: boolean
    }[];
};

export const FormSteps = ({steps}: FormStepsProps) => {
    return (
        <FormStepsContainer>
            <Title>Etapes de questionnaire</Title>
            <StepList>
                {steps.map((step, index) => (
                    <Step
                        key={index}
                        current={step.current}
                        onClick={step.onClick}>
                        {step.label}
                    </Step>
                ))}
            </StepList>
        </FormStepsContainer>
    )
};

const Title = styled.div`
  font-size: 1.3em;
  font-weight: 500;
  color: #187EAD;
  padding: 20px;
`;

const FormStepsContainer = styled.div`
  grid-column: span 2;
  grid-row: span 8;
  background-color: white;
`;

const StepList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const Step = styled.div<{ current: boolean }>`
  height: 40px;
  width: calc(100% - 40px);
  background-color: ${({current}) => current ? '#187EAD' : 'transparent'};
  display: flex;
  align-items: center;
  color: ${({current}) => current ? 'white' : 'black'};
  cursor: pointer;
  transition: all 0.2s;
  padding: 0 20px;
`;
