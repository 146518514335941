import {NewUserInfos} from "../components/dashboard-create-user/DashboardCreateUser";
import {customFetch} from "./utils";

export const getBaseUrl = () => process.env.REACT_APP_HEROKU_TARGET;

export const createUser = async (userInfos: NewUserInfos) => {
    return await customFetch(`${getBaseUrl()}/auth/createUser`, 'POST', JSON.stringify(userInfos), false);
}

export const deleteUserById = async (userId) => {
    await customFetch(`${getBaseUrl()}/form/delete`, 'POST', JSON.stringify({userId}), true);
};

export const getDbUsers = async () => {
    return await customFetch(`${getBaseUrl()}/form/all`, 'GET', null, true);
};

export const getUserById = async (userId) => {
    let response = await customFetch(`${getBaseUrl()}/form/`, 'POST', JSON.stringify({userId}), true);
    return response.user;
}

export const login = async (email: string, password: string) => {
    return await customFetch(`${getBaseUrl()}/auth/login`, 'POST', JSON.stringify({email, password}), true);
}

export const updateDbUser = async (userId, data) => {
    let response = await customFetch(`${getBaseUrl()}/form/updateUser`, 'POST', JSON.stringify({userId, data}), true);
    return response.updatedUser;
}

export const updateRemaining = async (userId, action) => {
    let response = await customFetch(`${getBaseUrl()}/form/updateRemaining`, 'POST', JSON.stringify({userId, ...action}), true);
    return {error: response.error, user: response.user}
};

export const updateUserRights = async (userId, actionRights) => {
    let response = await customFetch(`${getBaseUrl()}/form/updateRights`, 'POST', JSON.stringify({
        userId,
        actionRights
    }), true);
    return {user: response.updatedUser}
}

export const updateUserResponses = async (userId, responses) => {
    return await customFetch(`${getBaseUrl()}/form/updateResponses`, 'POST', JSON.stringify({userId, responses}), true);
}

