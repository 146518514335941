import * as contentful from "contentful";
import store from "src/store/store";
import {setErrors} from "../reducers/app.reducer";
import {PROCESSOR_MONITORING_STATUS} from "./constants";
import {selectAllContentfulData, selectSurveys} from "../reducers/contentful.reducer";
import {selectImpersonated, selectUser} from "../reducers/user.reducer";

var client = contentful.createClient({
    space: "5h3y0cofo0sv",
    accessToken: "xnrKvKF_08E69gufbsa2JVqvNKJuJQQhwSQHkgeA8cs",
});

export const ContentTypes = {
    ARRAY: "documentArray",
    BULLET: "bulletPointList",
    TEXT: "documentPart",
    TITLE: "sectionTitle",
    COMPOSED: "composedPart",
};

export const customFetch = async (url, method, body, xsrf) => {
    let customHeaders = new Headers();
    customHeaders.set("Content-Type", "application/json");
    customHeaders.set("accept", "application/json");

    let customOptions: any = {
        method: method,
        headers: customHeaders,
        body: body ?? undefined
    }

    if (xsrf) {
        customHeaders.set("x-xsrf-token", window.sessionStorage.getItem('user_token'));
        customOptions = {
            ...customOptions,
            headers: customHeaders,
            mode: 'cors',
            credentials: 'include'
        }
    }

    const customRequest = new Request(url, customOptions);
    const response = await fetch(customRequest);

    if (!response.ok) {
        handleError(response)
    }

    return response.json();
};

const handleError = (res) => {
    store.dispatch(setErrors(true))
}

const parseBulletItems = (listItems) => {
    return listItems.map((item) => ({
        ...item.fields,
        type: item.sys.contentType.sys.id,
        content:
            item.sys.contentType.sys.id === ContentTypes.COMPOSED
                ? parseComposedParts(item.fields.content)
                : item.fields.content,
        conditions: item.fields.conditions?.conditions,
        ...(item.fields.replace && {replace: item.fields.replace.replace})
    }));
};

const parseComposedParts = (parts) => {
    return parts.map((item) => {
        return {
            ...item.fields,
            conditions: item.fields.conditions?.conditions,
        };
    });
};

export const parseParts = (parts) => {
    return parts.map((part) => {
        if (!part.fields) return;

        if (part.sys.contentType.sys.id === ContentTypes.BULLET) {
            return {
                ...part.fields,
                conditions: part.fields.conditions?.conditions,
                replace: part.fields.replace?.replace,
                listItems: parseBulletItems(part.fields.listItems),
                type: part.sys.contentType.sys.id,
            };
        }

        if (part.sys.contentType.sys.id === ContentTypes.TITLE) {
            return {
                ...part.fields,
                conditions: part.fields.conditions?.conditions,
                replace: part.fields.replace?.replace,
                type: part.sys.contentType.sys.id,
            };
        }

        if (part.sys.contentType.sys.id === ContentTypes.COMPOSED) {
            return {
                ...part.fields,
                conditions: part.fields.conditions?.conditions,
                replace: part.fields.replace?.replace,
                content: parseComposedParts(part.fields.content),
                type: part.sys.contentType.sys.id,
            };
        }

        if (part.sys.contentType.sys.id === ContentTypes.ARRAY) {
            return {
                ...part.fields,
                conditions: part.fields.conditions?.conditions,
                replace: part.fields.replace?.replace,
                type: part.sys.contentType.sys.id,
                rows: part.fields.rows?.map((row, index) => {
                    return {
                        conditions: row.fields?.conditions?.conditions,
                        values: row.fields?.values?.map((value, index) => {

                            return {
                                values: value.fields?.values?.map(value => ({
                                    text: value.fields?.text,
                                    conditions: value.fields?.conditions?.conditions,
                                })),
                                composed: value.sys.contentType.sys.id === "composedRowValue",
                                text: value.fields?.text,
                                replace: value.fields?.replace?.replace,
                                backgroundColor: value.fields?.backgroundColor,
                                conditions: value.fields?.conditions?.conditions
                            }
                        })
                    }
                }),
            };
        }


        return {
            ...part.fields,
            conditions: part?.fields?.conditions?.conditions,
            replace: part?.fields?.replace?.replace,
            type: part?.sys?.contentType?.sys.id,
        };
    });
};

export const parseRichConditions = (conditions) => {
    return conditions.map(condition => {
        return {
            ...condition.fields
        }
    });
};

const parseQuestions = (questions) => questions.map(question => {
    const optionsList = question.fields.optionList;
    const richConditions = question.fields.richConditions;
    return {
        ...question.fields,
        long: question.fields.longResponses,
        conditions: question.fields?.conditions?.conditions,
        required: !!question.fields?.required,
        ...(richConditions && {richConditions: parseRichConditions(richConditions)}),
        ...(optionsList && {options: parseOptions(optionsList)})
    }
});

const parseOptions = (optionsList) => {
    return optionsList.map(option => ({
        ...option.fields,
        name: option.fields.value
    }))
};

export const parseSteps = (steps) => steps.map(step => ({
    name: step.fields.name,
    form: parseQuestions(step.fields.stepQuestions),
    conditions: step.fields?.conditions?.conditions,
}));

//RETRIEVE FROM CONTENTFUL

export const getSurvey = async () => {
    let response = await client.getEntries({
        content_type: "survey",
        include: 10,
    });

    return response.items.map((survey: any) => {
        return {
            id: survey.sys.id,
            name: survey.fields.name,
            index: survey.fields.index,
            steps: parseSteps(survey.fields.steps)
        }
    }).sort((a, b) => a.index - b.index);
};

export const getExcelSheets = async (lang) => {
    let response = await client.getEntries({
        content_type: "registry",
        'fields.lang': lang,
        include: 10,
    });
    return {
        registries: response.items.map((registry: any, index) => {
            return {
                conditions: registry.fields.conditions?.conditions,
                name: registry.fields.name,
                sheets: registry.fields.sheets.map(sheet => {
                    return {
                        headers: sheet.fields?.headers,
                        name: sheet.fields?.sheetName,
                        rows: sheet.fields?.rows?.map((row, index) => {

                            return {
                                conditions: row.fields?.conditions?.conditions,
                                values: row.fields?.values?.map((value, index) => {

                                    return {
                                        values: value.fields?.values?.map(value => ({
                                            text: value.fields?.text,
                                            conditions: value.fields?.conditions?.conditions,
                                        })),
                                        composed: value.sys.contentType.sys.id === "composedRowValue",
                                        text: value.fields?.text,
                                        replace: value.fields?.replace?.replace
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }
};

//UTILS

export const handleConditions = (entry, formData) => {
    if (!entry.conditions) {
        return true;
    }

    if (!formData) {
        return false;
    }

    return entry.conditions.every((condition) => {
        let modifiedFormData = formData.responses ?? formData;
        if (Array.isArray(modifiedFormData[condition["key"]])) {
            return modifiedFormData[condition["key"]].some(
                (value) => value === condition["value"]
            );
        }
        return modifiedFormData[condition["key"]] === condition["value"];
    });

};
const replaceInString = (string, replaceObj, formData) => string?.replaceAll(replaceObj["key"], formData[replaceObj["value"]]);

export const handleReplace = (part, formData) => {
    if (!part.replace) {
        return part;
    }

    let copyPart = {
        ...part
    }
    copyPart?.replace?.forEach((replaceObj, index) => {
        let partType = copyPart.content ? "DocPart" : copyPart.text ? "Value" : "Sheet"
        switch (partType) {
            case "DocPart":
                copyPart.content = replaceInString(copyPart.content, replaceObj, formData);
                break;
            case "Value":
                copyPart.text = replaceInString(copyPart.text, replaceObj, formData);
                break;
            case "Sheet":
                copyPart.name = replaceInString(copyPart.name, replaceObj, formData);
                break;
        }
    });
    return copyPart;
};

export const getColorFromProcessorStatus = (processorStatus) => {
    return PROCESSOR_MONITORING_STATUS.find((process) => process.label === processorStatus)?.color ?? undefined
}

export const getCellAddress = (rowIndex, columnIndex) => {
    let columnLetter = "";
    /* Note : Row & Column indexes start at 0,0 instead of A2. Because of headers. Thus we add 1 to column and 2 to row */
    let newColumnIndex = columnIndex + 1;
    let newRowIndex = rowIndex + 2;
    let t = 0;

    while (newColumnIndex > 0) {
        t = (newColumnIndex - 1) % 26;
        columnLetter = String.fromCharCode(65 + t) + columnLetter;
        newColumnIndex = (newColumnIndex - t) / 26 | 0;
    }
    return columnLetter + newRowIndex;
}

export function isTelValid(number) {
    let telRegExp = new RegExp(/^[0-9]{10}$/g);
    return telRegExp.test(number);
};

export function isEmailValid(email) {
    let emailRegExp = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return emailRegExp.test(email);
};

export const getLinksKeyToDocs = (contentfulDocuments) => {
    let linksKeyToDocs = {};
    contentfulDocuments.map(doc => {
        linksKeyToDocs[doc.id] = [];
        doc?.parts?.forEach(part => {
            if (part.conditions) {
                part.conditions.forEach(condition => {
                    let isPresent = linksKeyToDocs[doc.id].some(elt => elt === condition.key);
                    if (!isPresent) {
                        linksKeyToDocs[doc.id].push(condition.key);
                    }
                })
            }
            if (part.replace) {
                part.replace.forEach(replace => {
                    let isPresent = linksKeyToDocs[doc.id].some(elt => elt === replace.value);
                    if (!isPresent) {
                        linksKeyToDocs[doc.id].push(replace.value);
                    }
                })
            }
        });
    });
    for (let keys in linksKeyToDocs) {
        if (!(linksKeyToDocs[keys].length > 0)) {
            delete linksKeyToDocs[keys];
        }
    }
    return linksKeyToDocs;
}

export const getLinksKeyToRegistry = (contentfulRegistries) => {
    let linksKeyToRegistry = {};
    contentfulRegistries.map(registry => {
        linksKeyToRegistry[registry.id] = [];
        registry?.sheets?.forEach(sheet => {
            sheet.rows.map(row => {
                if (row.conditions) {
                    row.conditions.forEach(condition => {
                        let isPresent = linksKeyToRegistry[registry.id].some(elt => elt === condition.key);
                        if (!isPresent) {
                            linksKeyToRegistry[registry.id].push(condition.key);
                        }
                    })
                }
                if (row.replace) {
                    row.replace.forEach(replace => {
                        let isPresent = linksKeyToRegistry[registry.id].some(elt => elt === replace.value);
                        if (!isPresent) {
                            linksKeyToRegistry[registry.id].push(replace.value);
                        }
                    })
                }
                row?.values?.map(value => {
                    if (value.conditions) {
                        value.conditions.forEach(condition => {
                            let isPresent = linksKeyToRegistry[registry.id].some(elt => elt === condition.key);
                            if (!isPresent) {
                                linksKeyToRegistry[registry.id].push(condition.key);
                            }
                        })
                    }
                    if (value.replace) {
                        value.replace.forEach(replace => {
                            let isPresent = linksKeyToRegistry[registry.id].some(elt => elt === replace.value);
                            if (!isPresent) {
                                linksKeyToRegistry[registry.id].push(replace.value);
                            }
                        })
                    }
                })
            })
        });
    });

    for (let keys in linksKeyToRegistry) {
        if (!(linksKeyToRegistry[keys].length > 0)) {
            delete linksKeyToRegistry[keys];
        }
    }

    return linksKeyToRegistry;
}

export const getRelationsBetweenSurveys = (contentfulSurveys, contentfulDocuments, actionRightsSurveys) => {
    if (!(contentfulSurveys?.length > 0) || !(contentfulDocuments?.length > 0)) {
        return;
    }
    let linksKeyToDocs = getLinksKeyToDocs(contentfulDocuments);

    let docToSurveys = {};
    let keysToSurveys = {};

    Object.keys(linksKeyToDocs).forEach(docId => {
        docToSurveys[docId] = [];
        contentfulSurveys.filter(survey => actionRightsSurveys?.some(userSurvey => userSurvey.value === survey.id)).forEach(survey => {
            keysToSurveys[survey.id] = [];
            docToSurveys[survey.id] = [];
            survey.steps.forEach(step => {
                step.form.forEach(question => {
                    keysToSurveys[survey.id].push(question.name);
                    if (linksKeyToDocs[docId].some(key => key === question.name)) {
                        if (!docToSurveys[docId].some(doc => doc === survey.id)) {
                            docToSurveys[docId].push(survey.id);
                        }
                    }
                })
            })
        })
    });
    for (let keys in docToSurveys) {
        if (!(docToSurveys[keys].length > 0)) {
            delete docToSurveys[keys];
        }
    }
    return {docToSurveys, keysToSurveys};
};

export const getRelationsBetweenSurveysAndRegistries = (contentfulSurveys, contentfulRegistries, actionRightsSurveys) => {

    if (!(contentfulSurveys?.length > 0) || !(contentfulRegistries?.length > 0)) {
        return;
    }

    let linksKeyToDocs = getLinksKeyToRegistry(contentfulRegistries);
    let registryToSurveys = {};
    let keysToSurveys = {};

    Object.keys(linksKeyToDocs).forEach(registryId => {

        registryToSurveys[registryId] = [];
        contentfulSurveys.filter(survey => actionRightsSurveys?.some(userSurvey => userSurvey.value === survey.id)).forEach(survey => {
            keysToSurveys[survey.id] = [];
            registryToSurveys[survey.id] = [];
            survey.steps.forEach(step => {
                step.form.forEach(question => {
                    keysToSurveys[survey.id].push(question.name);
                    if (linksKeyToDocs[registryId].some(key => key === question.name)) {
                        if (!registryToSurveys[registryId].some(doc => doc === survey.id)) {
                            registryToSurveys[registryId].push(survey.id);
                        }
                    }
                })
            })
        })
    });

    for (let keys in registryToSurveys) {
        if (!(registryToSurveys[keys].length > 0)) {
            delete registryToSurveys[keys];
        }
    }
    return {registryToSurveys, keysToSurveys};
};

export const getVersioningLabels = (surveys) => {
    let mappedLabels = {};
    surveys.forEach((survey) => {
        mappedLabels[survey.id] = survey.versioning.name !== "" ? survey.versioning.name : "version"
    })

    return mappedLabels;
};

export const getQuestionsType = (surveys) => {
    let mappedTypes = {};
    surveys.forEach(survey => {
        survey.steps.forEach(step => {
            step.form.forEach(question => {
                mappedTypes[question.name] = question.type;
            })
        })
    });
    return mappedTypes;
}

export const GetAuthorizedSurveys = () => {
    const state = store.getState();
    let contentfulData = selectAllContentfulData(state);
    let impersonated = selectImpersonated(state);
    let user = selectUser(state);

    let rights = impersonated ? impersonated?.actionRights : user?.actionRights;

    if (typeof rights === "string") {
        rights = JSON.parse(rights);
    }

    return contentfulData.surveys.filter(survey => {
        return rights.surveys?.some(surveyRights => survey.id === surveyRights.value)
    })
};

export const initEntity = (entity) => {
    let entityResponses = entity.responses;
    let entityKeys = Object.keys(entityResponses);
    let authorizedSurveys = GetAuthorizedSurveys();
    let newResponses = {};

    authorizedSurveys.forEach((survey) => {
        if (!entityKeys.some(surveyId => surveyId === survey.id)) {  // if no response for this survey
            newResponses = {
                ...newResponses,
                [survey.id]: [{
                    name: survey.versioning.enabled ? `${survey.versioning.name}_1` : 'version_1',
                    responses: {}
                }]
            }
        }
    });

    return {
        ...entity,
        inited: true,
        responses: newResponses
    }
};

export const setSessionUser = (user) => {
    window.sessionStorage.setItem('dui', JSON.stringify(user));
}

export const getSessionUserId = () => {
    return window.sessionStorage.getItem('dui');
}

export const setSessionToken = (token) => {
    window.sessionStorage.setItem('user_token', token);
}

export const getSessionToken = () => {
    return window.sessionStorage.getItem('user_token');
}

export const getSurveyById = (surveyId) => {
    let contentfulData = selectSurveys(store.getState());
    return contentfulData.find(survey => survey.id === surveyId);
}





