import * as React from 'react';
import styled from 'styled-components';
import {IoSettingsOutline} from "react-icons/all";
import {IconType} from "react-icons";

export interface ButtonExpandProps {
    icon: IconType;
    actions: any;
    animation?: boolean;
    enabled?: boolean;
}

export const ButtonExpand = ({icon, actions, animation, enabled = true}: ButtonExpandProps) => {
    const Icon = icon;

    return (
        <Button enabled={enabled} animation={animation}>
            <ActionsContainer>
                {actions.map((action, index) => (
                    <Action onClick={action.onClick}
                            key={index}>{action.label}</Action>
                ))}
            </ActionsContainer>
            <Icon/>
        </Button>
    );
}

const Button = styled.div<{ animation: boolean; enabled: boolean }>`
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 1px 0px 4px #2b2b2b26;
  max-width: 20px;
  position: relative;
  overflow: hidden;
  opacity: 0.5;


  ${({enabled}) => enabled && `
    opacity: 1;
        
    &:hover {
      max-width: 300px;
    
      ${props => props.animation && `
          svg {
            transform: rotate(90deg);
          }
      `}
    }
  `};

  svg {
    transition: all 0.3s;
    min-height: 20px;
    min-width: 20px;
    opacity: 1;
  }

`;

const Action = styled.div`
  font-size: 1em;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  column-gap: 10px;
  padding: 0 10px;
  height: 20px;
`;
