import * as React from 'react';
import styled from 'styled-components';
import {
    AiOutlineFileText, BiLoaderAlt, BsCheckLg,

} from "react-icons/all";
import {useEffect, useRef} from "react";

export interface StepProps {
    user?: string;
    admin?: string;
}

export interface ConformityNumbersProps {
    steps: Array<StepProps>;
    currentStep: number;
};

export const ConformitySteps = ({steps, currentStep}: ConformityNumbersProps) => {
    const overflowedContainerRef = useRef(null);
    const stepElements = useRef([]);
    const filteredSteps = steps.filter((step) => !!step.user);

    useEffect(() => {
        let targetStepEltIndex = currentStep - 2;
        let previousStepElement = stepElements.current.filter(elt => {
            let currentStepEltIndex = parseInt(elt?.getAttribute('value'));
            targetStepEltIndex = targetStepEltIndex < 0 ? 0 : targetStepEltIndex;
            return currentStepEltIndex === targetStepEltIndex;
        })[0];


        overflowedContainerRef.current.scrollTo({
            top: previousStepElement.offsetTop - 20,
            behavior: "smooth",
        });
        stepElements.current = [];
    }, [currentStep]);

    return (
        <ConformityStepsContainer>
            <StepsContainer ref={overflowedContainerRef}>
                <StepsSlider>
                    {filteredSteps.map((step, index) => {
                        let current = currentStep - 1 === index;
                        let completed = index < currentStep - 1;
                        let first = index === 0;
                        let last = index === steps.length - 1;
                        return (
                            <Step ref={element => element && stepElements.current.push(element)}
                                  first={first}
                                  last={last}
                                  current={current}
                                  completed={completed}
                                  key={index}
                                  value={index}
                            >
                                <IconContainer>
                                    <Icon current={current} completed={completed}>
                                        {current
                                            ? <BiLoaderAlt/>
                                            : completed
                                                ? <BsCheckLg/>
                                                : <AiOutlineFileText/>
                                        }
                                    </Icon>
                                </IconContainer>
                                <CurrentStep>ETAPE {index + 1}</CurrentStep>
                                <Title current={current}>{step.user}</Title>
                                <StatusContainer>
                                    <Status current={current}
                                            completed={completed}>{current ? 'En cours' : completed ? 'Validée' : 'À venir'}</Status>
                                </StatusContainer>
                            </Step>
                        )
                    })}
                    <Spacer>&nbsp;</Spacer>
                </StepsSlider>
            </StepsContainer>
        </ConformityStepsContainer>
        /*{steps.map((step, index) => (
            <React.Fragment key={index}>
                <Step completed={index < currentStep}>
                    {step.label}
                </Step>
                {index !== steps.length - 1 &&
                    <Link completed={index < currentStep - 1}><span><StyledChecked/></span></Link>
                }
            </React.Fragment>
        ))}*/
    )
};

const ConformityStepsContainer = styled.div`
  height: 100%;
  width: 360px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background-image: linear-gradient(0deg, transparent 0%, #F4F7FE 90%);
  }
`;

const StepLine = styled.div`
  background-color: yellow;
  height: 100%;
  width: 40px;
  display: flex;
  position: relative;
  justify-content: center;
`;

const Line = styled.div`
  background-color: blue;
  width: 3px;
`;

const StepsContainer = styled.div`
  display: flex;
  row-gap: 10px;
  padding: 20px 10px 20px 50px;
  width: 300px;
  overflow-y: auto;
  height: calc(100% - 40px);
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const StepsSlider = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  height: 100%;
`;

const Step = styled.div<{ first: boolean, last: boolean, current: boolean, completed: boolean }>`
  border-radius: 15px;
  width: calc(100% - 40px);
  border: 2px solid ${({current, completed}) => current ? '#1179A0' : completed ? '#CCEBE3' : '#E0E0E0'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: gray;
  padding: 20px;
  background-color: ${({current, completed}) => current ? '#1179A0' : completed ? '#F0FCF6' : '#FAFBFC'};
  position: relative;
  transition: all 0.3s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
    height: 9px;
    background-color: ${({current, completed}) => current ? 'white' : completed ? 'white' : '#45526C'};
    border-radius: 50%;
    border: 2px solid ${({completed}) => completed ? '#27BD68' : '#45526C'};
    box-shadow: 1px 0px 4px #2b2b2b26;
  }

  &:before {
    content: '';
    position: absolute;
    left: -25px;
    top: ${({first}) => first ? '50%' : '-24px'};
    bottom: ${({last}) => last ? '50%' : '0px'};
    width: 2px;
    background-color: ${({completed}) => completed ? '#27BD68' : '#E3E4E7'};
  }

  ${({current}) => current && `box-shadow: 1px 0px 4px #2b2b2b26;`}
`;

const IconContainer = styled.div`
  flex: 1;
`;

const Icon = styled.div<{ current: boolean, completed: boolean }>`
  padding: 5px;
  border-radius: 50%;
  background-color: ${({completed}) => completed ? '#27BD68' : 'white'};
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({completed}) => completed ? 'white' : '#7F8799'};
  box-shadow: 1px 0px 4px #2b2b2b26;
  position: relative;

  svg {
    height: 22px;
    width: 22px;
  }

  ${({current}) => current && `
    &:after {
        position: absolute;
        content: '';
        height: 80%;
        width: 80%;
        border: 2px solid #CCEBE3;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
  `}
`;

const CurrentStep = styled.div`
  text-transform: uppercase;
  color: #ACB0B7;
  font-size: 1em;
  font-weight: 500;
`;

const Title = styled.div<{ current: boolean }>`
  color: ${({current}) => current ? 'white' : '#45526C'};
  font-weight: 500;
  font-size: 1.1em;
  margin-bottom: 5px;
  //text-transform: capitalize;
  //white-space: nowrap;
`;

const StatusContainer = styled.div`
  display: flex;
  min-height: 0;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;

const Status = styled.div<{ current: boolean, completed: boolean }>`
  min-height: 0;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: ${({current, completed}) => current ? `#99A9FA` : completed ? '#27BD68' : `#D9DFFB`};
  color: ${({current, completed}) => current ? 'white' : completed ? 'white' : '#324FE4'};
  font-weight: ${({current}) => current ? '600' : 'normal'};
  font-size: 0.9em;
`;

const Spacer = styled.div`
  min-height: 0px;
  height: 0px;
`;

const Fader = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  height: 15px;
  background-color: red;
  z-index: 9999;
`;
