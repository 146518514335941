import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {fetchAllContentfulData} from "./contentful.reducer";

export const changeLangAndFetchContentful = createAsyncThunk(
    'app/CHANGE_LANG',
    async (lang: any, thunkApi) => {
        let dispatch = thunkApi.dispatch;
        await dispatch(fetchAllContentfulData(lang))
        return lang;
    }
)
const initialState = {
    loading: false,
    lang: 'FR',
    targetDocument: null,
    targetExcel: null,
    displayDebug: false,
    currentSurvey: null,
    currentStep: 1,
    currentVersion: 'init',
    entities: [],
    darkMode: false,
    currentEntity: undefined,
    filteredSurveys: [],
    entitiesSetup: false,
    currentPage: null,
    errors: undefined,
    dirty: false,
};

export const appReducer = createSlice({
    name: 'APP',
    initialState: initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setLang: (state, action) => {
            state.lang = action.payload;
        },
        setTargetDocument: (state, action) => {
            state.targetDocument = action.payload;
        },
        setTargetExcel: (state, action) => {
            state.targetExcel = action.payload;
        },
        setDisplayDebug: (state, action) => {
            state.displayDebug = action.payload;
        },
        setCurrentSurvey: (state, action) => {
            state.currentSurvey = action.payload;
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        setCurrentVersion: (state, action) => {
            state.currentVersion = action.payload;
        },
        setDirty: (state, action) => {
            state.dirty = action.payload;
        },
        setEntities: (state, action) => {
            state.entities = action.payload;
        },
        setDarkMode: (state, action) => {
            state.darkMode = action.payload;
        },
        setCurrentEntity: (state, action) => {
            state.currentEntity = action.payload;
        },
        setFilteredSurveys: (state, action) => {
            state.filteredSurveys = action.payload;
        },
        setEntitiesSetup: (state, action) => {
            state.entitiesSetup = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        resetState: (state) => {
            state.loading = false;
            state.lang = 'FR';
            state.targetDocument = null;
            state.targetExcel = null;
            state.displayDebug = false;
            state.currentSurvey = null;
            state.currentStep = 1;
            state.currentVersion = 'init';
            state.entities = [];
            state.darkMode = false;
            state.currentEntity = undefined;
            state.filteredSurveys = [];
            state.entitiesSetup = false;
            state.errors = undefined;
            state.dirty = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeLangAndFetchContentful.fulfilled, (state, action) => {
                state.lang = action.payload;
            })
    }
});

export const {
    setLoading,
    setLang,
    setTargetDocument,
    setTargetExcel,
    setDisplayDebug,
    setCurrentSurvey,
    setCurrentVersion,
    setCurrentStep,
    setDirty,
    setEntities,
    setDarkMode,
    setCurrentEntity,
    setFilteredSurveys,
    resetState,
    setEntitiesSetup,
    setCurrentPage,
    setErrors,
} = appReducer.actions;

export const selectLoading = state => state.app.loading;

export const selectLang = state => state.app.lang;

export const selectTargetDocument = state => state.app.targetDocument;

export const selectTargetExcel = state => state.app.targetExcel;

export const selectDisplayDebug = state => state.app.displayDebug;

export const selectCurrentSurvey = state => state.app.currentSurvey;

export const selectCurrentStep = state => state.app.currentStep;

export const selectCurrentVersion = state => state.app.currentVersion;

export const selectDirty = state => state.app.dirty;

export const selectEntities = state => state.app.entities;

export const selectDarkMode = state => state.app.darkMode;

export const selectCurrentEntity = state => state.app.currentEntity;

export const selectFilteredSurveys = state => state.app.filteredSurveys;

export const selectEntitiesSetup = state => state.app.entitiesSetup;

export const selectCurrentPage = state => state.app.currentPage;

export const selectErrors = state => state.app.errors;

export default appReducer.reducer;
