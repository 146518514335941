import React, {useCallback} from "react";
import styled from "styled-components";
import Select from "react-select";
import CloseIcon from '@mui/icons-material/Close';
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import HelpIcon from '@mui/icons-material/Help';
import _ from "lodash";


const inputWidth = '100%';

interface InputComponentProps {
    register?: any;
    inputKey?: any;
    label?: any;
    name?: any;
    type?: any;
    longAnswers?: any;
    placeholder?: any;
    options?: any;
    value?: any;
    question?: any;
    onChange?: any;
    tooltip?: any;
    darkMode?: boolean;
    bold?: boolean;
    span?: number;
    index?: number;
    display?: string;
    clearable?: string;
    last?: boolean;
    required?: boolean;
};

export function InputComponent({
                                   inputKey,
                                   label,
                                   name,
                                   type,
                                   longAnswers,
                                   placeholder,
                                   options,
                                   value,
                                   onChange,
                                   tooltip,
                                   darkMode,
                                   bold,
                                   span,
                                   display,
                                   clearable,
                                   last,
                                   required
                               }: InputComponentProps) {

    const getMultiDefaultValue = (value) => {
        if (!value) {
            return [];
        }

        if (typeof value !== 'object') {
            return [{
                label: getOptionLabelByValue(value),
                value: value,
                name: value
            }]
        }

        return value.map((value, index) => ({
            label: getOptionLabelByValue(value),
            value: value,
            name: value
        })) || []
    }

    const getMultiValue = value => value.map(item => item.value);

    const getOptionLabelByValue = value => options?.find(options => options.value === value)?.label;

    return (
        <FieldContainer last={last} span={span} display={display}>
            <InputLabel dark={darkMode} htmlFor={name} bold={bold}>
                {label}
                {tooltip &&
                    <Tippy content={<StyledTooltip>{tooltip}</StyledTooltip>}>
                        <StyledHelpIcon
                            sx={{color: '#1179a0', fontSize: "20px", cursor: 'pointer', marginLeft: "10px"}}/>
                    </Tippy>
                }
                {type === "radio" &&
                    <span onClick={() => onChange({target: {name, value: null}})}>(Vider le champs)</span>
                }
                {required && <span className={'required'} style={{color: 'red'}}>(obligatoire)</span>}
            </InputLabel>
            {type === "text" && (
                <div key={inputKey} className="input">
                    <StyledInput
                        placeholder={placeholder}
                        type="text"
                        name={name}
                        defaultValue={value ?? ''}
                        onChange={e => onChange({target: {name, value: e.target.value}})}
                    />
                </div>
            )}
            {type === "radio" && (
                <React.Fragment key={inputKey}>
                    <RadioContainer dark={darkMode} className={longAnswers && "long-answers"}>
                        {options?.map((option, index) => (
                            <React.Fragment key={index}>
                                <Option dark={darkMode}>
                                    <input
                                        type="radio"
                                        id={option.value}
                                        value={option.value}
                                        checked={option.value === value}
                                        onChange={e => onChange({target: {name, value: option.value}})}
                                    />
                                    <span>{option.label}</span>
                                </Option>
                                {option.userInput && (
                                    <div>
                                        <input type="text" id={option.value} name={name}/>
                                        <label htmlFor={name}>{option.placeholder}</label>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </RadioContainer>
                </React.Fragment>
            )}
            {type === "select" && (
                <div className="input">
                    <Select
                        name={name}
                        options={options}
                        onChange={e => onChange(name, e?.value)}
                        defaultValue={value}
                        isClearable={clearable == "true"}
                    />
                </div>
            )}
            {type === "checkbox" && (
                <div className="input">
                    <Select
                        name={name}
                        isMulti={true}
                        options={options}
                        defaultValue={getMultiDefaultValue(value) || []}
                        onChange={e => onChange && onChange({target: {name, value: getMultiValue(e)}})}
                    />
                </div>
            )}
        </FieldContainer>
    );
}

const StyledInput = styled.input`
  &:focus-visible {
    outline-offset: unset;
    outline: 1px solid #2684FF !important;
  }
`;

const StyledTooltip = styled.span`
  font-family: "Baloo 2";
  font-size: 1em;
`;

const StyledHelpIcon = styled(HelpIcon)`
  &:focus {
    outline: none;
  }
`;

const FieldContainer = styled.div<{ last: boolean; span: number; display: string }>`
  display: ${({display}) => (!display || display === "true") ? 'block' : 'none'};
  width: ${inputWidth};
  font-family: "Baloo 2";
  grid-column: span ${({span}) => span ? span : 2} / auto;

  ${({last}) => last && `
    padding-bottom: 30px;
  `}
  .input {
    display: flex;
    flex-direction: column;

    label {
    }

    input[type="text"] {
      width: calc(${inputWidth} - 14px);
      height: 40px;
      border: solid 1px hsl(0, 0%, 80%);
      font-family: "Open Sans";
      border-radius: 4px;
      font-size: 1em;
      padding: 5px 0 5px 10px;
    }
  }
`;

const RadioContainer = styled.div<{ dark: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
  margin-top: 10px;
  width: ${inputWidth};
  flex-wrap: wrap;
  row-gap: 15px;


  &.long-answers {
    flex-direction: column;
    row-gap: 15px;

    label {
      display: flex;
      align-items: center;
      width: ${inputWidth};
      column-gap: 10px;
    }

    input {
      display: block;

      &:checked + span {
        background-color: transparent;
        color: #187EAD;
      }
    }

    span {
      width: auto;
      height: auto;
      background-color: transparent;
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      color: ${({dark}) => dark ? '#f1f2f6' : 'black'};

      &:hover {
        background-color: transparent;
        color: ${({dark}) => dark ? '#187EAD' : 'black'};
      }
    }
  }
`;

const Option = styled.label<{ dark: boolean }>`
  margin-top: 10px;

  input {
    display: none;
    margin: 0;

    &:checked + span {
      background-color: #187EAD;
      color: white;
    }
  }

  span {
    width: 54px;
    height: 37px;
    padding: 5px 10px;
    background-color: #f5f5f5;
    border-radius: 10px;
    font-family: "Baloo 2";
    cursor: pointer;
    box-shadow: 0 2px 7px 2px ${({dark}) => dark ? 'none' : '#e5e5e5'};
    transition: all 0.2s;
    user-select: none;
    font-size: 1em;

    &:hover {
      background-color: #187EAD;
      color: white;
    }
  }
`;

const InputLabel = styled.div<{ dark: boolean, bold: boolean }>`
  color: ${({dark}) => dark ? '#f1f2f6' : 'black'};
  font-family: "Baloo 2";
  font-size: 1em;
  margin-bottom: 5px;
  font-weight: ${({bold}) => bold ? 600 : 200};
  display: flex;
  align-items: center;

  span {

    cursor: pointer;
    margin-left: 10px;
    transition: all 0.3s;

    &:not(.required) {
      color: #28607a;
      opacity: 0.4;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;
