import * as React from 'react';
import styled from 'styled-components';
import {SidenavItem, SidenavItemProps} from "./SidenavItem";
import {Logo} from "../logo/logo";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUser} from "../../reducers/user.reducer";
import {USER_ROLES} from "../../utils/constants";

export interface SidenavProps {
    menuItems: Array<SidenavItemProps>;
}

export const SideNav = ({menuItems}: SidenavProps) => {
    const location = useLocation();
    const user = useSelector(selectUser);

    return (
        <Sidenav>
            <LogoContainer>
                <Logo subtitle={''} whiteBg={true}/>
            </LogoContainer>
            <Menu>
                {menuItems && menuItems.map((item, index) => {
                    if (item.admin && (user?.userType === USER_ROLES.AGENCY || user?.userType === USER_ROLES.ADMIN)) {
                        return (<SidenavItem admin={true} path={item.path} active={item.path === location.pathname}
                                             key={`sidenavitem-${index}`} icon={item.icon} label={item.label}/>)
                    } else if (!item.admin) {
                        return (<SidenavItem path={item.path} active={item.path === location.pathname}
                                             key={`sidenavitem-${index}`} icon={item.icon} label={item.label}/>)
                    }
                })}
            </Menu>
        </Sidenav>
    )
};

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
`;

const Sidenav = styled.nav`
  width: 260px;
  height: calc(100% - 40px);
  background-color: #f5f5f5;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
`;

const SidenavLogo = styled.div``;
