import * as React from 'react';
import styled from 'styled-components';
import {
    selectCurrentEntity,
    selectCurrentSurvey,
    selectEntities,
    setCurrentVersion,
    setEntities
} from "../../reducers/app.reducer";
import {useDispatch, useSelector} from "react-redux";
import {ButtonExpand} from "../button-expand/ButtonExpand";
import {FaCheck, FaTimes, IoSettingsOutline} from "react-icons/all";
import _ from "lodash";
import {useState} from "react";
import {selectImpersonated, selectUser, updateResponses} from "../../reducers/user.reducer";
import {selectAllContentfulData} from "../../reducers/contentful.reducer";
import {useHistory} from "react-router-dom";
import {pathes} from "../../App";
import {handleConditions} from "../../utils/utils";


interface SurveyCardProps {
    version: { name: string, responses: any };
}

export const VersionCard = ({version}: SurveyCardProps) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const entities = useSelector(selectEntities);
    const currentEntity = useSelector(selectCurrentEntity);
    const currentSurvey = useSelector(selectCurrentSurvey);
    const impersonated = useSelector(selectImpersonated);
    const contentfulData = useSelector(selectAllContentfulData);
    const user = useSelector(selectUser);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showRenameConfirmation, setShowRenameConfirmation] = useState(false);
    const [newName, setNewName] = useState(undefined);

    const HandleVersionChangeName = async () => {
        let entitiesCopy = _.cloneDeep(entities);
        let currentEntityCopy = entitiesCopy.find(entity => entity.name === currentEntity.name);
        let currentResponses = currentEntityCopy.responses[currentSurvey.id];

        if (newName) {
            currentResponses = currentResponses.map(versionItem => {
                if (versionItem.name === version.name) {
                    versionItem.name = newName;
                }
                return versionItem;
            })

            dispatch(setEntities(entitiesCopy));
            dispatch(setCurrentVersion(newName));
            await saveResponses(entitiesCopy);
        }
        closeRenameConfirmation();
    };

    const saveResponses = async (updatedEntities) => {
        await dispatch(updateResponses({userId: impersonated ? impersonated._id : user._id, values: updatedEntities}));
    };

    const handleDeleteVersionTab = async () => {
        let entitiesCopy = _.cloneDeep(entities);
        let currentEntityCopy = entitiesCopy.find(entity => entity.name === currentEntity.name);
        currentEntityCopy.responses[currentSurvey.id] = currentEntityCopy.responses[currentSurvey.id].filter(versionItem => versionItem.name !== version.name);
        dispatch(setEntities(entitiesCopy));
        await saveResponses(entitiesCopy);
        closeDeleteConfirmation();
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    const closeRenameConfirmation = () => {
        setShowRenameConfirmation(false);
        setNewName(null);
    }

    const handleDeleteVersionConfirmation = () => {
        closeRenameConfirmation();
        setShowDeleteConfirmation(true);
    }

    const handleRenameVersionConfirmation = () => {
        closeDeleteConfirmation()
        setShowRenameConfirmation(true);
    }

    const getFullSurveyFromContentful = (surveyId) => contentfulData.surveys.find(survey => survey.id === surveyId);

    const getVersionResponses = (surveyId, versionName) => {
        let currentEntityCopy = entities.find(entity => entity.name === currentEntity.name);
        return currentEntityCopy.responses[surveyId].find(version => version.name === versionName).responses;
    }

    const getRequiredQuestionsAmountBySurveyId = () => {
        let requiredQuestions = [];
        currentSurvey?.steps?.map(step => {
            step.form.map(question => {
                if (question.required) {
                    if (handleConditions(question, getVersionResponses(currentSurvey.id, version.name))) {
                        requiredQuestions.push({name: question.name});
                    }
                }
            });
        });

        return {
            requiredQuestions,
            amount: requiredQuestions.length
        }
    }

    const getFilledRequiredQuestionsAmount = () => {
        let requiredQuestions = getRequiredQuestionsAmountBySurveyId().requiredQuestions;
        let filledRequiredQuestions = 0;
        requiredQuestions.map(question => {
            if (version?.responses && version?.responses[question.name]) {
                filledRequiredQuestions++;
            }
        });
        return filledRequiredQuestions;
    }

    const getpercentageOfFilledRequiredQuestions = () => {
        let requiredQuestions = getRequiredQuestionsAmountBySurveyId().amount;
        let filledRequiredQuestions = getFilledRequiredQuestionsAmount();
        let percentage = Math.round((filledRequiredQuestions / requiredQuestions) * 100);
        return isNaN(percentage) ? 0 : percentage;
    }

    const handleSelectVersion = () => {
        dispatch(setCurrentVersion(version.name));
        history.push(pathes.compliance);
    }

    const cardActions = [
        {
            label: "Supprimer",
            onClick: handleDeleteVersionConfirmation
        },
        {
            label: "Modifier",
            onClick: handleRenameVersionConfirmation
        },
    ];

    return (
        <VersionContainer>
            <CardTop>
                <ButtonExpand animation={true} icon={IoSettingsOutline} actions={cardActions}/>
            </CardTop>
            <VersionContent onClick={handleSelectVersion}>
                <Left>
                    <VersionTitle>{version.name}</VersionTitle>
                    <VersionSubtitle>Questions
                        obligatoires: {getFilledRequiredQuestionsAmount()} / {getRequiredQuestionsAmountBySurveyId().amount}</VersionSubtitle>
                </Left>
                <Right>
                    <Label>Conformité</Label>
                    <Percentage>{getpercentageOfFilledRequiredQuestions()}%</Percentage>
                </Right>
            </VersionContent>
            <PopinContent open={showDeleteConfirmation}>
                <PopinTitle>Etes-vous sûrs de vouloir
                    supprimer <span>{version.name}</span> ?</PopinTitle>
                <Actions>
                    <Action onClick={closeDeleteConfirmation}>
                        <Label>Annuler</Label>
                        <FaTimes style={{color: '#ff7675'}}/>
                    </Action>
                    <Action onClick={handleDeleteVersionTab}>
                        <Label>Valider</Label>
                        <FaCheck style={{color: '#55efc4'}}/>
                    </Action>
                </Actions>
            </PopinContent>
            <PopinContent open={showRenameConfirmation}>
                <PopinTitle>Quel nouveau nom voulez-vous donner à <span>{version.name}</span> ?</PopinTitle>
                <input type="text" name={'newName'} defaultValue={version.name}
                       onChange={(e) => setNewName(e.target.value)}/>
                <Actions>
                    <Action onClick={closeRenameConfirmation}>
                        <Label>Annuler</Label>
                        <FaTimes style={{color: '#ff7675'}}/>
                    </Action>
                    <Action onClick={HandleVersionChangeName}>
                        <Label>Valider</Label>
                        <FaCheck style={{color: '#55efc4'}}/>
                    </Action>
                </Actions>
            </PopinContent>
        </VersionContainer>
    )
}

const CardTop = styled.div`
  width: calc(100% - 20px);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
`;

const Label = styled.div`
  font-weight: 500;
`;

const Percentage = styled.div`
  font-size: 2em;
  color: #797979;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const VersionContainer = styled.div`
  background-color: white;
  border-radius: 15px;
  height: 200px;
  width: 300px;
  position: relative;
  background-image: url("https://st2.depositphotos.com/4202565/8003/v/600/depositphotos_80030720-stock-illustration-clipboard-with-checklist-and-pen.jpg");
  background-size: cover;
  background-position: bottom -76px center;
  background-repeat: no-repeat;
  overflow: hidden;
  box-shadow: 1px 0 4px #2b2b2b26;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    box-shadow: 1px 4px 10px 0px #4e4e4e36;
    background-position: bottom -25px center;
  }
`;

const VersionContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 45px;
  padding: 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: white;
  box-shadow: 1px 0px 4px #2b2b2b56;
  display: flex;
  column-gap: 15px;
`;

const VersionTitle = styled.div`
  font-size: 1.2em;
  font-weight: 500;
`;

const VersionSubtitle = styled.div`
  font-size: 0.9em;
  color: #797979;
`;

//POPIN
const PopinContent = styled.div<{ open: boolean }>`
  position: absolute;
  background-color: white;
  width: calc(100% - 30px);
  height: calc(100% - 55px);
  left: 0;
  right: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: transform 0.2s;
  transform: translateY(${({open}) => open ? '0%' : '100%'});
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px 15px;
  row-gap: 5px;

  input {
    font-family: 'Baloo 2';
    width: calc(100% - 30px);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    font-size: 1em;
    padding: 5px 15px;

    &:focus-visible {
      outline-offset: unset;
      outline: 1px solid #2684FF !important;
    }
  }
`;

const PopinTitle = styled.span`
  font-size: 1.2rem;
  text-align: center;

  span {
    color: #187ead
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  svg {
    height: 20px;
    width: 20px;
  }
`;
