import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {createUser, login, updateRemaining, updateDbUser, updateUserResponses} from "../utils/api";
import {setLoading} from "./app.reducer";
import {NewUserInfos} from "../components/dashboard-create-user/DashboardCreateUser";
import {selectAllUsers, setAllUsers} from "./dashboard.reducer";
import {setSessionToken, setSessionUser} from "../utils/utils";

export const createDbUser = createAsyncThunk(
    'user/CREATE_USER',
    async (userInfos: NewUserInfos, thunkApi) => {
        let dispatch = thunkApi.dispatch;
        let state = thunkApi.getState();
        let allUsers = selectAllUsers(state);
        dispatch(setLoading(true));
        let createdUser = await createUser(userInfos);
        if (createdUser.ok) {
            dispatch(setAllUsers([...allUsers, createdUser.user]));
            dispatch(setLoading(false));
            return {token: createdUser.xsrfToken, user: createdUser.user};
        }
        dispatch(setLoading(false));
        return {token: createdUser.xsrfToken};
    }
);

export const submitLogin = createAsyncThunk(
    'user/SUBMIT_LOGIN',
    async (credentials: any, thunkApi) => {
        let dbCreatedUser = await login(credentials.email, credentials.password);
        let userResponses = dbCreatedUser.user.responses;
        let responses = [];

        Object.keys(userResponses).forEach(key => {
            responses.push(userResponses[key]);
        });

        let userRights = dbCreatedUser.user.actionRights;
        userRights = userRights ?? {};

        return {
            token: dbCreatedUser.xsrfToken,
            user: {...dbCreatedUser.user, actionRights: userRights, responses}
        };
    }
);

export const updateDbRemaining = createAsyncThunk(
    'user/UPDATE_REMAINING_DL',
    async (options: { userId: string, actions: any }, thunkApi) => {
        let response = await updateRemaining(options.userId, options.actions);
        return {error: response.error, user: response.user};
    }
);

export const updateSelectedRemaining = createAsyncThunk(
    'user/UPDATE_SELECTED_REMAINING_DL',
    async (options: { userId: string, actions: any }, thunkApi) => {
        let response = await updateRemaining(options.userId, options.actions);
        return {error: response.error, user: response.user};
    }
);

export const updateResponses = createAsyncThunk(
    'user/UPDATE_RESPONSES',
    async (data: { userId: string, values: any }, thunkApi) => {
        let state = thunkApi.getState();
        let dispatch = thunkApi.dispatch;
        let impersonated = selectImpersonated(state);
        let userId = impersonated ? selectImpersonated(state)._id : selectUser(state)._id;

        if (data.userId !== undefined) {
            userId = data.userId;
        }

        let updatedUser = await updateUserResponses(userId, data.values);

        if (impersonated) {
            dispatch(setImpersonated(updatedUser.updatedUser));
        }

        return {...updatedUser.updatedUser};
    });

export const updateUser = createAsyncThunk(
    'user/UPDATE_USER',
    async (options: { userId: string, selectedUser: any }, thunkApi) => {
        let updatedUser = await updateDbUser(options.userId, JSON.stringify(options.selectedUser));
        updatedUser.responses = JSON.parse(updatedUser.updatedUser.responses);
        updatedUser.actionRights = JSON.parse(updatedUser.updatedUser.actionRights);
        updatedUser.links = JSON.parse(updatedUser.updatedUser.links);
        return updatedUser;
    });

export const userReducer = createSlice({
    name: 'USER',
    initialState: {
        token: undefined,
        user: undefined,
        impersonated: undefined,
        downloadOk: true
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setImpersonated: (state, action) => {
            state.impersonated = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        disconnectUser: state => {
            state.user = undefined;
            state.impersonated = undefined;
            state.token = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitLogin.fulfilled, (state, action) => {
                state.user = action.payload.user;
                setSessionToken(action.payload.token);
                setSessionUser(action.payload.user._id);
                state.downloadOk = action.payload.user.remainingDownload > 0;
            })
            .addCase(submitLogin.rejected, (state, action) => {
                alert('Mauvais identifiants');
            })
            .addCase(updateDbRemaining.fulfilled, (state, action) => {
                state.user.remainingDownload = action.payload.user.remainingDownload;
                state.downloadOk = action.payload.user.remainingDownload > 0;
            })
            .addCase(updateResponses.fulfilled, (state, action) => {
                if (action.payload._id === state.user._id) {
                    state.user = action.payload;
                }
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                if (action.payload._id === state.user._id) {
                    state.user = action.payload;
                }
            })
            .addCase(updateSelectedRemaining.fulfilled, (state, action) => {
                state.user.remainingDownload = action.payload.user;
            })
    }
});

export const {setUser, setImpersonated, setToken, disconnectUser} = userReducer.actions;

export const selectUser = state => state.user.user;

export const selectImpersonated = state => state.user.impersonated;

export const selectToken = state => state.user.token;

export const selectAllResponses = state => state.user.allResponses;

export const selectDownloadOk = state => state.user.downloadOk;

export const selectActionRights = state => state.user.user.actionRights;

export default userReducer.reducer;
