import * as React from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from "react-redux";
import {selectImpersonated, selectUser, updateResponses} from "../../reducers/user.reducer";
import {useHistory} from "react-router-dom";
import {pathes} from "../../App";
import {
    selectCurrentEntity,
    selectCurrentSurvey,
    selectEntities,
    setCurrentStep,
    setCurrentSurvey,
    setCurrentVersion, setEntities
} from "../../reducers/app.reducer";
import {selectAllContentfulData} from "../../reducers/contentful.reducer";
import _ from "lodash";
import {AiOutlinePlusCircle, RiSurveyLine} from "react-icons/all";
import {VersionCard} from "../version-card/VersionCard";
import {handleConditions} from "../../utils/utils";

export interface MySurveysProps {
}

export const MySurveys = ({}: MySurveysProps) => {
    //redux
    const dispatch = useDispatch();
    const currentEntity = useSelector(selectCurrentEntity);
    const contentfulData = useSelector(selectAllContentfulData);
    const currentSurvey = useSelector(selectCurrentSurvey);
    const entities = useSelector(selectEntities);
    const impersonated = useSelector(selectImpersonated);
    const user = useSelector(selectUser);
    const history = useHistory();

    const handleSurveyChoice = (surveyId) => {
        let survey = contentfulData.surveys.find(survey => survey.id === surveyId);
        dispatch(setCurrentSurvey(survey));
        let allVersionNames = Object.keys(currentEntity.responses?.[survey.id]);
        dispatch(setCurrentVersion(allVersionNames[allVersionNames.length - 1]));
        dispatch(setCurrentStep(1));
    };

    const getEntityResponsesBySurveyId = (surveyId) => {
        return entities.find(entity => {
            return entity.name === currentEntity.name;
        })?.responses?.[surveyId];
    };

    const getFullSurveyFromContentful = (surveyId) => contentfulData.surveys.find(survey => survey.id === surveyId);

    const getSurveyFirstVersion = (surveyId) => {
        let versions = Object.keys(getEntityResponsesBySurveyId(surveyId));
        return versions[0];
    };

    const getAuthorizedSurveys = () => impersonated ? impersonated?.actionRights?.surveys : user?.actionRights?.surveys;

    const getSurveysData = () => {
        let headers = {items: ['Nom']};
        let surveys = getAuthorizedSurveys();
        if (!surveys) {
            return;
        }
        let data = surveys.map(survey => {
            let fullSurvey = getFullSurveyFromContentful(survey.value);
            return {
                label: survey.label,
                _id: survey.value,
                onClick: () => {
                    handleSurveyChoice(survey.value);
                    dispatch(setCurrentVersion(getSurveyFirstVersion(survey.value)));
                    history.push(pathes.compliance);
                },
                items: [survey.label],
                versioning: fullSurvey?.versioning,
            }
        });
        return {headers, data}
    };

    const handleSelectSurvey = (survey) => {
        dispatch(setCurrentSurvey(getFullSurveyFromContentful(survey?._id)));
        if (!survey?.versioning?.enabled) {
            dispatch(setCurrentVersion(getEntityResponsesBySurveyId(survey._id)?.[0]?.name));
            history.push(pathes.compliance);
        }
    };

    const handleNewVersionTab = async (surveyId) => {
        let survey = getFullSurveyFromContentful(surveyId);
        let entitiesCopy = _.cloneDeep(entities);
        let entity = entitiesCopy.find(entity => entity.name === currentEntity.name);
        let currentResponses = entity?.responses?.[survey.id];
        let versionsAmount = currentResponses?.length + 1;
        currentResponses.push({name: survey.versioning.name + '_' + versionsAmount, responses: {}});
        dispatch(setEntities(entitiesCopy));
        await saveResponses(entitiesCopy);
    };

    const saveResponses = async (updatedEntities) => {
        await dispatch(updateResponses({userId: impersonated ? impersonated._id : user._id, values: updatedEntities}));
    };

    const getRequiredQuestionsAmountBySurvey = (survey) => {
        let requiredQuestions = [];
        getFullSurveyFromContentful(survey._id)?.steps?.map(step => {
            step.form.map(question => {
                if (question.required) {
                    // Only manage 1st version because Multiple version managed individually in Version Card
                    if (currentEntity.responses?.[survey._id] && handleConditions(question, currentEntity.responses?.[survey._id][0])) {
                        requiredQuestions.push({name: question.name});
                    }

                }
            });
        });
        return {
            questions: requiredQuestions,
            amount: requiredQuestions.length
        }
    }

    const getFilledRequiredQuestionsAmount = (survey) => {
        let requiredQuestions = getRequiredQuestionsAmountBySurvey(survey).questions;
        let currentResponsesSet = getEntityResponsesBySurveyId(survey._id);
        let currentResponses = currentResponsesSet && currentResponsesSet[0]?.responses;
        let filledRequiredQuestions = 0;

        if (!currentResponses) {
            return 0;
        }

        requiredQuestions.map(question => {
            if (currentResponses[question.name] !== undefined && currentResponses[question.name] !== null && !_.isEmpty(currentResponses[question.name])) {
                filledRequiredQuestions += 1;
            }
        });
        return filledRequiredQuestions;
    }

    return (
        <MySurveysContainer>
            <SurveyListContainer>
                <TopContainer>
                    <ListTitle>Mes questionnaires</ListTitle>
                </TopContainer>
                <SurveyList>
                    <SurveySlider>
                        {getSurveysData()?.data?.map((survey, index) => (
                            <Survey key={index} onClick={() => handleSelectSurvey(survey)}
                                    active={currentSurvey?.id === survey._id}>
                                <Icon>
                                    <RiSurveyLine/>
                                </Icon>
                                <SurveyContent>
                                    <SurveyTitle>{survey.label}</SurveyTitle>
                                    {survey?.versioning?.enabled &&
                                        <SurveySubtitle>Nombre de
                                            versions: {getEntityResponsesBySurveyId(survey._id)?.length}</SurveySubtitle>
                                    }
                                    {!survey?.versioning?.enabled &&
                                        <SurveySubtitle>Questions
                                            obligatoires: {getFilledRequiredQuestionsAmount(survey)} / {getRequiredQuestionsAmountBySurvey(survey).amount}</SurveySubtitle>
                                    }
                                </SurveyContent>
                            </Survey>
                        ))}
                    </SurveySlider>
                </SurveyList>
            </SurveyListContainer>
            {currentSurvey?.versioning?.enabled &&
                <VersionListContainer>
                    <TopContainer>
                        <ListTitle>Versions du questionnaire</ListTitle>
                        <AddVersion onClick={() => handleNewVersionTab(currentSurvey.id)}> <AiOutlinePlusCircle/>Ajouter
                            une
                            version</AddVersion>
                    </TopContainer>
                    <VersionList>
                        <VersionsSlider>
                            {currentSurvey && getEntityResponsesBySurveyId(currentSurvey.id)?.map((version, index) => (
                                <VersionCard key={index} version={version}/>
                            ))}
                        </VersionsSlider>
                    </VersionList>
                </VersionListContainer>
            }
            <InfosContainer>
            </InfosContainer>
        </MySurveysContainer>
    )
}

const SurveyList = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  padding: 20px;
  height: calc(100% - 40px);
  overflow-y: auto;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
`;

const AddVersion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
  color: white;
  opacity: 0.7;
  border-radius: 15px;
  background-color: #187EAD;
  column-gap: 10px;
  padding: 6px 20px;

  &:hover {
    opacity: 1;
  }

  svg {
    opacity: 1;
    height: 20px;
    width: 20px;
  }
`;

const MySurveysContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  row-gap: 10px;
  flex: 1;
`;

const SurveyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const VersionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  row-gap: 10px;
`;

const VersionList = styled.div`
  height: 100%;
  overflow-y: auto;
`;


const VersionsSlider = styled.div`
  display: grid;
  padding: 20px;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  justify-items: center;
`;

const Survey = styled.div<{ active: boolean }>`
  background-color: white;
  box-shadow: 1px 0px 4px #2b2b2b26;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  width: 300px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;

  ${({active}) => active && `
    outline: 2px solid #1179A0;
  `}
  &:hover {
    outline: 2px solid #1179A0;
  }
`;

const Icon = styled.div`
  background-color: #1179A0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 8px;


  svg {
    width: 22px;
    height: 22px;
    color: white;
  }
`;

const SurveyContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const SurveyTitle = styled.div`
  font-size: 1em;
  font-weight: 500;
`;

const SurveySubtitle = styled.div`
  font-size: 1em;
  color: #797979;
`;

const ListTitle = styled.div`
  font-size: 1.5em;
`;

const SurveySlider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
`;

const InfosContainer = styled.div`
  flex: 1;
`;
