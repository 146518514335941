import styled from 'styled-components';
import officeImg from '../../assets/office-bg.jpg';
import {Logo} from "../logo/logo";
import {Button} from "../button";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {submitLogin} from "../../reducers/user.reducer";
import {useEffect} from "react";
import {Waves} from "./waves";

export const Login = () => {
    const dispatch = useDispatch();
    const {register, getValues, reset} = useForm();

    let handleLogin = async () => {
        dispatch(submitLogin({email: getValues('email'), password: getValues('password')}));
    }

    useEffect(() => {
        reset({});
    }, [])

    return (
        <LoginContainer>
            <Left>
                <StyledLogo large={true} className={'logo-dipeeo'} subtitle={'SAAS'}/>
                <Content></Content>
               {/* <Waves/>*/}
            </Left>
            <Right>
                <FormContainer>
                    <Beta>BETA V2</Beta>
                    <Title>Connexion</Title>
                    <InputContainer>
                        <label htmlFor="email">Email</label>
                        <input {...register('email')} type="text" name={'email'}/>
                    </InputContainer>
                    <InputContainer>
                        <label htmlFor="login">Mot de passe</label>
                        <input {...register('password')} type="password" name={'password'}/>
                    </InputContainer>
                    <Button label={'Se connecter'} onClick={handleLogin}/>
                </FormContainer>
            </Right>
        </LoginContainer>
    )
}

const Content = styled.div`
  
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2d3436;
  font-weight: 300;
`;

const LoginContainer = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
`;

const Left = styled.div`
  background-color: #1179a0;
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Right = styled.div`
  flex: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 50%;
  justify-content: center;
  row-gap: 20px;
`;

const Beta = styled.div`
  height: 20px;
  width: 100px;
  background-color: #f49d1d;
  color: white;
  z-index: 999;
  content: 'BETA';
  position: fixed;
  right: -22px;
  top: 16px;
  font-family: "Baloo 2";
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  font-weight: 600;
  letter-spacing: 3px;
`;

const BackgroundContainer = styled.div``;

const AuthContainer = styled.div`
  width: 400px;
  background-color: white;
  z-index: 9999;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  row-gap: 20px;
  border-radius: 5px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-family: "Baloo 2";
  width: 100%;

  input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #E6E6E6;
    font-family: "Baloo 2";
    font-size: 1em;

    &:focus-visible, &:focus {
      border: 1px solid #696969 !important;
      outline: none;
    }
  }
`;

const StyledLogo = styled(Logo)``;

const AuthTitle = styled.h1`
  color: #2b2b2b;
  font-size: 1.5625em;
  text-align: center;
  width: 100%;
  font-weight: normal;
`;
