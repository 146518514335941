import * as React from 'react';
import styled from "styled-components";
import {EntityCard} from "../entity-card/EntityCard";
import {useCallback, useEffect, useRef} from "react";
import * as _ from "lodash";
import {useSelector} from "react-redux";
import {selectEntities} from "../../reducers/app.reducer";

export interface DashboardCardSliderProps {
}

export const DashboardCardSlider = (props: DashboardCardSliderProps) => {
    const cardSliderRef = useRef(null);
    const entities = useSelector(selectEntities);

    const handleScroll = (e) => {
        cardSliderRef.current.scrollTo({
            left: cardSliderRef.current.scrollLeft + (e.deltaY > 0 ? 300 : -300),
            behavior: "smooth",
        })
    }

    const debouncedHandleScroll = useCallback(_.throttle(handleScroll, 300, {trailing: true, leading: true}), []);

    useEffect(() => {
        if (cardSliderRef) {
            cardSliderRef.current.addEventListener('mousewheel', debouncedHandleScroll);
        }
    }, []);

    return (
        <EntityCardContainer ref={cardSliderRef}>
            <EntityCardSlider>
                {entities?.map((entity, index) => {
                    return (
                        <EntityCard nameChanged={entity.nameChanged} key={index} entity={entity}/>
                    )
                })}
                {(entities[entities.length - 1]?.nameChanged || !entities.length) &&
                    <EntityCard create={true}/>
                }
            </EntityCardSlider>
        </EntityCardContainer>
    );
}


const EntityCardSlider = styled.div`
  //background-color: blue;
  padding: 20px;
  display: flex;
  column-gap: 20px;
  //width: 100%;
`;

const EntityCardContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: -140px;

  ::-webkit-scrollbar {
    display: none;
  }
`;
