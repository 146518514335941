import styled from 'styled-components';
import React, {useEffect, useState} from "react";
import {deleteUserById, getUserById} from "../../utils/api";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setImpersonated} from "../../reducers/user.reducer";
import {getAllUsers, selectAllUsers, selectSelectedUser, setSelectedUser} from "../../reducers/dashboard.reducer";
import {DashboardGenericTable} from "../dashboard-generic-tab/DashboardGenericTab";

import {UserDetails} from "../dashboard-user-details/UserDetails";
import {setCurrentEntity, setLoading} from "../../reducers/app.reducer";
import {DashboardCreateUser} from "../dashboard-create-user/DashboardCreateUser";
import {BsTrash, FiUsers} from "react-icons/all";
import {pathes} from "../../App";
import {USER_ROLES} from "../../utils/constants";

enum DashboardMode {
    CREATE,
    MODIFY
}

export const AdminPanel = () => {

    //state
    const [mode, setMode] = useState(DashboardMode.MODIFY);

    //redux
    const dispatch = useDispatch();
    const allUsers = useSelector(selectAllUsers);
    const selectedUser = useSelector(selectSelectedUser);
    const user = useSelector(selectUser);
    const [parsedUsers, setParsedUsers] = useState([]);

    const history = useHistory();

    const handleSelectUser = async targetUser => {
        dispatch(setLoading(true));
        const userRefreshedData = await getUserById(targetUser._id);
        setMode(DashboardMode.MODIFY);
        dispatch(setSelectedUser(userRefreshedData));
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 1000);
    };

    const handleDeleteUser = async (userId) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?') === true) {
            await deleteUserById(userId);
            await dispatch(getAllUsers({}));
        }
        dispatch(setLoading(false));
    };

    const handleImpersonate = async (user) => {
        await dispatch(setImpersonated(user));
        await dispatch(setCurrentEntity(null));
        history.push(pathes.dashboard);
    };

    /* const handleMigrate = async () => {
         let headers = new Headers({});
         headers.set("Content-Type", "application/json");
         headers.set("accept", "application/json");
         let links = getRelationsBetweenSurveys(contentfulSurveys, contentfulDocuments).keysToSurveys;

         let response = await fetch(`http://localhost:5000/form/bash`, {
             method: 'POST',
             headers: headers,
             body: JSON.stringify({
                 links: links
             })
         });

         let parseResponse = await response.json();
         console.log(parseResponse);
     };*/

    const getUsersTabData = users => {
        let allowedUsers = users;
        if (user.userType === USER_ROLES.AGENCY) {
            allowedUsers = allowedUsers.filter(userItem => userItem._id === user._id);
        }

        return allowedUsers.map(user => ({
            onClick: () => handleSelectUser(user),
            items: [user.userName, user.email],
            current: user.userName === selectedUser?.userName,
            actions: [{
                label: 'Impersonate',
                onClick: () => handleImpersonate(user),
                icon: FiUsers
            }, {
                label: 'Delete',
                onClick: () => handleDeleteUser(user._id),
                icon: BsTrash
            }]
        }))
    };

    const getUsersTabHeaders = () => ({items: ['Nom', 'Email']});

    const createNewUser = () => {
        setMode(DashboardMode.CREATE);
    }

    const closeCreateUser = () => {
        setMode(DashboardMode.MODIFY);
    }

    useEffect(() => {
        (async () => {
            dispatch(setImpersonated(null));
            dispatch(getAllUsers({}));
            dispatch(setSelectedUser(null));
            await handleSelectUser(user);
        })()
    }, []);

    useEffect(() => {
        setParsedUsers(getUsersTabData(allUsers));
    }, [allUsers]);

    return (
        <>
            {(allUsers && allUsers.length > 0) &&
                <AdminPanelContainer>
                    {user?.userType !== USER_ROLES.AGENCY &&
                        <UserList>
                            <DashboardGenericTable
                                current={selectedUser?.userName}
                                title={'Clients'}
                                headers={getUsersTabHeaders()}
                                content={parsedUsers}
                                searchable={true}
                                {...(user.userType !== USER_ROLES.AGENCY ? {
                                    action: {
                                        label: 'Ajouter un client',
                                        onClick: createNewUser
                                    }
                                } : {})}
                            />
                        </UserList>
                    }
                    {mode === DashboardMode.MODIFY &&
                        <UserDetails/>
                    }
                    {mode === DashboardMode.CREATE &&
                        <DashboardCreateUser close={closeCreateUser}/>
                    }
                </AdminPanelContainer>
            }
        </>
    )
}

const AdminPanelContainer = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  height: 100%;
  width: 100%;
  row-gap: 10px;
  column-gap: 10px;
  position: relative;
  flex: 1;
`;

const UserList = styled.div`
  grid-column: span 3;
  grid-row: span 8;
  height: 100%;
  width: 100%;
`;

const Counters = styled.div`
  grid-column: span 2;
  grid-row: span 2;
  height: 100%;
  width: 100%;
`;

/*//STYLED COMPONENTS
const StyledButton = styled.div`
  padding: 10px;
  color: black;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const UserList = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c7c7c7;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 2.1px -1px 12px 0px #f0f0f0;
  }
`;

const Row = styled.div`
  border-bottom: 1px solid #dadada;
  width: 100%;
  display: flex;
  height: 50px;
  justify-content: space-between;
`;

const RowHeader = styled(Row)`
  font-weight: bold;
  div:last-child {
    margin-right: 9px;
  }
`;

const RowCell = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-start;
  width: 150px;
  &.text {
    width: 300px;
  }

  &:last-child {
    column-gap: 20px;
  }

  > svg {
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }
  }
`;

const DashboardContainer = styled.div`
  height: calc(100vh - 150px);
  width: calc(100% - 200px);
  padding: 100px 100px 50px 100px;
  position: relative;
`;

const ModeSelector = styled.div`
  font-family: "Baloo 2";
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;
`;

const SelectorItem = styled.div<{ current: boolean }>`
  cursor: pointer;
  font-size: 17px;
  color: white;
  transition: all 0.3s;
  border-left: 3px solid ${props => props.current ? 'white' : 'transparent'};
  padding-left: ${props => props.current ? '10px' : '0'};
`;

const LeftContainer = styled.div`
  width: 150px;
  height: calc(100vh - 150px);
  background-color: #2d3436;
  padding: 0 15px;
`;

const RightContainer = styled.div`
  background-color: white;
  flex: 1;
`;

const DashboardContent = styled.div`
  display: flex;
  height: calc(100vh - 150px);
  overflow: hidden;
  position: relative;
`;

const CardContainer = styled.div`
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Baloo 2";
  width: calc(100% - 40px);
  height: calc(100% - 40px);

  h3 {
    margin-bottom: 20px;
    span {
      font-size: 1em;
      opacity: 0.5;
    }
  }
`;*/
