import * as React from 'react';
import styled from 'styled-components';
import {
    FaCheck, FaTimes,
    IoSettingsOutline
} from "react-icons/all";
import {COUNTER_TYPES, ENTITY_STATUS, ENTITY_STEPS} from "../../utils/constants";
import {GetAuthorizedSurveys, handleConditions, initEntity} from "../../utils/utils";
import {
    selectCurrentEntity,
    selectEntities,
    setCurrentEntity,
    setCurrentSurvey,
    setEntities, setLoading
} from "../../reducers/app.reducer";
import {useDispatch, useSelector} from "react-redux";
import {selectImpersonated, selectUser, updateResponses} from "../../reducers/user.reducer";
import {selectAllContentfulData} from "../../reducers/contentful.reducer";
import {ButtonExpand} from "../button-expand/ButtonExpand";
import {useCallback, useEffect, useState} from "react";
import * as _ from "lodash";
import {Modal} from "../modal/Modal";

interface EntityCardProps {
    entity?: any;
    create?: boolean;
    nameChanged?: boolean;
}

export const EntityCard = ({entity, create, nameChanged}: EntityCardProps) => {
    //REACT HOOKS
    const [delPopin, setDelPopin] = useState(false);
    const [renamePopin, setRenamePopin] = useState(false);
    const [submitPopin, setSubmitPopin] = useState(false);
    const [showModalSubmitErrors, setShowModalSubmitErrors] = useState(false);
    const [newName, setNewName] = useState(null);


    //redux
    const dispatch = useDispatch();
    const entities = useSelector(selectEntities);
    const user = useSelector(selectUser);
    const impersonated = useSelector(selectImpersonated);
    const contentfulData = useSelector(selectAllContentfulData);
    const currentEntity = useSelector(selectCurrentEntity);

    const getEntitySteps = (entity) => {
        let parsedStep = parseInt(entity.currentStep);
        let entityNextStep = ENTITY_STEPS[parsedStep + 1];

        if (!entityNextStep) {
            entityNextStep = {user: "Cette entitiée est désormais conforme"};
        }

        return {
            current: ENTITY_STEPS[parsedStep].user,
            next: entityNextStep.user,
        }
    }

    const saveResponses = async (updatedEntities) => {
        await dispatch(updateResponses({userId: impersonated ? impersonated._id : user._id, values: updatedEntities}));
    };

    const handleNewEntity = async () => {
        setNewName(undefined);
        let entitiesCopy = [...entities];
        let newEntity = initEntity({
            name: `Entreprise ${entitiesCopy.length + 1}`,
            counters: COUNTER_TYPES,
            currentStep: 1,
            responses: {},
            status: ENTITY_STATUS.NOT_EDITED,
            nameChanged: false,
        });
        entitiesCopy.push(newEntity);
        dispatch(setEntities(entitiesCopy));
    };

    const handleSelectEntity = (entity) => {
        dispatch(setLoading(true));
        if (!delPopin) {
            dispatch(setCurrentSurvey(null));
            dispatch(setCurrentEntity(entity));
        }
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 1000);
    };

    const handleDeleteEntity = async (updateDb) => {
        let entitiesCopy = _.cloneDeep(entities);
        entitiesCopy = entitiesCopy.filter(entityItem => entityItem.name !== entity.name);
        dispatch(setCurrentEntity(null));
        setDelPopin(null);
        dispatch(setEntities(entitiesCopy));
        if (updateDb) {
            dispatch(updateResponses({userId: impersonated ? impersonated._id : user._id, values: entitiesCopy}));
        }
    };

    const handleEntityChangeName = async () => {
        if (!getRenameValidation(newName).isValid) {
            return;
        }
        let entitiesCopy = _.cloneDeep(entities);
        let currentEntityCopy = entitiesCopy.find(entityItem => entityItem.name === entity.name);
        currentEntityCopy.name = newName?.trim();
        currentEntityCopy.nameChanged = true;
        dispatch(setEntities(entitiesCopy));
        dispatch(setCurrentEntity(currentEntityCopy));
        await saveResponses(entitiesCopy);
        await closeRenamePopin(null);
    };

    const getRenameValidation = name => {
        let alreadyExist = entities?.some(entityItem => entityItem?.name?.toLowerCase().trim() === name?.toLowerCase().trim())
        return {
            alreadyExists: alreadyExist,
            empty: name?.trim() === "" || name === null,
            isValid: !alreadyExist && name?.trim() !== "" && name !== null
        }
    };

    const closeDelPopin = (e) => {
        e?.stopPropagation();
        setDelPopin(false);
    }

    const closeRenamePopin = async (e, remove: boolean = false) => {
        e?.stopPropagation();
        setRenamePopin(false);
        setNewName(undefined);
        if (remove) {
            await handleDeleteEntity(false);
        }
    }

    const closeSubmitPopin = (e) => {
        e?.stopPropagation();
        setSubmitPopin(false);
    }

    const closeSubmitErrorsModal = (e) => {
        e?.stopPropagation();
        setShowModalSubmitErrors(false);
        closeSubmitPopin(null);
    }

    const handleDeleteConfirmation = async () => {
        await closeRenamePopin(null);
        closeSubmitPopin(null);
        setDelPopin(true);
    }

    const handleRenameConfirmation = () => {
        setNewName(null);
        closeDelPopin(null);
        closeSubmitPopin(null)
        setRenamePopin(true);
    }

    const handleSubmitEntityConfirmation = async () => {
        closeDelPopin(null);
        await closeRenamePopin(null);
        setSubmitPopin(true);
    }

    const getEntityResponsesBySurveyId = (surveyId) => {
        return entities.find(entityItem => {
            return entityItem?.name === entity?.name;
        })?.responses?.[surveyId];
    }

    const getFullSurveyFromContentful = (surveyId) => contentfulData.surveys.find(survey => survey.id === surveyId);

    const getRequiredQuestionsBySurvey = (survey) => {
        let requiredQuestions = [];
        getFullSurveyFromContentful(survey.id)?.steps?.map(step => {
            return step.form.map(question => {
                if (question.required) {
                    // Manage Multiple Versions
                    if (survey.versioning.enabled && entity?.responses?.[survey.id]?.length > 1) {
                        entity?.responses?.[survey.id].map(
                            ((surveyVersion, idx) => {

                                if (entity && handleConditions(question, entity?.responses?.[survey.id][idx])) {
                                    requiredQuestions.push({name: question.name, version: idx});
                                }
                            })
                        )
                    } else {
                        if (entity && handleConditions(question, entity?.responses?.[survey.id] && entity?.responses?.[survey.id][0])) {
                            requiredQuestions.push({name: question.name});
                        }
                    }
                }
            });
        });
        return {
            questions: requiredQuestions,
            amount: requiredQuestions.length
        }
    }

    const getFilledRequiredQuestions = (survey) => {
        let requiredQuestions = getRequiredQuestionsBySurvey(survey).questions;
        let currentResponsesSet = getEntityResponsesBySurveyId(survey.id);
        let currentResponses = currentResponsesSet && currentResponsesSet[0]?.responses;
        let filledRequiredQuestions = 0;

        if (!currentResponses) {
            return;
        }
        // Manage Multiple Versions
        if (survey.versioning.enabled && currentResponsesSet.length > 1) {
            requiredQuestions.map(question => {
                if (currentResponsesSet[question.version]?.responses &&
                    currentResponsesSet[question.version]?.responses?.[question.name] !== undefined &&
                    currentResponsesSet[question.version]?.responses?.[question.name] !== null &&
                    !_.isEmpty(currentResponsesSet[question.version]?.responses?.[question.name])
                ) {
                    filledRequiredQuestions += 1;
                }
            });
        } else {
            requiredQuestions.map(question => {
                if (currentResponses[question.name] !== undefined && currentResponses[question.name] !== null && !_.isEmpty(currentResponses[question.name])
                ) {
                    filledRequiredQuestions += 1;
                }
            });
        }

        return filledRequiredQuestions;
    }

    const getFullSurveyList = () => {
        let authorizedSurveys = GetAuthorizedSurveys();
        let surveyList = [];
        authorizedSurveys.forEach(survey => {
            surveyList.push({
                ...survey,
                requiredQuestions: getRequiredQuestionsBySurvey(survey),
                filledRequiredQuestions: getFilledRequiredQuestions(survey),
                fillingPercentage: Math.round((getFilledRequiredQuestions(survey) / getRequiredQuestionsBySurvey(survey).amount) * 100)
            });
        });

        return surveyList;
    }

    const getEntityTotalFillingPercentage = () => {
        let totalPercentage = 0;
        let surveyList = getFullSurveyList();
        surveyList.forEach(survey => {
            totalPercentage += survey.fillingPercentage || 0;
        });
        let totalFillingPercentage = Math.round(totalPercentage / surveyList.length)
        return isNaN(totalFillingPercentage) ? 0 : totalFillingPercentage;
    }

    const getConformitySummary = () => {
        let surveyList = getFullSurveyList();
        return surveyList.map(survey => ({
            conformity: survey.fillingPercentage === 100,
            survey
        }));
    }

    const isConformityOK = () => {
        let conformitySummary = getConformitySummary();
        let conformityOK = true;
        conformitySummary.forEach(survey => {
            if (!survey.conformity) {
                conformityOK = false;
            }
        });
        return conformityOK;
    }

    const handleSubmitEntity = async () => {
        if (isConformityOK()) {
            await submitEntity();
        } else {
            setShowModalSubmitErrors(true);
        }
    }

    const submitEntity = async () => {
        let entitiesCopy = _.cloneDeep(entities);
        let currentEntityCopy = entitiesCopy.find(entityItem => entityItem.name === entity.name);
        currentEntityCopy.status = ENTITY_STATUS.SUBMITTED;
        await dispatch(updateResponses({userId: user.id, values: entitiesCopy}));
        await dispatch(setCurrentEntity(currentEntityCopy));
        closeSubmitPopin(null);
        closeSubmitErrorsModal(null);
    }

    const debouncedEntitySetName = useCallback(
        _.debounce(setNewName, 300, {leading: true})
        , []);

    const cardActions = [
        {
            label: "Supprimer",
            onClick: handleDeleteConfirmation
        },
        {
            label: "Modifier",
            onClick: handleRenameConfirmation
        },
        /*{
            label: "Soumettre",
            onClick: handleSubmitEntityConfirmation
        },*/
    ];

    //USE EFFECTS
    useEffect(() => {
        if (!nameChanged) {
            handleRenameConfirmation();
        }
    }, []);

    return (
        <>
            {(entity || create) &&
                <>
                    <EntityCardContainer active={!create && currentEntity?.name === entity?.name} create={create}>
                        {!create &&
                            <CardTop>
                                <ButtonExpand enabled={entity.nameChanged} animation={true} icon={IoSettingsOutline}
                                              actions={cardActions}/>
                            </CardTop>
                        }
                        {!create &&
                            <EntityCardContent onClick={() => handleSelectEntity(entity)}>
                                <Top>
                                    <Name>{entity.name}</Name>
                                </Top>
                                <Content>
                                    <Left>
                                        <Data>
                                            <DataTitle>En cours :</DataTitle>
                                            <DataValue>{getEntitySteps(entity).current}</DataValue>
                                        </Data>
                                        <Data>
                                            <DataTitle>A suivre :</DataTitle>
                                            <DataValue>{getEntitySteps(entity).next}</DataValue>
                                        </Data>
                                    </Left>
                                    <Right>
                                        <Percentage>
                                            <PercentageName>Conformité</PercentageName>
                                            <PercentageValue>{getEntityTotalFillingPercentage()}%</PercentageValue>
                                        </Percentage>
                                        <StatusContainer>
                                            <StatusBadge status={entity.status}>{entity.status}</StatusBadge>
                                        </StatusContainer>
                                    </Right>
                                </Content>
                                <PopinContent onClick={e => e.stopPropagation()} open={delPopin}>
                                    <PopinTitle>Etes-vous sûrs de vouloir
                                        supprimer <span>{entity.name}</span> ?</PopinTitle>
                                    <Actions>
                                        <Action enabled={true} onClick={closeDelPopin}>
                                            <Label>Annuler</Label>
                                            <FaTimes style={{color: '#ff7675'}}/>
                                        </Action>
                                        <Action enabled={true} onClick={() => handleDeleteEntity(true)}>
                                            <Label>Valider</Label>
                                            <FaCheck style={{color: '#55efc4'}}/>
                                        </Action>
                                    </Actions>
                                </PopinContent>
                                <PopinContent onClick={e => e.stopPropagation()} open={renamePopin}>
                                    {!entity.nameChanged &&
                                        <PopinTitle>Entrez un nom pour votre <span>nouvelle entité</span></PopinTitle>
                                    }
                                    {entity.nameChanged &&
                                        <PopinTitle>
                                            Quel nouveau nom voulez-vous donner à <span>{entity.name}</span> ?
                                        </PopinTitle>
                                    }
                                    {renamePopin &&
                                        <InputContainer>
                                            <input autoComplete="false" name="hidden" type="text"
                                                   style={{display: 'none'}}/>
                                            <StyledInput error={!getRenameValidation(newName).isValid}
                                                         onClick={e => e.stopPropagation()}
                                                         type="text" name={'newName'}
                                                         autocomplete="off"
                                                         defaultValue={newName}
                                                         onChange={(e) => {
                                                             debouncedEntitySetName(e.target.value)
                                                         }}
                                            />
                                            <Error>
                                                {getRenameValidation(newName).alreadyExists &&
                                                    <>le nom <b>{newName}</b> existe déja</>
                                                }
                                                {getRenameValidation(newName).empty &&
                                                    <>le nom ne doit pas etre vide</>
                                                }
                                            </Error>
                                        </InputContainer>
                                    }
                                    <Actions>
                                        <Action enabled={true}
                                                onClick={e => closeRenamePopin(e, !entity.nameChanged)}>
                                            <Label>Annuler</Label>
                                            <FaTimes style={{color: '#ff7675'}}/>
                                        </Action>
                                        <Action enabled={getRenameValidation(newName).isValid}
                                                onClick={handleEntityChangeName}>
                                            <Label>Valider</Label>
                                            <FaCheck style={{color: '#55efc4'}}/>
                                        </Action>
                                    </Actions>
                                </PopinContent>
                                <PopinContent onClick={e => e.stopPropagation()} open={submitPopin}>
                                    <>
                                        <PopinTitle>Etes-vous sûr de vouloir soumettre vos réponses pour cette entitée
                                            ? </PopinTitle>
                                        <Actions>
                                            <Action enabled={true} onClick={closeSubmitPopin}>
                                                <Label>Annuler</Label>
                                                <FaTimes style={{color: '#ff7675'}}/>
                                            </Action>
                                            <Action enabled={true} onClick={handleSubmitEntity}>
                                                <Label>Valider</Label>
                                                <FaCheck style={{color: '#55efc4'}}/>
                                            </Action>
                                        </Actions>
                                    </>
                                </PopinContent>
                            </EntityCardContent>
                        }
                        {create &&
                            <CreateNew onClick={handleNewEntity}>
                                <span>Ajouter une entité</span>
                                <Add>+</Add>
                            </CreateNew>
                        }
                    </EntityCardContainer>
                    {
                        create &&
                        <Spacer/>
                    }
                    <Modal onClose={() => closeSubmitErrorsModal(null)} size={{height: undefined, width: '40%'}}
                           show={showModalSubmitErrors}
                           title={'Réponses manquantes'}>
                        <ConformityErrorsSummary>
                            <ErrorTitle>Certaines des réponses pour <b>{entity?.name}</b> sont manquantes </ErrorTitle>
                            <ErrorList>
                                {getConformitySummary().filter(item => !item.conformity).map((conformityItem, index) => (
                                    <ErrorItem key={index}>
                                        <SurveyName>{conformityItem.survey.name}</SurveyName>
                                        <span>questions obligatoires: <b>{conformityItem.survey.filledRequiredQuestions} / {conformityItem.survey.requiredQuestions.amount}</b></span>
                                    </ErrorItem>
                                ))}
                            </ErrorList>
                            <ErrorTitle>Etes-vous sûr de vouloir soumettre les réponses pour cette entité malgré tout
                                ? </ErrorTitle>
                            <ErrorsActions>
                                <Action enabled={true} onClick={closeSubmitErrorsModal}>
                                    <Label>Annuler</Label>
                                    <FaTimes style={{color: '#ff7675'}}/>
                                </Action>
                                <Action enabled={true} onClick={submitEntity}>
                                    <Label>Valider</Label>
                                    <FaCheck style={{color: '#55efc4'}}/>
                                </Action>
                            </ErrorsActions>
                        </ConformityErrorsSummary>
                    </Modal>
                </>
            }
        </>
    )
};

const ErrorList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  row-gap: 10px;
`;

const Error = styled.div`
  color: #ff7675;
  font-size: 1em;
  height: 22px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
  width: 100%;
`;

const StyledInput = styled.input<{ error: boolean }>`
  ${({error}) => error && `
    outline: 1px solid red;
    
    &:focus {
        outline: 1px solid red;
    }
  `};
`;

const SurveyName = styled.div``;

const ErrorItem = styled.div`
  display: flex;
  border: 1px solid #dfe6e9;
  padding: 5px;

  border-radius: 5px;
  flex-direction: column;
  line-height: 20px;

  span {
    color: #797979;

    b {
      color: #ff7675;
    }
  }
`;

const ConformityErrorsSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 40px);
  padding: 20px;

`;

const ErrorTitle = styled.div`
  font-size: 1.2em;
  text-align: center;

  b {
    color: #1179A0;
  }
`;

const EntityCardContainer = styled.div<{ create: boolean, active: boolean }>`
  border-radius: 20px;
  width: 350px;
  min-width: 350px;
  height: 250px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  ${({create}) => !create && `
    box-shadow: 1px 0px 4px #2b2b2b26;
    background-image: url("https://media.istockphoto.com/vectors/isometric-smart-industrial-factory-automated-production-line-and-vector-id1175415560?k=20&m=1175415560&s=612x612&w=0&h=pEyN_2GPNoGMMLMBlBz3GzFEM9uc_o08CX9P44uzwKU=");
    background-color: white;
    background-size: cover;
    background-position: center bottom 145px;
    background-repeat: no-repeat;
  `};

  ${({create}) => create && `
    border: 3px dashed #b2bec3;
    background-color: #dfe6e970;
    height: 244px;
    min-height: 244px;
    transition: all 0.3s;
    
    &:hover {
        background-color: #dfe6e9;
    }
  `};

  ${({active}) => active && `
        box-shadow: 1px 0px 11px 6px #4e4e4e36;
        outline: 3px solid #f49d1d;
        transform: scale(1.01);
  `};

  &:hover {
    ${({create}) => !create && `
        box-shadow: 1px 0px 11px 6px #4e4e4e36;
        outline: 4px solid #f49d1d;
        background-position: center bottom 155px;
    `}
  }
`;

const CardTop = styled.div`
  height: 45px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
`;

const EntityCardContent = styled.div<{ create: boolean }>`
  background-color: #fff;
  width: calc(100% - 30px);
  height: 80%;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 1px 0px 4px #2b2b2b56;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
`;

const PopinContent = styled.div<{ open: boolean }>`
  position: absolute;
  background-color: white;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  left: 0;
  right: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: transform 0.2s;
  transform: translateY(${({open}) => open ? '0%' : '100%'});
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  row-gap: 15px;

  input {
    width: calc(100% - 30px);
    border: 1px solid rgb(204, 204, 204);
    font-family: "Open Sans";
    border-radius: 4px;
    font-size: 1em;
    padding: 5px 15px;

    &:focus-visible {
      outline-offset: unset;
      outline: 1px solid #2684FF !important;
    }
  }
`;

const PopinTitle = styled.span`
  font-size: 1.2rem;
  text-align: center;

  span {
    color: #187ead
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
  margin-top: -10px;
`;

const ErrorsActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
`;

const Action = styled.div<{ enabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${({enabled}) => enabled ? 0.8 : 0.4};
  transition: opacity 0.2s;
  user-select: none;

  cursor: ${({enabled}) => enabled ? 'pointer' : 'not-allowed'};


  ${({enabled}) => enabled && `
      &:hover {
        opacity: 1;
      }
  `}
  svg {
    height: 20px;
    width: 20px;
  }
`;

const Label = styled.span``;

const CreateNew = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  opacity: 0.6;
  transition: all 0.3s;

  span {
    color: #77797a;
    font-size: 1.2em;
  }

  &:hover {
    opacity: 1;
  }
`;

const Add = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #b2bec3;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  color: #b2bec3;
  font-size: 5em;
`;

const Name = styled.div`
  font-size: 1.5em;
  margin-top: 5px;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

`;

const Content = styled.div`
  flex: 1;
  display: flex;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

`;

const Right = styled.div`
  width: 120px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Percentage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex: 1;
`;

const PercentageName = styled.div`
  font-weight: 500;
`;

const PercentageValue = styled.div`
  font-size: 2em;
  color: #797979;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
  margin-bottom: 10px;
  column-gap: 3px;
  width: 100%;
`;

const StatusBadge = styled.div<{ status: typeof ENTITY_STATUS }>`
  background-color: #00b894;
  color: white;
  padding: 3px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 0.8em;

  ${({status}) => {
    switch (status) {
      case ENTITY_STATUS.NOT_EDITED:
        return `background-color: #74b9ff;`;
      case ENTITY_STATUS.EDITED:
        return `background-color: #00cec9;`;
      case ENTITY_STATUS.SUBMITTED:
        return `background-color: #fdcb6e;`;
      case ENTITY_STATUS.REJECTED:
        return `background-color: #fab1a0;`;
      case ENTITY_STATUS.VALIDATED:
        return `background-color: #00b894; color: white;`;
    }
  }};

`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataTitle = styled.div`
  font-weight: 500;
  font-size: 1em;
`;

const DataValue = styled.div`
  font-size: 1em;
  color: #797979;
`;

const Spacer = styled.div`
  min-width: 5px;
`;

const DelModaleContainer = styled.div`
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: "Baloo 2";

  > span {
    padding: 20px 0;

    b {
      color: #187ead;
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 15px;
    width: 100%;
    padding: 10px 0;

    > span {
      font-family: "Baloo 2";
      background-color: #f49d1d;
      border-radius: 5px;
      padding: 10px 20px;
      color: #f1f2f6;
      display: flex;
      column-gap: 10px;
      align-items: center;
      opacity: 0.8;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      svg {
        margin-top: -2px;
      }
    }
  }
`;



