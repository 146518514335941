import * as React from 'react';
import styled from "styled-components";
import {IconType} from "react-icons";
import {useHistory} from "react-router-dom";


export interface SidenavItemProps {
    icon: IconType;
    active?: boolean;
    path?: string;
    label: string,
    admin?: boolean
};

export const SidenavItem = ({icon, label, path, active, admin}: SidenavItemProps) => {
    const Icon = icon;
    const history = useHistory();

    return (
        <Item admin={admin} onClick={() => history.push(path)} active={active}>
            <Icon/>
            <span>{label}</span>
        </Item>
    )
};


const Item = styled.div<{ active: boolean, admin: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 50px;
  position: relative;
  transition: all 0.3s;
  color: ${({admin}) => admin ? '#00b894' : '#2b2b2b'};
  opacity: 0.7;
  width: calc(100% - 15px);
  padding-left: 15px;
  border-radius: 15px;

    /*&:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    border-top: 5px solid ${({admin}) => admin ? '#016c5a' : '#0d3749'};;
    border-right: 15px solid transparent;
    right: 0;
    bottom: -5px;
    opacity: 0;
    transition: all 0.3s;
  }*/

  svg {
    height: 20px;
    width: 20px;
  }

  ${({active, admin}) => active && `
    opacity: 1;
    background-color: white;
    font-weight: 500;
  `}
  &:hover {
    opacity: 1;
    background: white;
  }

  span {
    margin-left: 15px;
  }
`;
